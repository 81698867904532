import axios from "axios";
import errorUtility from "./errorUtility";

export default {
    checkLicence,
};

function checkLicence() {
    return axios.get("/api/License/IsValid", {}).catch((e) => Promise.reject(errorUtility.getErrorMessage(e)));
}
