<template>
    <div>
        <v-menu ref="menu" v-model="show" v-bind="$attrs" offset-y :close-on-content-click="false" v-on="$listeners">
            <template v-slot:activator="{ on }">
                <app-field-icon v-on="on">
                    {{ icon }}
                </app-field-icon>
            </template>
            <slot />
        </v-menu>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                default: "mdi-circle",
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                show: false,
            };
        },
        watch: {
            show(show) {
                if (show !== this.value) {
                    this.$emit("input", show);
                }
            },
            value: {
                immediate: true,
                handler(value) {
                    if (this.show !== value) {
                        this.show = value;
                    }
                },
            },
        },
        beforeDestroy() {
            // Fixes bug with the v-menu activator slot and transitions
            // https://github.com/vuetifyjs/vuetify/issues/9276#issuecomment-549085722
            this.$refs.menu.activatorNode = null;
        },
    };
</script>
