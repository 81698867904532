<template>
    <app-dialog :value="!!value" @keydown.esc="$emit('input', null)">
        <component
            :is="formComponent"
            :key="formKey"
            :entity-key="entityKey"
            :value="value"
            v-bind="$attrs"
            v-on="$listeners"
            @cancel="$emit('input', null)"
        />
    </app-dialog>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import { getForm } from "../forms";

    export default {
        mixins: [schemaMixin],
        props: {
            value: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                formKey: 0,
            };
        },
        computed: {
            formComponent() {
                return getForm(this.entityKey);
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (value) {
                        this.formKey++;
                    }
                },
            },
        },
    };
</script>
