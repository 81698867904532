import axios from "axios";
import errorUtility from "./errorUtility";
import store from "../store/store";

export default {
    login,
    logout,
    getLoggedInUser,
    hasClaim,
    isMasterUser,
};

function login(username, password) {
    return axios
        .post("/api/Security/UserLogin", { username, password })
        .catch((e) => Promise.reject(errorUtility.getErrorMessage(e)));
}

function logout() {
    return axios.post("/api/Security/UserLogout").catch((e) => Promise.reject(errorUtility.getErrorMessage(e)));
}

function getLoggedInUser() {
    return axios
        .get("/api/Security/CurrentUser")
        .then((response) => {
            return response.data;
        })
        .catch((e) => Promise.reject(errorUtility.getErrorMessage(e)));
}

function hasClaim(name, requireswrite) {
    if (!store.state.authentication.user) {
        return false;
    }
    let claims = store.state.authentication.user.claims;
    return claims.some(
        (claim) => claim.name == name && (!requireswrite || claim.value == "ReadWrite") && !claim.isInactive
    );
}

async function isMasterUser() {
    let user = store.state.authentication.user;
    if (!user) {
        try {
            user = await getLoggedInUser();
        } catch (e) {
            return false;
        }
    }

    return user.isMaster;
}
