<template>
    <color-picker-field
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        :solo="false"
        outlined
        dense
        v-on="$listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import colorPickerField from "@/components/ColorPickerField.vue";

    export default {
        types: ["color"],
        mixins: [formFieldMixin],
        components: {
            colorPickerField,
        },
    };
</script>
