<template>
    <dialog-cell title="Json Data" :value="formattedValue" :max-width="'50%'" />
</template>

<script>
    import DialogCell from "./DialogCell.vue";

    export default {
        types: ["json"],
        components: { DialogCell },
        props: ["value"],
        computed: {
            formattedValue() {
                const obj = JSON.parse(this.value);
                return JSON.stringify(obj, null, 2);
            },
        },
    };
</script>
