<template>
    <v-text-field
        v-model="localeTime"
        v-bind="$attrs"
        class="app-time-field"
        dense
        outlined
        :placeholder="formatString"
        :rules="combinedRules"
    >
        <template v-slot:append>
            <app-field-menu v-model="showTimePicker" icon="mdi-clock-outline">
                <app-time-picker v-model="pickerTime" />
            </app-field-menu>
        </template>
    </v-text-field>
</template>

<script>
    import { isNullOrWhiteSpace } from "@/services/stringUtility";
    import { dateTimesEqual, formatTime, getTimeFormatString, parseIso } from "@/services/dateUtility";
    import { parseTime } from "@/services/timeUtility";

    function toIso(localeTime) {
        return parseTime(localeTime)?.toISOTime({ includeOffset: false });
    }

    function mapRule(rule) {
        return (localeTime) => rule(toIso(localeTime));
    }

    function parseTimeOrNull(localeTime) {
        let dateTime = parseTime(localeTime);

        if (dateTime === null || !dateTime.isValid) {
            return null;
        }

        return dateTime;
    }

    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
            required: {
                type: Boolean,
                default: false,
            },
            rules: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                // This is in the short time format for the configured locale.
                localeTime: null,
                showTimePicker: false,
                defaultRules: [
                    (v) => !this.required || !isNullOrWhiteSpace(v) || "Date is required",
                    (v) => {
                        if (isNullOrWhiteSpace(v) || parseTime(v)?.isValid) {
                            return true;
                        }
                        return "Enter time as " + getTimeFormatString().toLowerCase();
                    },
                ],
            };
        },

        computed: {
            combinedRules() {
                return [...this.defaultRules, ...this.rules.map(mapRule)];
            },

            pickerTime: {
                get() {
                    let dateTime = parseTimeOrNull(this.localeTime);
                    return dateTime?.toISOTime({ includeOffset: false });
                },
                set(pickerTime) {
                    this.showTimePicker = false;
                    if (isNullOrWhiteSpace(pickerTime)) {
                        return;
                    }

                    let dateTime = parseIso(pickerTime);
                    if (dateTime.isValid) {
                        this.localeTime = formatTime(dateTime);
                    }
                    this.pickerTime = null;
                },
            },
            valid() {
                return this.combinedRules.every((rule) => rule(this.localeTime) === true);
            },
            formatString() {
                return (
                    getTimeFormatString()
                        // making this lower case is not correct, but looks nicer when displayed.
                        .toLowerCase()
                );
            },
        },

        watch: {
            localeTime(localeTime) {
                if (isNullOrWhiteSpace(localeTime)) {
                    this.$emit("input", null);
                    return;
                }

                if (!this.valid) {
                    return;
                }

                let isoTime = toIso(localeTime);

                if (this.value !== isoTime) {
                    this.$emit("input", isoTime);
                }
            },
            value: {
                immediate: true,
                handler(value) {
                    let dateTime = parseIso(value);
                    let currentDateTime = parseTimeOrNull(this.localeTime);

                    if (!dateTimesEqual(dateTime, currentDateTime)) {
                        this.localeTime = formatTime(dateTime);
                    }
                },
            },
        },
    };
</script>

<style lang="scss">
    .app-time-field {
        &.v-text-field--enclosed.v-input--dense.v-text-field--outlined .v-input__append-inner {
            margin: 2px -10px 2px 2px;
        }
    }
</style>
