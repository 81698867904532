<template>
    <!-- https://github.com/vuetifyjs/vuetify/issues/1810 -->
    <v-text-field
        :value="localValue"
        @change="(value) => (localValue = value)"
        v-bind="$attrs"
        class="app-date-field"
        style="width: 100%"
        :type="useDateInputType ? 'date' : 'text'"
        :placeholder="formatString"
        :rules="combinedRules"
        :required="required"
        :disabled="readonly"
        @click.prevent
    >
        <template v-slot:append>
            <app-field-menu v-if="!readonly" v-model="showDatePicker" icon="mdi-calendar">
                <v-date-picker
                    v-model="pickerDate"
                    :events="events"
                    :event-color="eventColor"
                    @change="$emit('change', $event)"
                />
            </app-field-menu>
        </template>
    </v-text-field>
</template>

<script>
    import { parseFromFormat } from "@/services/dateUtility";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    import {
        dateTimesEqual,
        formatDateOnly,
        getDateFormatString,
        localize,
        parseDate,
        parseIso,
        getCurrentDateIso,
    } from "@/services/dateUtility";

    function useDateInputType() {
        return getDateFormatString() == getDateFormatString(true);
    }

    function parse(value) {
        var parseFunction = useDateInputType() ? parseIso : parseDate;
        return parseFunction(value);
    }

    function toIso(value, includeOffset = true) {
        return parse(value)?.toISO({ includeOffset: includeOffset });
    }

    function toLocalValue(dateTime, includeOffset = true) {
        return useDateInputType()
            ? dateTime?.toFormat("yyyy-MM-dd")
            : formatDateOnly(dateTime?.toISO({ includeOffset: includeOffset }));
    }

    function mapRule(rule) {
        return (value) => rule(toIso(value));
    }

    function parseOrNull(value) {
        let dateTime = parse(value);

        if (dateTime === null || !dateTime.isValid) {
            return null;
        }

        return dateTime;
    }

    // Consider the following represents a full date with an offset:
    // 2019-09-28T00:00:00+08:00

    export default {
        props: {
            value: String,
            required: Boolean,
            rules: Array,
            defaultToToday: Boolean,
            events: Array,
            eventColor: String,
            readonly: Boolean,
            includeOffset: {
                type: Boolean,
                default: () => true,
            },
        },

        data() {
            return {
                // This is in the short date format for the configured locale.
                localValue: null,
                showDatePicker: false,
                defaultRules: [
                    (v) => !this.required || !isNullOrWhiteSpace(v) || "Date is required",
                    (v) => {
                        if (isNullOrWhiteSpace(v)) {
                            return true;
                        }

                        let date = parse(v);

                        if (!date.isValid) {
                            return "Enter date as " + getDateFormatString().toLowerCase();
                        }
                        if (date.year >= 10000) {
                            return "Ensure year is less than 10000";
                        }

                        return true;
                    },
                ],
            };
        },

        computed: {
            useDateInputType,
            combinedRules() {
                if (typeof rules === "undefined") {
                    return this.defaultRules;
                }
                var rules = this.useDateInputType ? this.rules : this.rules.map(mapRule);
                return [...this.defaultRules, ...rules];
            },

            // v-date-picker requires the value be formatted yyyy-MM-dd this computed property
            // converts the date value to and from this format, defaulting to today if the current date
            // is not specified.
            pickerDate: {
                get() {
                    // v-date-picker accepts inputs as yyyy-MM-dd:
                    let dateTime = parseOrNull(this.localValue);
                    return dateTime?.toFormat("yyyy-MM-dd");
                },
                set(value) {
                    this.showDatePicker = false;
                    if (isNullOrWhiteSpace(value)) {
                        return;
                    }
                    if (this.useDateInputType) {
                        this.localValue = value;
                        return;
                    }
                    let dateTime = localize(parseFromFormat(value, "yyyy-MM-dd"));
                    if (dateTime.isValid) {
                        this.localValue = formatDateOnly(dateTime);
                    }
                },
            },
            valid() {
                return this.combinedRules.every((rule) => rule(this.localValue) === true);
            },
            formatString() {
                // making this lower case is not correct, but looks nicer when displayed.
                return getDateFormatString().toLowerCase();
            },
        },

        watch: {
            localValue(localValue) {
                if (isNullOrWhiteSpace(localValue)) {
                    this.$emit("input", null);
                    return;
                }
                if (!this.valid) {
                    return;
                }
                let isoDate = toIso(localValue, this.includeOffset);
                if (this.value !== isoDate) {
                    this.$emit("input", isoDate);
                    this.$emit("change", isoDate);
                }
            },
            value: {
                immediate: true,
                handler(value) {
                    let currentDateTime = parseOrNull(this.localValue);
                    let dateTime = parseIso(value);
                    if (this.includeOffset) {
                        dateTime = localize(dateTime);
                    }
                    if (dateTimesEqual(dateTime, currentDateTime)) {
                        return;
                    }
                    this.localValue = toLocalValue(dateTime, this.includeOffset);
                },
            },
        },

        mounted() {
            if (this.defaultToToday && !this.value) {
                this.$emit("input", getCurrentDateIso(this.includeOffset));
            }
        },
    };
</script>

<style lang="scss">
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="date"]::-webkit-clear-button {
        display: none;
        appearance: none;
    }

    .app-date-field {
        &.v-text-field .v-input__append-inner {
            margin: 4px 4px 0 0;
        }

        &.v-text-field--enclosed.v-input--dense.v-text-field--outlined .v-input__append-inner {
            margin: 6px -4px 0 2px;
        }
    }
</style>
