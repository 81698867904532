<template>
    <div>
        <a :href="url">{{ display }}</a>
    </div>
</template>

<script>
    import { display } from "../services/entityCellProvider";

    export default {
        types: ["file"],
        props: {
            value: {
                type: [String, Object],
                default: () => null,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            display() {
                return display("file", this.value, this.options?.lookup);
            },
            url() {
                if (this.value == null) return null;
                const idValue = typeof this.value === "string" ? this.value : this.value.id;
                return `/api/file/${idValue}`;
            },
        },
    };
</script>
