<template>
    <v-form ref="form" v-model="isValid" @submit.prevent="save()">
        <v-card>
            <slot name="formTitle">
                <v-card-title>
                    <span class="headline">{{ isEdit ? "Edit" : "Add" }} {{ entity.singleTitle }}</span>
                </v-card-title>
            </slot>

            <v-card-text>
                <slot
                    v-for="(field, index) in fields"
                    :field="field"
                    :item="item"
                    :name="`field-${field.key}`"
                    :autofocus="index == 0"
                >
                    <component
                        :is="getComponent(field)"
                        :key="field.value"
                        v-model="item[field.key]"
                        :field="field"
                        :autofocus="index == 0"
                        validate-on-blur
                        @save="save"
                    />
                </slot>
                <slot name="append" :item="item" :fixed-values="fixedValues" />
            </v-card-text>
            <slot name="formActions">
                <v-divider />
                <v-card-actions class="pa-4">
                    <slot v-if="canDelete" name="deleteButton" :deleteItem="deleteItem">
                        <app-button color="secondary" text @click="deleteItem">Deactivate</app-button>
                    </slot>
                    <v-spacer />
                    <app-button v-if="canCancel" color="primary" text @click="$emit('cancel')">Cancel</app-button>
                    <app-button v-if="canSave" type="submit" text :disabled="isSaving">Save</app-button>
                </v-card-actions>
            </slot>

            <v-progress-linear v-if="isSaving" indeterminate color="blue" />
        </v-card>
    </v-form>
</template>

<script>
    import formMixin from "@/features/schemas/mixins/formMixin";

    export default {
        mixins: [formMixin],
    };
</script>
