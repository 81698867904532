import licenseValidService from "../services/licenseValidService";

export const licenseValid = {
    namespaced: true,
    state: {
        status: {},
    },
    actions: {
        checkValid({ commit }) {
            licenseValidService
                .checkLicence()
                .then((result) => {
                    commit("licenseValid", result.data.isValid);
                })
                .catch(() => {
                    commit("licenseValid", false);
                });
        },
    },
    mutations: {
        licenseValid(state, licenseState) {
            state.status = { licenseState: licenseState };
        },
    },
};
