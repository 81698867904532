import { hasProperty } from "@/services/objectUtility";

export default {
    props: {
        /**
         * Fixed values is a key-value dictionary that stores the property name and the value that you define, e.g "iconId: null".
         * This then gets passed to the components and can be used to hide columns, filter the results with an "equals" condition
         * and then makes any new items have that property get defaulted with that value.
         */
        fixedValues: {
            type: Object,
            default: null,
        },
    },
    methods: {
        isFixedValue(field) {
            // May have field like magazineArea instead of magazineAreaId due to label logic.
            // So need to check both combinations.
            if (field.endsWith("Id")) {
                return hasProperty(this.fixedValues, field) || hasProperty(this.fixedValues, field.slice(0, -2));
            }

            return hasProperty(this.fixedValues, field) || hasProperty(this.fixedValues, `${field}Id`);
        },
    },
};
