<template>
    <schema-search-select v-bind="$attrs" v-on="$listeners">
        <template #list-item-icon="{ item }">
            <v-list-item-icon v-if="hasIcon(item.icon, 'iconImage')">
                <exm-icon
                    :icon="formatIconName(item.icon.iconImage)"
                    :primary-color="item.icon.primaryColor"
                    :secondary-color="item.icon.secondaryColor"
                    :background-color="item.icon.backgroundColor"
                    width="40px"
                    height="40px"
                />
            </v-list-item-icon>
        </template>
    </schema-search-select>
</template>

<script>
    import { camelToPascalCase } from "@/services/stringUtility";
    import ExmIcon from "@/components/ExmIcon";

    export default {
        components: {
            ExmIcon,
        },
        methods: {
            formatIconName(iconName) {
                return camelToPascalCase(iconName);
            },
            hasIcon(item, property) {
                return item.hasOwnProperty(property);
            },
        },
    };
</script>
