import Vue from "vue";
import errorUtility from "../services/errorUtility";

export default Vue.mixin({
    methods: {
        handleError(e) {
            errorUtility.handleError(e);
        },
    },
});
