<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
    export default {
        types: ["bool"],
        props: {
            value: {
                type: [Boolean],
                default: null,
            },
        },
        computed: {
            display() {
                if (this.value == null) {
                    return "";
                }
                return this.value ? "Yes" : "No";
            },
        },
    };
</script>
