<template>
    <div class="app-search">
        <v-textarea
            v-if="!isEditing"
            key="displayField"
            class="app-search-item-display"
            v-bind="$attrs"
            :value="display"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            outlined
            dense
            rows="1"
            auto-grow
            :autofocus="false"
            :clearable="false"
            :append-icon="closeIcon"
            @click:append="remove"
            @focus="edit"
        />
        <app-text-field
            v-if="isEditing"
            ref="editField"
            key="editField"
            v-model="searchText"
            v-bind="$attrs"
            :label="label"
            placeholder="Type To Search"
            :hide-details="hideDetails"
            autocomplete="off"
            autofocus
            @keydown="onKeyDown"
        />

        <v-menu
            :value="menuVisible"
            :close-on-click="false"
            :close-on-content-click="false"
            :disable-keys="true"
            :open-on-click="false"
            max-height="300"
        >
            <template #activator="{ on }">
                <div :style="activatorStyle" v-on="on" />
            </template>
            <v-list
                class="app-search-results"
                v-click-outside="{
                    handler: clear,
                    closeConditional: () => menuVisible,
                }"
            >
                <v-list-item
                    v-for="item in items"
                    :key="item.id"
                    link
                    :class="itemClass(item)"
                    @mousedown="onItemClick(item)"
                >
                    <v-list-item-action>
                        <v-icon v-if="isSelected(item)" color="primary" @click.stop="removeItem(item)">
                            mdi-checkbox-marked
                        </v-icon>
                        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>
                        {{ item.label }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
    import AppTextField from "@/components/AppTextField.vue";
    import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
    import { search } from "@/features/schemas/services/searcher";
    import { getEntity } from "@/features/schemas/services/schemaProvider";
    import { get } from "@/features/schemas/services/schemaApi";
    import { getLabel } from "@/features/schemas/services/labeller";
    import { debounce } from "@/services/debounce";

    export default {
        components: {
            AppTextField,
        },
        props: {
            entityKey: {
                type: String,
                default: null,
                required: true,
            },
            value: {
                type: Array,
                default: () => [],
            },
            label: {
                type: String,
                default: () => "Search",
            },
            filter: {
                type: Object,
                default: null,
            },
            searchOptions: {
                type: Object,
                default: null,
            },
            searchWhenBlank: {
                type: Boolean,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isEditing: false,
                searchText: null,
                results: null,
                selectedIndex: -1,
                selectedItems: [],
                showMenu: false,
            };
        },
        computed: {
            items() {
                return this.results?.items ?? [];
            },
            display() {
                if (this.selectedItems?.length === 0) {
                    return "";
                }

                return this.selectedItems.map((item) => item.label).join(", ");
            },
            activatorStyle() {
                if (this.hideDetails) {
                    return null;
                }
                return {
                    marginTop: "-26px",
                    height: "26px",
                };
            },
            closeIcon() {
                return this.value && this.value.length > 0 ? "mdi-close" : null;
            },
            searchIsBlank() {
                return isNullOrWhiteSpace(trim(this.searchText, ""));
            },
            menuVisible() {
                return this.showMenu && this.items && this.items.length;
            },
        },
        watch: {
            searchText: debounce(function () {
                return this.search();
            }, 250),
            selectedItems(selectedItems) {
                if (this.value && JSON.stringify(this.value) !== JSON.stringify(selectedItems.map((item) => item.id))) {
                    this.$emit(
                        "input",
                        selectedItems.map((item) => item.id)
                    );
                    this.$emit("items", selectedItems);
                }
            },
            value: {
                immediate: true,
                async handler(value) {
                    if (!value?.length) {
                        this.searchText = null;
                        this.selectedItems = [];
                        this.showMenu = false;
                        return;
                    }

                    await this.load();
                },
            },
        },
        methods: {
            async load() {
                let items = await Promise.all(this.value.map((id) => get(this.entityKey, id)));
                items.forEach((item, index) => {
                    item.label = getLabel(this.entityKey, item);
                    this.$set(this.selectedItems, index, item);
                });
            },
            edit() {
                this.isEditing = true;
                this.showMenu = true;
                this.search();
            },
            async search() {
                if (!this.searchWhenBlank && this.searchIsBlank) {
                    this.results = null;
                    return;
                }

                let searchText = this.searchText;

                let options = {
                    filter: this.filter,
                    sortBy: this.searchOptions?.sortBy,
                    direction: this.searchOptions?.direction,
                };

                let results = await search(this.entityKey, searchText, options);

                results.items.forEach((item) => (item.label = getLabel(this.entityKey, item)));
                results.headers.push({
                    value: "label",
                    text: getEntity(this.entityKey).singleTitle,
                });

                // Avoid showing previous search if results arrive out of order.
                if (searchText === this.searchText) {
                    this.results = results;
                }
            },
            onKeyDown(e) {
                let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Tab"];

                if (handledKeys.includes(e.key)) {
                    let handler = this[`on${e.key}`];
                    handler(e);
                }
            },
            onArrowDown(e) {
                let index = this.selectedIndex + 1;
                if (index < this.items.length) {
                    this.selectedIndex = index;
                }
                e.preventDefault();
            },
            onArrowUp(e) {
                let index = this.selectedIndex - 1;
                if (index > -1) {
                    this.selectedIndex = index;
                }
                e.preventDefault();
            },
            onEnter(e) {
                if (this.selectedIndex > -1) {
                    this.toggleItem(this.items[this.selectedIndex]);
                    this.clear();
                }
                e.preventDefault();
            },
            onTab() {
                if (this.selectedIndex > -1) {
                    this.toggleItem(this.items[this.selectedIndex]);
                    this.clear();
                }
            },
            onItemClick(item) {
                this.toggleItem(item);
            },
            clear() {
                this.results = null;
                this.selectedIndex = -1;
                this.isEditing = false;
                this.showMenu = false;
            },
            remove() {
                this.selectedItems = [];
                this.searchText = null;
                this.clear();
            },
            itemClass(item) {
                if (this.isSelected(item)) {
                    return "app-search-selected";
                }
            },
            toggleItem(item) {
                let index = this.selectedItems.findIndex((selected) => selected.id === item.id);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                } else {
                    this.selectedItems.push(item);
                }
                this.$emit(
                    "input",
                    this.selectedItems.map((item) => item.id)
                );
            },
            isSelected(item) {
                return this.selectedItems.some((e) => e.id === item.id);
            },
            removeItem(itemToRemove) {
                this.selectedItems = this.selectedItems.filter((item) => item.id !== itemToRemove.id);
                this.$emit(
                    "input",
                    this.selectedItems.map((item) => item.id)
                );
            },
        },
    };
</script>
<style lang="scss" scoped>
    @import "@/assets/colors.scss";

    .app-search-selected {
        color: $color-2;
        background-color: rgba($color-2, 0.15);
    }

    .app-search-results > * {
        cursor: pointer;
    }
</style>
