<template>
    <v-select
        v-model="valueLocal"
        :items="offsets"
        item-value="value"
        item-text="name"
        :label="label"
        :required="required"
        :dense="dense"
        :outlined="outlined"
        :hide-details="hideDetails"
        :disabled="disabled"
        :readonly="readonly"
    />
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: null,
            },
            value: {
                type: String,
                default: null,
            },
            required: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            dense: {
                type: Boolean,
                default: true,
            },
            outlined: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                offsets: [
                    { value: "-12:00", name: "UTC -12:00" },
                    { value: "-11:00", name: "UTC -11:00" },
                    { value: "-10:00", name: "UTC -10:00" },
                    { value: "-09:30", name: "UTC -09:30" },
                    { value: "-09:00", name: "UTC -09:00" },
                    { value: "-08:00", name: "UTC -08:00" },
                    { value: "-07:00", name: "UTC -07:00" },
                    { value: "-06:00", name: "UTC -06:00" },
                    { value: "-05:00", name: "UTC -05:00" },
                    { value: "-04:00", name: "UTC -04:00" },
                    { value: "-03:30", name: "UTC -03:30" },
                    { value: "-03:00", name: "UTC -03:00" },
                    { value: "-02:00", name: "UTC -02:00" },
                    { value: "-01:00", name: "UTC -01:00" },
                    { value: "Z", name: "UTC" },
                    { value: "+01:00", name: "UTC +01:00" },
                    { value: "+02:00", name: "UTC +02:00" },
                    { value: "+03:00", name: "UTC +03:00" },
                    { value: "+03:30", name: "UTC +03:30" },
                    { value: "+04:00", name: "UTC +04:00" },
                    { value: "+04:30", name: "UTC +04:30" },
                    { value: "+05:00", name: "UTC +05:00" },
                    { value: "+05:30", name: "UTC +05:30" },
                    { value: "+05:45", name: "UTC +05:45" },
                    { value: "+06:00", name: "UTC +06:00" },
                    { value: "+06:30", name: "UTC +06:30" },
                    { value: "+07:00", name: "UTC +07:00" },
                    { value: "+08:00", name: "UTC +08:00" },
                    { value: "+08:45", name: "UTC +08:45" },
                    { value: "+09:00", name: "UTC +09:00" },
                    { value: "+09:30", name: "UTC +09:30" },
                    { value: "+10:00", name: "UTC +10:00" },
                    { value: "+10:30", name: "UTC +10:30" },
                    { value: "+11:00", name: "UTC +11:00" },
                    { value: "+12:00", name: "UTC +12:00" },
                    { value: "+12:45", name: "UTC +12:45" },
                    { value: "+13:00", name: "UTC +13:00" },
                    { value: "+14:00", name: "UTC +14:00" },
                ],
            };
        },

        computed: {
            valueLocal: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
        },
    };
</script>
