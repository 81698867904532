import { hasProperty } from "@/services/objectUtility";
import { buildRules } from "@/services/ruleBuilder";

export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
    },
    computed: {
        required() {
            return hasProperty(this.field, "required");
        },
        getMaxLength() {
            return this.field.maxLength?.length;
        },
        rules() {
            return buildRules(this.field);
        },
    },
};
