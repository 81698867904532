<template>
    <div>
        <schema-table
            :entity-key="entityKey"
            :key="componentKey"
            v-bind="$attrs"
            v-on="$listeners"
            style="height: 100%"
        >
            <template #before-buttons>
                <h2>Ordering</h2>
                <v-spacer />
            </template>
            <template #add-button="{ canAdd }">
                <app-button v-if="canAdd" class="ml-2" @click="add">Add New Order</app-button>
            </template>
        </schema-table>
        <item-order-wizard :value="wizard" @close="onCloseWizard"></item-order-wizard>
    </div>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import itemOrderWizard from "@/features/exmOrdering/ItemOrderWizard.vue";

    export default {
        mixins: [schemaMixin],
        components: { itemOrderWizard },

        data() {
            return {
                wizard: false,
                componentKey: 0,
            };
        },

        methods: {
            add() {
                this.wizard = true;
            },
            async onCloseWizard() {
                this.wizard = false;
                // Refresh the table
                this.componentKey++;
            },
        },
    };
</script>
