import { pascalToCamelCase } from "@/services/stringUtility";
import schemaDataTable from "../components/SchemaDataTable";

const tablesByEntityKey = {};

// Grab all the *DataTable.vue files in this folder (exluding subfolders).
let requireComponent = require.context(".", false, /^.\/([\w-]+)Table\.vue$/);

requireComponent.keys().forEach((filePath) => {
    const table = requireComponent(filePath);
    const entityKey = pascalToCamelCase(filePath.slice(2, -9));
    tablesByEntityKey[entityKey] = table.default;
});

export function getTable(entityKey) {
    return tablesByEntityKey[entityKey] ?? schemaDataTable;
}

export default {
    getTable,
};
