<template>
    <div>
        <schema-table ref="schemaTable" :entity-key="entityKey" v-bind="$attrs" v-on="$listeners" style="height: 100%">
            <template #before-excel-button="{}">
                <!-- #region Excel Importer -->
                <!-- TODO: add location group to the importer-->
                <v-dialog
                    v-if="canEdit('Locations') && canEdit('LocationTypes')"
                    v-model="dialogExcel"
                    max-width="80vw"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" dark v-on="on" class="ml-2 float-right">Import From Excel</v-btn>
                    </template>
                    <excel-importer
                        type="Locations"
                        :fields="excelFields"
                        template="./../files/locations-template.xlsx"
                        @cancel="dialogExcel = false"
                        @save="onSave"
                    ></excel-importer>
                </v-dialog>
            </template>
        </schema-table>
    </div>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import excelImporter from "/src/components/ExcelImporter";

    export default {
        mixins: [schemaMixin],
        components: { excelImporter },
        data() {
            return {
                dialogExcel: false,
                excelFields: [
                    {
                        name: "locationType",
                        label: "Location Type",
                        type: "text",
                        editable: true,
                        required: true,
                        max: 50,
                    },
                    { name: "name", label: "Name", type: "text", editable: true, required: true, max: 50 },
                    { name: "priority", label: "Priority", type: "number", editable: true, required: false, max: null },
                ],
            };
        },

        methods: {
            async onSave() {
                await this.$refs.schemaTable.onItemSaved();
                this.dialogExcel = false;
            },
        },
    };
</script>
