<template>
    <app-dialog :value="value" max-width="1000px">
        <v-form>
            <v-card>
                <v-card-title>
                    <v-stepper v-model="step" style="width: 100%">
                        <v-stepper-header style="overflow: hidden">
                            <v-stepper-step :complete="step > 1" step="1">Item Selection</v-stepper-step>
                            <v-divider />
                            <v-stepper-step v-if="isCustom && isCategoryWizard" :complete="step > 2" step="2">
                                Category Icon
                            </v-stepper-step>
                            <v-divider v-if="isCustom && isCategoryWizard" />
                            <v-stepper-step v-if="isCustom && isCategoryWizard" :complete="step > 3" step="3">
                                Item Setup
                            </v-stepper-step>
                            <v-divider v-if="isCustom && isCategoryWizard" />
                            <v-stepper-step
                                v-if="isCategoryWizard || !isCustom"
                                :complete="(isCustom && step > 2) || (!isCustom && step > 2)"
                                :step="isCustom ? '4' : '2'"
                            >
                                Finalise
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                </v-card-title>
                <v-card-text>
                    <v-stepper v-model="step">
                        <v-stepper-items>
                            <v-stepper-content step="1" class="py-0 px-4">
                                <v-tabs v-model="tab" fixed-tabs>
                                    <v-tab>Catalog</v-tab>
                                    <v-tab>Custom</v-tab>
                                </v-tabs>

                                <v-tabs-items v-model="tab" class="pt-4">
                                    <v-tab-item>
                                        <v-form ref="form-not-custom">
                                            <catalog-selection
                                                v-model="catalogSelection"
                                                :is-category="isCategoryWizard"
                                            />
                                        </v-form>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-form v-if="isCategoryWizard" ref="form-custom">
                                            <custom-selection v-model="customSelection" />
                                        </v-form>
                                        <magazine-item-form
                                            ref="itemForm"
                                            v-else
                                            v-model="item"
                                            entity-key="magazineItem"
                                        >
                                            <template #formTitle><div></div></template>
                                            <template #formActions><div></div></template>
                                        </magazine-item-form>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-stepper-content>
                            <v-stepper-content v-if="isCustom && isCategoryWizard" step="2" class="py-0 px-4">
                                <v-form ref="form-2">
                                    <category-icon ref="form-2" v-model="categoryIcon" class="pt-4" />
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content v-if="isCustom && isCategoryWizard" step="3" class="py-0 px-4">
                                <v-form ref="form-3">
                                    <item-setup
                                        v-model="categoryItems"
                                        :category-name="category"
                                        :show-icon="showIcon"
                                        @update:showIcon="updateShowIcon"
                                    />
                                </v-form>
                            </v-stepper-content>
                            <v-stepper-content :step="isCustom && isCategoryWizard ? '4' : '2'" class="py-0 px-4">
                                <finalise-selection
                                    v-if="category && !isCustom"
                                    :item-name="category.name"
                                    :item-icon="category.icon"
                                    :show-icon="category.isShowIcon"
                                    :items="items"
                                    sort-by="priority"
                                    :sort-desc="true"
                                />
                                <finalise-selection
                                    v-if="category && isCustom && isCategoryWizard"
                                    :item-name="customSelection.category"
                                    :item-description="customSelection.categoryDescription"
                                    :item-icon="categoryIcon"
                                    :show-icon="showIcon"
                                    :items="categoryItems"
                                    sort-by="name"
                                    :sort-desc="false"
                                />
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <v-progress-linear v-if="isSaving" indeterminate color="primary"></v-progress-linear>
                </v-card-text>
                <v-card-actions>
                    <app-button color="primary" text @click="cancel()">
                        {{ step == 1 ? "Cancel" : "Back" }}
                    </app-button>
                    <v-spacer />
                    <app-button text @click="submitForm" :disabled="isSaving">
                        {{ canSave ? "Save" : "Next" }}
                    </app-button>
                </v-card-actions>
            </v-card>
        </v-form>
    </app-dialog>
</template>

<script>
    import axios from "axios";
    import CatalogSelection from "./CatalogSelection.vue";
    import FinaliseSelection from "./FinaliseSelection.vue";
    import ItemSetup from "./ItemSetup.vue";
    import CustomSelection from "./CustomSelection.vue";
    import CategoryIcon from "./CategoryIcon.vue";
    import MagazineItemForm from "@/features/schemas/forms/MagazineItemForm.vue";

    export default {
        components: {
            CatalogSelection,
            FinaliseSelection,
            ItemSetup,
            CustomSelection,
            CategoryIcon,
            MagazineItemForm,
        },
        props: {
            value: Boolean,
            isCategoryWizard: Boolean,
        },
        data() {
            return {
                wizard: false,
                step: 1,
                isValid: false,
                catalogSelection: {
                    manufacturer: null,
                    magazineItemTypeId: null,
                    mappedUnitId: null,
                    mappedCapacityUnitId: null,
                    item: null,
                },
                customSelection: {
                    manufacturer: null,
                    magazineItemTypeId: null,
                    mappedUnitId: null,
                    mappedCapacityUnitId: null,
                    category: null,
                    categoryDescription: null,
                },
                item: null,
                tab: null,
                categoryIcon: {
                    iconImage: null,
                    primaryColor: null,
                    secondaryColor: null,
                    backgroundColor: null,
                },
                categoryItems: [],
                showIcon: true,
                isSaving: false,
            };
        },

        methods: {
            cancel() {
                if (this.step > 1) {
                    this.step--;
                    return;
                }

                this.$emit("close");
            },

            async submitForm() {
                if (this.canSave) {
                    await this.save();
                    return;
                }

                if (this.step == 1) {
                    if (this.isCustom && !this.$refs["form-custom"].validate()) return;
                    if (!this.isCustom && !this.$refs["form-not-custom"].validate()) return;
                }

                if (this.step == 3 && this.isCustom) {
                    for (const [index, item] of this.categoryItems.entries()) {
                        item.priority = this.categoryItems.length - index;
                    }
                }

                const currentForm = `form-${this.step}`;
                if (this.$refs[currentForm] !== undefined && !this.$refs[currentForm].validate()) {
                    return;
                }

                this.step++;
            },

            async saveSelection() {
                const selection = {
                    manufacturer: this.catalogSelection.manufacturer,
                    magazineItemTypeId: this.catalogSelection.magazineItemTypeId,
                    unitId: this.catalogSelection.mappedUnitId,
                    capacityUnitId: this.catalogSelection.mappedCapacityUnitId,
                    name: this.category.name,
                };

                const { data } = this.isCategoryWizard
                    ? await axios.post("/api/MagazineCatalog/SaveCategory", selection)
                    : await axios.post("/api/MagazineCatalog/SaveItem", selection);
                this.$emit("save", data);
            },

            async saveCategoryCustom() {
                const customSelection = {
                    magazineItemManufacturerId: this.customSelection.manufacturer,
                    magazineItemTypeId: this.customSelection.magazineItemTypeId,
                    unitId: this.customSelection.mappedUnitId,
                    capacityUnitId: this.customSelection.mappedCapacityUnitId,
                    categoryIcon: this.categoryIcon,
                    name: this.category,
                    description: this.customSelection.categoryDescription,
                    showIcon: this.showIcon,
                    categoryItems: this.categoryItems,
                };

                const { data } = await axios.post("/api/MagazineCatalog/SaveCustom", customSelection);
                this.$emit("save", data);
            },

            async save() {
                if (this.isSaving) {
                    return;
                }

                this.isSaving = true;

                if (!this.isCategoryWizard && this.isCustom) {
                    const itemForm = this.$refs.itemForm;
                    if (!(await itemForm.save())) {
                        this.isSaving = false;
                        return;
                    }
                    this.$emit("save", this.item.id);
                    return;
                } else if (this.isCustom) {
                    await this.saveCategoryCustom();
                } else {
                    await this.saveSelection();
                }
            },

            updateShowIcon(value) {
                this.showIcon = value;
            },
        },

        computed: {
            category() {
                if (this.isCustom) {
                    return this.customSelection && this.customSelection.category;
                }

                return this.catalogSelection && this.catalogSelection.item;
            },

            isCustom() {
                return this.tab == 1;
            },
            canSave() {
                if (!this.isCategoryWizard && this.isCustom) {
                    return this.step == 1;
                } else if (this.isCustom) {
                    return this.step == 4;
                }
                return this.step == 2;
            },
            items() {
                return this.isCategoryWizard ? this.category.magazineInventoryItems : [this.category];
            },
        },
    };
</script>
