<template>
    <div>
        <schema-data-table ref="table" v-bind="$attrs" v-on="$listeners" sortBy="dateStarted" direction="descending">
            <template #view-item-action="{ item }">
                <v-icon @click.stop="viewCustomItem(item)">mdi-arrow-right-thick</v-icon>
            </template>
        </schema-data-table>
    </div>
</template>

<script>
    export default {
        methods: {
            async viewCustomItem(item) {
                this.$router.push({ path: `/item-order-batch/${item.id}` });
            },
            async refresh() {
                await this.$refs.table.refresh();
            },
        },
    };
</script>
