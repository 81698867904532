export default [
    {
        path: "/activities",
        name: "activities",
        component: () => import(/* webpackChunkName: "activities" */ "./tableViews/Activities.vue"),
        meta: { helpLink: "activities" },
    },
    {
        path: "/activity-time-usage-activities/:id",
        name: "activityTimeUsageActivities",
        component: () =>
            import(
                /* webpackChunkName: "activity-time-usage-activities" */ "./tableViews/ActivityTimeUsageActivities.vue"
            ),
    },
    {
        path: "/agi-trucks",
        name: "agiTrucks",
        component: () => import(/* webpackChunkName: "agi-trucks" */ "./tableViews/AgiTrucks.vue"),
    },
    {
        path: "/agi-truck/:id",
        name: "agiTruck",
        component: () => import(/* webpackChunkName: "agi-truck" */ "./editViews/AgiTruck.vue"),
    },
    {
        path: "/agi-truck-approvals",
        name: "agiTruckApprovals",
        component: () => import(/* webpackChunkName: "agi-truck-approvals" */ "./tableViews/AgiTruckApprovals.vue"),
    },
    {
        path: "/agi-truck-components",
        name: "agiTruckComponents",
        component: () => import(/* webpackChunkName: "agi-truck-components" */ "./tableViews/AgiTruckComponents.vue"),
    },
    {
        path: "/agi-truck-deliveries",
        name: "agiTruckDeliveries",
        component: () => import(/* webpackChunkName: "agi-truck-deliveries" */ "./tableViews/AgiTruckDeliveries.vue"),
    },
    {
        path: "/agi-truck-product-components",
        name: "agiTruckProductComponents",
        component: () =>
            import(/* webpackChunkName: "agi-truck-product-components" */ "./tableViews/AgiTruckProductComponents.vue"),
    },
    {
        path: "/api-clients",
        name: "apiClients",
        component: () => import(/* webpackChunkName: "api-clients" */ "./tableViews/ApiClients.vue"),
    },
    {
        path: "/approvals",
        name: "approvals",
        component: () => import(/* webpackChunkName: "approvals" */ "./tableViews/Approvals.vue"),
    },
    {
        path: "/cable-bolts",
        name: "cableBolts",
        component: () => import(/* webpackChunkName: "cable-bolts" */ "./tableViews/CableBolts.vue"),
    },
    {
        path: "/agi-truck-product/:id",
        name: "agitruckProduct",
        component: () => import(/* webpackChunkName: "agi-truck-products" */ "./editViews/AgiTruckProduct.vue"),
    },
    {
        path: "/cable-bolt/:id",
        name: "cableBolt",
        component: () => import(/* webpackChunkName: "cable-bolt" */ "./editViews/CableBolt.vue"),
    },
    {
        path: "/cable-bolt-activities",
        name: "cableBoltActivities",
        component: () => import(/* webpackChunkName: "cable-bolt-activities" */ "./tableViews/CableBoltActivities.vue"),
    },
    {
        path: "/cable-bolt-approvals",
        name: "cableBoltApprovals",
        component: () => import(/* webpackChunkName: "cable-bolt-approvals" */ "./tableViews/CableBoltApprovals.vue"),
    },
    {
        path: "/cable-bolt-consumables",
        name: "cableBoltConsumables",
        component: () =>
            import(/* webpackChunkName: "cable-bolt-consumables" */ "./tableViews/CableBoltConsumables.vue"),
    },
    {
        path: "/cable-bolt-holes",
        name: "cableBoltHoles",
        component: () => import(/* webpackChunkName: "cable-bolt-holes" */ "./tableViews/CableBoltHoles.vue"),
    },
    {
        path: "/cable-bolt-hole-lengths",
        name: "cableBoltHoleLengths",
        component: () =>
            import(/* webpackChunkName: "cable-bolt-hole-lengths" */ "./tableViews/CableBoltHoleLengths.vue"),
    },
    {
        path: "/cable-bolt-hole-length/:id",
        name: "cableBoltHoleLength",
        component: () => import(/* webpackChunkName: "cable-bolt-hole-length" */ "./editViews/CableBoltHoleLength.vue"),
    },
    {
        path: "/cable-bolt-hole-sizes",
        name: "cableBoltHoleSizes",
        component: () => import(/* webpackChunkName: "cable-bolt-hole-sizes" */ "./tableViews/CableBoltHoleSizes.vue"),
    },
    {
        path: "/cable-bolt-hole-size/:id",
        name: "cableBoltHoleSize",
        component: () => import(/* webpackChunkName: "cable-bolt-hole-size" */ "./editViews/CableBoltHoleSize.vue"),
    },
    {
        path: "/charge-ups",
        name: "chargeUps",
        component: () => import(/* webpackChunkName: "charge-ups" */ "./tableViews/ChargeUps.vue"),
    },
    {
        path: "/charge-up/:id",
        name: "chargeUp",
        component: () => import(/* webpackChunkName: "charge-up" */ "./editViews/ChargeUp.vue"),
    },
    {
        path: "/charge-up-activities",
        name: "chargeUpActivities",
        component: () => import(/* webpackChunkName: "charge-up-activities" */ "./tableViews/ChargeUpActivities.vue"),
    },
    {
        path: "/charge-up-approvals",
        name: "chargeUpApprovals",
        component: () => import(/* webpackChunkName: "charge-up-approvals" */ "./tableViews/ChargeUpApprovals.vue"),
    },
    {
        path: "/charge-up-consumables",
        name: "chargeUpConsumables",
        component: () => import(/* webpackChunkName: "charge-up-consumables" */ "./tableViews/ChargeUpConsumables.vue"),
    },
    {
        path: "/charge-up-crews",
        name: "chargeUpCrews",
        component: () => import(/* webpackChunkName: "charge-up-crews" */ "./tableViews/ChargeUpCrews.vue"),
    },
    {
        path: "/charge-up-files",
        name: "chargeUpFiles",
        component: () => import(/* webpackChunkName: "charge-up-files" */ "./tableViews/ChargeUpFiles.vue"),
    },
    {
        path: "/charge-up-firings",
        name: "chargeUpFirings",
        component: () => import(/* webpackChunkName: "charge-up-firings" */ "./tableViews/ChargeUpFirings.vue"),
    },
    {
        path: "/charge-up-holes",
        name: "chargeUpHoles",
        component: () => import(/* webpackChunkName: "charge-up-holes" */ "./tableViews/ChargeUpHoles.vue"),
    },
    {
        path: "/charge-up-standards",
        name: "chargeUpStandards",
        component: () => import(/* webpackChunkName: "charge-up-standards" */ "./tableViews/ChargeUpStandards.vue"),
    },
    {
        path: "/charge-up-standard/:id",
        name: "chargeUpStandard",
        component: () => import(/* webpackChunkName: "charge-up-standard" */ "./editViews/ChargeUpStandard.vue"),
    },
    {
        path: "/charge-up-standard-activities",
        name: "chargeUpStandardActivities",
        component: () =>
            import(
                /* webpackChunkName: "charge-up-standard-activities" */ "./tableViews/ChargeUpStandardActivities.vue"
            ),
    },
    {
        path: "/charge-up-standard-items",
        name: "chargeUpStandardItems",
        component: () =>
            import(/* webpackChunkName: "charge-up-standard-items" */ "./tableViews/ChargeUpStandardItems.vue"),
    },
    {
        path: "/charge-up-steps",
        name: "chargeUpSteps",
        component: () => import(/* webpackChunkName: "charge-up-steps" */ "./tableViews/ChargeUpSteps.vue"),
    },
    {
        path: "/checklists",
        name: "checklists",
        component: () => import(/* webpackChunkName: "checklists" */ "./tableViews/Checklists.vue"),
        meta: { helpLink: "prestart" },
    },
    {
        path: "/checklist/:id",
        name: "checklist",
        component: () => import(/* webpackChunkName: "checklist" */ "./editViews/Checklist.vue"),
    },
    {
        path: "/checklist-answers",
        name: "checklistAnswers",
        component: () => import(/* webpackChunkName: "checklist-answers" */ "./tableViews/ChecklistAnswers.vue"),
    },
    {
        path: "/checklist-answer-files",
        name: "checklistAnswerFiles",
        component: () =>
            import(/* webpackChunkName: "checklist-answer-files" */ "./tableViews/ChecklistAnswerFiles.vue"),
    },
    {
        path: "/checklist-categories",
        name: "checklistCategories",
        component: () => import(/* webpackChunkName: "checklist-categories" */ "./tableViews/ChecklistCategories.vue"),
    },
    {
        path: "/checklist-category/:id",
        name: "checklistCategory",
        component: () => import(/* webpackChunkName: "checklist-category" */ "./editViews/ChecklistCategory.vue"),
    },
    {
        path: "/checklist-items",
        name: "checklistItems",
        component: () => import(/* webpackChunkName: "checklist-items" */ "./tableViews/ChecklistItems.vue"),
    },
    {
        path: "/claims",
        name: "claims",
        component: () => import(/* webpackChunkName: "claims" */ "./tableViews/Claims.vue"),
    },
    {
        path: "/claim/:id",
        name: "claim",
        component: () => import(/* webpackChunkName: "claim" */ "./editViews/Claim.vue"),
    },
    {
        path: "/consumables",
        name: "consumables",
        component: () => import(/* webpackChunkName: "consumables" */ "./tableViews/Consumables.vue"),
        meta: { helpLink: "inventory" },
    },
    {
        path: "/consumable-groups",
        name: "consumableGroups",
        component: () => import(/* webpackChunkName: "consumable-groups" */ "./tableViews/ConsumableGroups.vue"),
        meta: { helpLink: "inventoryCategories" },
    },
    {
        path: "/consumable-group-operations",
        name: "consumableGroupOperations",
        component: () =>
            import(/* webpackChunkName: "consumable-group-operations" */ "./tableViews/ConsumableGroupOperations.vue"),
    },
    {
        path: "/crews",
        name: "crews",
        component: () => import(/* webpackChunkName: "crews" */ "./tableViews/Crews.vue"),
    },
    {
        path: "/crew-operators",
        name: "crewOperators",
        component: () => import(/* webpackChunkName: "crew-operators" */ "./tableViews/CrewOperators.vue"),
    },
    {
        path: "/default-activities",
        name: "defaultActivities",
        component: () => import(/* webpackChunkName: "default-activities" */ "./tableViews/DefaultActivities.vue"),
    },
    {
        path: "/development-drills",
        name: "developmentDrills",
        component: () => import(/* webpackChunkName: "development-drills" */ "./tableViews/DevelopmentDrills.vue"),
    },
    {
        path: "/development-drill-activities",
        name: "developmentDrillActivities",
        component: () =>
            import(
                /* webpackChunkName: "development-drill-activities" */ "./tableViews/DevelopmentDrillActivities.vue"
            ),
        meta: { helpLink: "developmentDrillActivities" },
    },
    {
        path: "/development-drill-approvals",
        name: "developmentDrillApprovals",
        component: () =>
            import(/* webpackChunkName: "development-drill-approvals" */ "./tableViews/DevelopmentDrillApprovals.vue"),
    },
    {
        path: "/development-drill-batches",
        name: "developmentDrillBatches",
        component: () =>
            import(/* webpackChunkName: "development-drill-batches" */ "./tableViews/DevelopmentDrillBatches.vue"),
    },
    {
        path: "/development-drill-batch-approvals",
        name: "developmentDrillBatchApprovals",
        component: () =>
            import(
                /* webpackChunkName: "development-drill-batch-approvals" */ "./tableViews/DevelopmentDrillBatchApprovals.vue"
            ),
    },
    {
        path: "/development-drill-cut-lengths",
        name: "developmentDrillCutLengths",
        component: () =>
            import(
                /* webpackChunkName: "development-drill-cut-lengths" */ "./tableViews/DevelopmentDrillCutLengths.vue"
            ),
        meta: { helpLink: "developmentDrillCutLengths" },
    },
    {
        path: "/development-drill-standards",
        name: "developmentDrillStandards",
        component: () =>
            import(/* webpackChunkName: "development-drill-standards" */ "./tableViews/DevelopmentDrillStandards.vue"),
        meta: { helpLink: "developmentDrillStandards" },
    },
    {
        path: "/development-drill-standard-activities",
        name: "developmentDrillStandardActivities",
        component: () =>
            import(
                /* webpackChunkName: "development-drill-standard-activities" */ "./tableViews/DevelopmentDrillStandardActivities.vue"
            ),
    },
    {
        path: "/development-drill-standard-items",
        name: "developmentDrillStandardItems",
        component: () =>
            import(
                /* webpackChunkName: "development-drill-standard-items" */ "./tableViews/DevelopmentDrillStandardItems.vue"
            ),
    },
    {
        path: "/development-drill-steps",
        name: "developmentDrillSteps",
        component: () =>
            import(/* webpackChunkName: "development-drill-steps" */ "./tableViews/DevelopmentDrillSteps.vue"),
    },
    {
        path: "/development-drill-types",
        name: "developmentDrillTypes",
        component: () =>
            import(/* webpackChunkName: "development-drill-types" */ "./tableViews/DevelopmentDrillTypes.vue"),
        meta: { helpLink: "developmentDrillTypes" },
    },
    {
        path: "/devices",
        name: "devices",
        component: () => import(/* webpackChunkName: "devices" */ "./tableViews/Devices.vue"),
    },
    {
        path: "/device-types",
        name: "deviceTypes",
        component: () => import(/* webpackChunkName: "device-types" */ "./tableViews/DeviceTypes.vue"),
    },
    {
        path: "/device-type/:id",
        name: "deviceType",
        component: () => import(/* webpackChunkName: "device-type" */ "./editViews/DeviceType.vue"),
    },
    {
        path: "/diamond-drills",
        name: "diamondDrills",
        component: () => import(/* webpackChunkName: "diamond-drills" */ "./tableViews/DiamondDrills.vue"),
    },
    {
        path: "/diamond-drill/:id",
        name: "diamondDrill",
        component: () => import(/* webpackChunkName: "diamond-drill" */ "./editViews/DiamondDrill.vue"),
    },
    {
        path: "/diamond-drill-activities",
        name: "diamondDrillActivities",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-activities" */ "./tableViews/DiamondDrillActivities.vue"),
    },
    {
        path: "/diamond-drill-approvals",
        name: "diamondDrillApprovals",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-approvals" */ "./tableViews/DiamondDrillApprovals.vue"),
    },
    {
        path: "/diamond-drill-bits",
        name: "diamondDrillBits",
        component: () => import(/* webpackChunkName: "diamond-drill-bits" */ "./tableViews/DiamondDrillBits.vue"),
    },
    {
        path: "/diamond-drill-bit/:id",
        name: "diamondDrillBit",
        component: () => import(/* webpackChunkName: "diamond-drill-bit" */ "./editViews/DiamondDrillBit.vue"),
    },
    {
        path: "/diamond-drill-consumables",
        name: "diamondDrillConsumables",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-consumables" */ "./tableViews/DiamondDrillConsumables.vue"),
    },
    {
        path: "/diamond-drill-crews",
        name: "diamondDrillCrews",
        component: () => import(/* webpackChunkName: "diamond-drill-crews" */ "./tableViews/DiamondDrillCrews.vue"),
    },
    {
        path: "/diamond-drill-grouts",
        name: "diamondDrillGrouts",
        component: () => import(/* webpackChunkName: "diamond-drill-grouts" */ "./tableViews/DiamondDrillGrouts.vue"),
    },
    {
        path: "/diamond-drill-grout-approvals",
        name: "diamondDrillGroutApprovals",
        component: () =>
            import(
                /* webpackChunkName: "diamond-drill-grout-approvals" */ "./tableViews/DiamondDrillGroutApprovals.vue"
            ),
    },
    {
        path: "/diamond-drill-holes",
        name: "diamondDrillHoles",
        component: () => import(/* webpackChunkName: "diamond-drill-holes" */ "./tableViews/DiamondDrillHoles.vue"),
    },
    {
        path: "/diamond-drill-hole-sizes",
        name: "diamondDrillHoleSizes",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-hole-sizes" */ "./tableViews/DiamondDrillHoleSizes.vue"),
    },
    {
        path: "/diamond-drill-rod-lengths",
        name: "diamondDrillRodLengths",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-rod-lengths" */ "./tableViews/DiamondDrillRodLengths.vue"),
    },
    {
        path: "/diamond-drill-rod-length/:id",
        name: "diamondDrillRodLength",
        component: () =>
            import(/* webpackChunkName: "diamond-drill-rod-length" */ "./editViews/DiamondDrillRodLength.vue"),
    },
    {
        path: "/diamond-drill-runs",
        name: "diamondDrillRuns",
        component: () => import(/* webpackChunkName: "diamond-drill-runs" */ "./tableViews/DiamondDrillRuns.vue"),
    },
    {
        path: "/diamond-drill-surveys",
        name: "diamondDrillSurveys",
        component: () => import(/* webpackChunkName: "diamond-drill-surveys" */ "./tableViews/DiamondDrillSurveys.vue"),
    },
    {
        path: "/dimension-lookups",
        name: "dimensionLookups",
        component: () => import(/* webpackChunkName: "dimension-lookups" */ "./tableViews/DimensionLookups.vue"),
    },
    {
        path: "/equipments",
        name: "equipments",
        component: () => import(/* webpackChunkName: "equipments" */ "./tableViews/Equipments.vue"),
        meta: { helpLink: "equipments" },
    },
    {
        path: "/equipment/:id",
        name: "equipment",
        component: () => import(/* webpackChunkName: "equipment" */ "./editViews/Equipment.vue"),
    },
    {
        path: "/equipment-capacities",
        name: "equipmentCapacities",
        component: () => import(/* webpackChunkName: "equipment-capacities" */ "./tableViews/EquipmentCapacities.vue"),
    },
    {
        path: "/equipment-statuses",
        name: "equipmentStatuses",
        component: () => import(/* webpackChunkName: "equipment-statuses" */ "./tableViews/EquipmentStatuses.vue"),
        meta: { helpLink: "equipmentStatuses" },
    },
    {
        path: "/equipment-uses",
        name: "equipmentUses",
        component: () => import(/* webpackChunkName: "equipment-uses" */ "./tableViews/EquipmentUses.vue"),
    },
    {
        path: "/equipment-use-consumables",
        name: "equipmentUseConsumables",
        component: () =>
            import(/* webpackChunkName: "equipment-use-consumables" */ "./tableViews/EquipmentUseConsumables.vue"),
    },
    {
        path: "/face-sample-collections",
        name: "faceSampleCollections",
        component: () =>
            import(/* webpackChunkName: "face-sample-collections" */ "./tableViews/FaceSampleCollections.vue"),
    },
    {
        path: "/face-sample-collection/:id",
        name: "faceSampleCollection",
        component: () =>
            import(/* webpackChunkName: "face-sample-collection" */ "./editViews/FaceSampleCollection.vue"),
    },
    {
        path: "/face-sample-collection-bags",
        name: "faceSampleCollectionBags",
        component: () =>
            import(/* webpackChunkName: "face-sample-collection-bags" */ "./tableViews/FaceSampleCollectionBags.vue"),
    },
    {
        path: "/face-sample-requests",
        name: "faceSampleRequests",
        component: () => import(/* webpackChunkName: "face-sample-requests" */ "./tableViews/FaceSampleRequests.vue"),
    },
    {
        path: "/face-sample-request/:id",
        name: "faceSampleRequest",
        component: () => import(/* webpackChunkName: "face-sample-request" */ "./editViews/FaceSampleRequest.vue"),
    },
    {
        path: "/face-sample-request-bags",
        name: "faceSampleRequestBags",
        component: () =>
            import(/* webpackChunkName: "face-sample-request-bags" */ "./tableViews/FaceSampleRequestBags.vue"),
    },
    {
        path: "/files",
        name: "files",
        component: () => import(/* webpackChunkName: "files" */ "./tableViews/Files.vue"),
    },
    {
        path: "/form-groups",
        name: "formGroups",
        component: () => import(/* webpackChunkName: "form-groups" */ "./tableViews/FormGroups.vue"),
    },
    {
        path: "/form-group/:id",
        name: "formGroup",
        component: () => import(/* webpackChunkName: "form-group" */ "./editViews/FormGroup.vue"),
    },
    {
        path: "/form-records",
        name: "formRecords",
        component: () => import(/* webpackChunkName: "form-records" */ "./tableViews/FormRecords.vue"),
    },
    {
        path: "/form-sections",
        name: "formSections",
        component: () => import(/* webpackChunkName: "form-sections" */ "./tableViews/FormSections.vue"),
    },
    {
        path: "/form-section/:id",
        name: "formSection",
        component: () => import(/* webpackChunkName: "form-section" */ "./editViews/FormSection.vue"),
    },
    {
        path: "/hauls",
        name: "hauls",
        component: () => import(/* webpackChunkName: "hauls" */ "./tableViews/Hauls.vue"),
    },
    {
        path: "/haul-batches",
        name: "haulBatches",
        component: () => import(/* webpackChunkName: "haul-batches" */ "./tableViews/HaulBatches.vue"),
    },
    {
        path: "/haul-batch-approvals",
        name: "haulBatchApprovals",
        component: () => import(/* webpackChunkName: "haul-batch-approvals" */ "./tableViews/HaulBatchApprovals.vue"),
    },
    {
        path: "/haul-steps",
        name: "haulSteps",
        component: () => import(/* webpackChunkName: "haul-steps" */ "./tableViews/HaulSteps.vue"),
    },
    {
        path: "/api-syncs",
        name: "apiSyncs",
        component: () => import(/* webpackChunkName: "api-syncs" */ "./tableViews/ApiSyncs.vue"),
    },
    {
        path: "/instruction-plan-files",
        name: "instructionPlanFiles",
        component: () =>
            import(/* webpackChunkName: "instruction-plan-files" */ "./tableViews/InstructionPlanFiles.vue"),
    },
    {
        path: "/loads",
        name: "loads",
        component: () => import(/* webpackChunkName: "loads" */ "./tableViews/Loads.vue"),
    },
    {
        path: "/load-batches",
        name: "loadBatches",
        component: () => import(/* webpackChunkName: "load-batches" */ "./tableViews/LoadBatches.vue"),
    },
    {
        path: "/load-batch-approvals",
        name: "loadBatchApprovals",
        component: () => import(/* webpackChunkName: "load-batch-approvals" */ "./tableViews/LoadBatchApprovals.vue"),
    },
    {
        path: "/location-distances",
        name: "locationDistances",
        component: () => import(/* webpackChunkName: "location-distances" */ "./tableViews/LocationDistances.vue"),
    },
    {
        path: "/location-types",
        name: "locationTypes",
        component: () => import(/* webpackChunkName: "location-types" */ "./tableViews/LocationTypes.vue"),
        meta: { helpLink: "locationTypes" },
    },
    {
        path: "/location-type-filters",
        name: "locationTypeFilters",
        component: () => import(/* webpackChunkName: "location-type-filters" */ "./tableViews/LocationTypeFilters.vue"),
    },
    {
        path: "/location-type/:id",
        name: "locationType",
        component: () => import(/* webpackChunkName: "location-type" */ "./editViews/LocationType.vue"),
    },
    {
        path: "/makes",
        name: "makes",
        component: () => import(/* webpackChunkName: "makes" */ "./tableViews/Makes.vue"),
        meta: { helpLink: "makes" },
    },
    {
        path: "/make/:id",
        name: "make",
        component: () => import(/* webpackChunkName: "make" */ "./editViews/Make.vue"),
    },
    {
        path: "/meters",
        name: "meters",
        component: () => import(/* webpackChunkName: "meters" */ "./tableViews/Meters.vue"),
        meta: { helpLink: "meters" },
    },
    {
        path: "/meter-readings",
        name: "meterReadings",
        component: () => import(/* webpackChunkName: "meter-readings" */ "./tableViews/MeterReadings.vue"),
    },
    {
        path: "/models",
        name: "models",
        component: () => import(/* webpackChunkName: "models" */ "./tableViews/Models.vue"),
        meta: { helpLink: "models" },
    },
    {
        path: "/model/:id",
        name: "model",
        component: () => import(/* webpackChunkName: "model" */ "./editViews/Model.vue"),
    },
    {
        path: "/model-capacities",
        name: "modelCapacities",
        component: () => import(/* webpackChunkName: "model-capacities" */ "./tableViews/ModelCapacities.vue"),
    },
    {
        path: "/model-meters",
        name: "modelMeters",
        component: () => import(/* webpackChunkName: "model-meters" */ "./tableViews/ModelMeters.vue"),
    },
    {
        path: "/model-operations",
        name: "modelOperations",
        component: () => import(/* webpackChunkName: "model-operations" */ "./tableViews/ModelOperations.vue"),
    },
    {
        path: "/modules",
        name: "modules",
        component: () => import(/* webpackChunkName: "modules" */ "./tableViews/Modules.vue"),
        meta: { isMasterOnly: true },
    },
    {
        path: "/module/:id",
        name: "module",
        component: () => import(/* webpackChunkName: "model" */ "./editViews/Module.vue"),
    },
    {
        path: "/open-pit-drill-approvals",
        name: "openPitDrillApprovals",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-approvals" */ "./tableViews/OpenPitDrillApprovals.vue"),
    },
    {
        path: "/open-pit-drill-batches",
        name: "openPitDrillBatches",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-batches" */ "./tableViews/OpenPitDrillBatches.vue"),
    },
    {
        path: "/open-pit-drill-batch/:id",
        name: "openPitDrillBatch",
        component: () => import(/* webpackChunkName: "open-pit-drill-batch" */ "./editViews/OpenPitDrillBatch.vue"),
    },
    {
        path: "/open-pit-drill-consumables",
        name: "openPitDrillConsumables",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-consumables" */ "./tableViews/OpenPitDrillConsumables.vue"),
    },
    {
        path: "/open-pit-drill-hole-records",
        name: "openPitDrillHoleRecords",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-hole-records" */ "./tableViews/OpenPitDrillHoleRecords.vue"),
    },
    {
        path: "/open-pit-drill-hole-record/:id",
        name: "openPitDrillHoleRecord",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-hole-record" */ "./editViews/OpenPitDrillHoleRecord.vue"),
    },
    {
        path: "/open-pit-drill-plan-holes",
        name: "openPitDrillPlanHoles",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-plan-holes" */ "./tableViews/OpenPitDrillPlanHoles.vue"),
    },
    {
        path: "/open-pit-drill-plan-hole/:id",
        name: "openPitDrillPlanHole",
        component: () =>
            import(/* webpackChunkName: "open-pit-drill-plan-hole" */ "./editViews/OpenPitDrillPlanHole.vue"),
    },
    {
        path: "/open-pit-drill-quality-checks",
        name: "openPitDrillQualityChecks",
        component: () =>
            import(
                /* webpackChunkName: "open-pit-drill-quality-checks" */ "./tableViews/OpenPitDrillQualityChecks.vue"
            ),
    },
    {
        path: "/operations",
        name: "operations",
        component: () => import(/* webpackChunkName: "operations" */ "./tableViews/Operations.vue"),
        meta: { isMasterOnly: true },
    },
    {
        path: "/operators",
        name: "operators",
        component: () => import(/* webpackChunkName: "operators" */ "./tableViews/Operators.vue"),
        meta: { helpLink: "operators" },
    },
    {
        path: "/operator/:id",
        name: "operator",
        component: () => import(/* webpackChunkName: "model" */ "./editViews/Operator.vue"),
    },
    {
        path: "/operator-modules",
        name: "operatorModules",
        component: () => import(/* webpackChunkName: "operator-modules" */ "./tableViews/OperatorModules.vue"),
    },
    {
        path: "/operator-shifts",
        name: "operatorShifts",
        component: () => import(/* webpackChunkName: "operator-shifts" */ "./tableViews/OperatorShifts.vue"),
    },
    {
        path: "/operator-shift-task-development-drills",
        name: "operatorShiftTaskDevelopmentDrills",
        component: () =>
            import(
                /* webpackChunkName: "operator-shift-task-development-drills" */ "./tableViews/OperatorShiftTaskDevelopmentDrills.vue"
            ),
    },
    {
        path: "/operator-shift-task-hauls",
        name: "operatorShiftTaskHauls",
        component: () =>
            import(/* webpackChunkName: "operator-shift-task-hauls" */ "./tableViews/OperatorShiftTaskHauls.vue"),
    },
    {
        path: "/operator-shift-task-loads",
        name: "operatorShiftTaskLoads",
        component: () =>
            import(/* webpackChunkName: "operator-shift-task-loads" */ "./tableViews/OperatorShiftTaskLoads.vue"),
    },
    {
        path: "/operator-shift-task-production-drills",
        name: "operatorShiftTaskProductionDrills",
        component: () =>
            import(
                /* webpackChunkName: "operator-shift-task-production-drills" */ "./tableViews/OperatorShiftTaskProductionDrills.vue"
            ),
    },
    {
        path: "/operator-shift-task-service-crews",
        name: "operatorShiftTaskServiceCrews",
        component: () =>
            import(
                /* webpackChunkName: "operator-shift-task-service-crews" */ "./tableViews/OperatorShiftTaskServiceCrews.vue"
            ),
    },
    {
        path: "/operator-shift-task-charge-ups",
        name: "operatorShiftTaskChargeUps",
        component: () =>
            import(
                /* webpackChunkName: "operator-shift-task-charge-ups" */ "./tableViews/OperatorShiftTaskChargeUps.vue"
            ),
    },
    {
        path: "/operator-shift-task-squads",
        name: "operatorShiftTaskSquads",
        component: () =>
            import(/* webpackChunkName: "operator-shift-task-squads" */ "./tableViews/OperatorShiftTaskSquads.vue"),
    },
    {
        path: "/products",
        name: "products",
        component: () => import(/* webpackChunkName: "products" */ "./tableViews/Products.vue"),
        meta: { helpLink: "products" },
    },
    {
        path: "/production-drill-activities",
        name: "productionDrillActivities",
        component: () =>
            import(/* webpackChunkName: "production-drill-activities" */ "./tableViews/ProductionDrillActivities.vue"),
        meta: { helpLink: "productionDrillActivities" },
    },
    {
        path: "/production-drill-consumables",
        name: "productionDrillConsumables",
        component: () =>
            import(
                /* webpackChunkName: "production-drill-consumables" */ "./tableViews/ProductionDrillConsumables.vue"
            ),
    },
    {
        path: "/production-drill-holes",
        name: "productionDrillHoles",
        component: () =>
            import(/* webpackChunkName: "production-drill-holes" */ "./tableViews/ProductionDrillHoles.vue"),
    },
    {
        path: "/production-drill-hole-approvals",
        name: "productionDrillHoleApprovals",
        component: () =>
            import(
                /* webpackChunkName: "production-drill-hole-approvals" */ "./tableViews/ProductionDrillHoleApprovals.vue"
            ),
    },
    {
        path: "/production-drill-plans",
        name: "productionDrillPlans",
        component: () =>
            import(/* webpackChunkName: "production-drill-plans" */ "./tableViews/ProductionDrillPlans.vue"),
        meta: { helpLink: "productionDrillPlans" },
    },
    {
        path: "/production-drill-plan-holes",
        name: "productionDrillPlanHoles",
        component: () =>
            import(/* webpackChunkName: "production-drill-plan-holes" */ "./tableViews/ProductionDrillPlanHoles.vue"),
    },
    {
        path: "/production-drill-rings",
        name: "productionDrillRings",
        component: () =>
            import(/* webpackChunkName: "production-drill-rings" */ "./tableViews/ProductionDrillRings.vue"),
    },
    {
        path: "/production-drill-ring/:id",
        name: "productionDrillRing",
        component: () => import(/* webpackChunkName: "production-drill-ring" */ "./editViews/ProductionDrillRing.vue"),
    },
    {
        path: "/productive-times",
        name: "productiveTimes",
        component: () => import(/* webpackChunkName: "productive-times" */ "./tableViews/ProductiveTimes.vue"),
    },
    {
        path: "/raise-bores",
        name: "raiseBores",
        component: () => import(/* webpackChunkName: "raise-bores" */ "./tableViews/RaiseBores.vue"),
    },
    {
        path: "/raise-bore/:id",
        name: "raiseBore",
        component: () => import(/* webpackChunkName: "raise-bore" */ "./editViews/RaiseBore.vue"),
    },
    {
        path: "/raise-bore-approvals",
        name: "raiseBoreApprovals",
        component: () => import(/* webpackChunkName: "raise-bore-approvals" */ "./tableViews/RaiseBoreApprovals.vue"),
    },
    {
        path: "/raise-bore-bits",
        name: "raiseBoreBits",
        component: () => import(/* webpackChunkName: "raise-bore-bits" */ "./tableViews/RaiseBoreBits.vue"),
    },
    {
        path: "/raise-bore-bit/:id",
        name: "raiseBoreBit",
        component: () => import(/* webpackChunkName: "raise-bore-bit" */ "./editViews/RaiseBoreBit.vue"),
    },
    {
        path: "/raise-bore-consumables",
        name: "raiseBoreConsumables",
        component: () =>
            import(/* webpackChunkName: "raise-bore-consumables" */ "./tableViews/RaiseBoreConsumables.vue"),
    },
    {
        path: "/raise-bore-crews",
        name: "raiseBoreCrews",
        component: () => import(/* webpackChunkName: "raise-bore-crews" */ "./tableViews/RaiseBoreCrews.vue"),
    },
    {
        path: "/raise-bore-files",
        name: "raiseBoreFiles",
        component: () => import(/* webpackChunkName: "raise-bore-files" */ "./tableViews/RaiseBoreFiles.vue"),
    },
    {
        path: "/raise-bore-holes",
        name: "raiseBoreHoles",
        component: () => import(/* webpackChunkName: "raise-bore-holes" */ "./tableViews/RaiseBoreHoles.vue"),
    },
    {
        path: "/raise-bore-hole-sizes",
        name: "raiseBoreHoleSizes",
        component: () => import(/* webpackChunkName: "raise-bore-hole-sizes" */ "./tableViews/RaiseBoreHoleSizes.vue"),
    },
    {
        path: "/raise-bore-rod-lengths",
        name: "raiseBoreRodLengths",
        component: () =>
            import(/* webpackChunkName: "raise-bore-rod-lengths" */ "./tableViews/RaiseBoreRodLengths.vue"),
    },
    {
        path: "/raise-bore-runs",
        name: "raiseBoreRuns",
        component: () => import(/* webpackChunkName: "raise-bore-runs" */ "./tableViews/RaiseBoreRuns.vue"),
    },
    {
        path: "/roles",
        name: "roles",
        component: () => import(/* webpackChunkName: "roles" */ "./tableViews/Roles.vue"),
        meta: { helpLink: "roles" },
    },
    {
        path: "/role-claims",
        name: "roleClaims",
        component: () => import(/* webpackChunkName: "role-claims" */ "./tableViews/RoleClaims.vue"),
    },
    {
        path: "/services",
        name: "services",
        component: () => import(/* webpackChunkName: "services" */ "./tableViews/Services.vue"),
    },
    {
        path: "/shift-allocation-configurations",
        name: "shiftAllocationConfigurations",
        component: () => import(/* webpackChunkName: "services" */ "./tableViews/ShiftAllocationConfigurations.vue"),
    },
    {
        path: "/service-activities",
        name: "serviceActivities",
        component: () => import(/* webpackChunkName: "service-activities" */ "./tableViews/ServiceActivities.vue"),
    },
    {
        path: "/service-approvals",
        name: "serviceApprovals",
        component: () => import(/* webpackChunkName: "service-approvals" */ "./tableViews/ServiceApprovals.vue"),
    },
    {
        path: "/service-consumables",
        name: "serviceConsumables",
        component: () => import(/* webpackChunkName: "service-consumables" */ "./tableViews/ServiceConsumables.vue"),
    },
    {
        path: "/service-crews",
        name: "serviceCrews",
        component: () => import(/* webpackChunkName: "service-crews" */ "./tableViews/ServiceCrews.vue"),
    },
    {
        path: "/service-files",
        name: "serviceFiles",
        component: () => import(/* webpackChunkName: "service-files" */ "./tableViews/ServiceFiles.vue"),
    },
    {
        path: "/service-locations",
        name: "serviceLocations",
        component: () => import(/* webpackChunkName: "service-locations" */ "./tableViews/ServiceLocations.vue"),
    },
    {
        path: "/service-steps",
        name: "serviceSteps",
        component: () => import(/* webpackChunkName: "service-steps" */ "./tableViews/ServiceSteps.vue"),
    },
    {
        path: "/shifts",
        name: "shifts",
        component: () => import(/* webpackChunkName: "shifts" */ "./tableViews/Shifts.vue"),
        meta: { helpLink: "shifts" },
    },
    {
        path: "/shift-allocation-activities",
        name: "shiftAllocationActivities",
        component: () =>
            import(/* webpackChunkName: "shift-allocation-activities" */ "./tableViews/ShiftAllocationActivities.vue"),
    },
    {
        path: "/shift-allocation-time-usage-activities",
        name: "shiftAllocationTimeUsageActivities",
        component: () =>
            import(
                /* webpackChunkName: "shift-allocation-time-usage-activities" */ "./tableViews/ShiftAllocationTimeUsageActivities.vue"
            ),
    },
    {
        path: "/shotcretes",
        name: "shotcretes",
        component: () => import(/* webpackChunkName: "shotcretes" */ "./tableViews/Shotcretes.vue"),
    },
    {
        path: "/shotcrete-activities",
        name: "shotcreteActivities",
        component: () => import(/* webpackChunkName: "shotcrete-activities" */ "./tableViews/ShotcreteActivities.vue"),
    },
    {
        path: "/shotcrete-approvals",
        name: "shotcreteApprovals",
        component: () => import(/* webpackChunkName: "shotcrete-approvals" */ "./tableViews/ShotcreteApprovals.vue"),
    },
    {
        path: "/shotcrete-consumables",
        name: "shotcreteConsumables",
        component: () =>
            import(/* webpackChunkName: "shotcrete-consumables" */ "./tableViews/ShotcreteConsumables.vue"),
    },
    {
        path: "/shotcrete-products",
        name: "shotcreteProducts",
        component: () => import(/* webpackChunkName: "shotcrete-products" */ "./tableViews/ShotcreteProducts.vue"),
    },
    {
        path: "/shotcrete-product/:id",
        name: "shotcreteProduct",
        component: () => import(/* webpackChunkName: "shotcrete-product" */ "./editViews/ShotcreteProduct.vue"),
    },
    {
        path: "/shotcrete-standards",
        name: "shotcreteStandards",
        component: () => import(/* webpackChunkName: "shotcrete-standards" */ "./tableViews/ShotcreteStandards.vue"),
    },
    {
        path: "/shotcrete-thicknesses",
        name: "shotcreteThicknesses",
        component: () =>
            import(/* webpackChunkName: "shotcrete-thicknesses" */ "./tableViews/ShotcreteThicknesses.vue"),
    },
    {
        path: "/shotcrete-thickness/:id",
        name: "shotcreteThickness",
        component: () => import(/* webpackChunkName: "shotcrete-thickness" */ "./editViews/ShotcreteThickness.vue"),
    },
    {
        path: "/site-instruction/:locationId/:moduleId/:userInterface",
        name: "siteInstruction",
        component: () => import(/* webpackChunkName: "instruction-plan-files" */ "./editViews/SiteInstruction.vue"),
    },
    {
        path: "/sites",
        name: "sites",
        component: () => import(/* webpackChunkName: "sites" */ "./tableViews/Sites.vue"),
    },
    {
        path: "/time-usage-activities",
        name: "timeUsageActivities",
        component: () => import(/* webpackChunkName: "time-usage-activities" */ "./tableViews/TimeUsageActivities.vue"),
        meta: { helpLink: "timeUsageActivities" },
    },
    {
        path: "/time-usage-activity/:id",
        name: "timeUsageActivity",
        component: () => import(/* webpackChunkName: "time-usage-activity" */ "./editViews/TimeUsageActivity.vue"),
    },
    {
        path: "/time-usage-activity-operations",
        name: "timeUsageActivityOperations",
        component: () =>
            import(
                /* webpackChunkName: "time-usage-activity-operations" */ "./tableViews/TimeUsageActivityOperations.vue"
            ),
    },
    {
        path: "/time-usage-categories",
        name: "timeUsageCategories",
        component: () => import(/* webpackChunkName: "time-usage-categories" */ "./tableViews/TimeUsageCategories.vue"),
        meta: { helpLink: "timeUsageCategories" },
    },
    {
        path: "/time-usage-category/:id",
        name: "timeUsageCategory",
        component: () => import(/* webpackChunkName: "time-usage-category" */ "./editViews/TimeUsageCategory.vue"),
    },
    {
        path: "/units",
        name: "units",
        component: () => import(/* webpackChunkName: "units" */ "./tableViews/Units.vue"),
        meta: { helpLink: "units" },
    },
    {
        path: "/uploads",
        name: "uploads",
        component: () => import(/* webpackChunkName: "uploads" */ "./tableViews/Uploads.vue"),
    },
    {
        path: "/user-claims",
        name: "userClaims",
        component: () => import(/* webpackChunkName: "user-claims" */ "./tableViews/UserClaims.vue"),
    },
    {
        path: "/user-logins",
        name: "userLogins",
        component: () => import(/* webpackChunkName: "user-logins" */ "./tableViews/UserLogins.vue"),
    },
    {
        path: "/user-roles",
        name: "userRoles",
        component: () => import(/* webpackChunkName: "user-roles" */ "./tableViews/UserRoles.vue"),
    },
    {
        path: "/user-tokens",
        name: "userTokens",
        component: () => import(/* webpackChunkName: "user-tokens" */ "./tableViews/UserTokens.vue"),
    },
];
