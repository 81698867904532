<template>
    <app-field-menu v-model="showDatePicker" icon="mdi-calendar">
        <v-date-picker v-model="pickerDate" v-bind="$attrs" />
    </app-field-menu>
</template>

<script>
    import { isNullOrWhiteSpace } from "@/services/stringUtility";
    import { localize, parseFromFormat, parseIso } from "@/services/dateUtility";

    // Consider the following represents a full date with an offset:
    // 2019-09-28T00:00:00+08:00

    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                showDatePicker: false,
            };
        },

        computed: {
            // v-date-picker requires the value be formatted yyyy-MM-dd. This computed property
            // converts the date value to and from this format, defaulting to today if the current date
            // is not specified.
            pickerDate: {
                get() {
                    // v-date-picker accepts inputs as yyyy-MM-dd:
                    let dateTime = parseIso(this.value);
                    return dateTime?.toFormat("yyyy-MM-dd");
                },
                set(value) {
                    this.showDatePicker = false;
                    if (isNullOrWhiteSpace(value)) {
                        return;
                    }

                    let dateTime = localize(parseFromFormat(value, "yyyy-MM-dd"));
                    if (dateTime.isValid) {
                        this.$emit("input", dateTime.toISO());
                    }
                    this.pickerDate = null;
                },
            },
        },
    };
</script>
