import userInterfaceEnum from "../enums/userInterfaceEnum.js";

//filter out shared fields
const filter = {
    operatorId: null,
    moduleId: null,
    shiftId: null,
    date: null,
    equipmentId: null,
    comments: null,
    status: null,
    activityId: null,
    timeUsageActivityId: null,
};

const schema = {
    [userInterfaceEnum.Load]: {
        component: () => import("../forms/OperatorShiftTaskLoadForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: filter,
        url: "/api/OperatorShiftTaskLoads/",
    },
    [userInterfaceEnum.Haul]: {
        component: () => import("../forms/OperatorShiftTaskHaulForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: filter,
        url: "api/OperatorShiftTaskHauls/",
    },
    [userInterfaceEnum.HaulOpenPit]: {
        component: () => import("../forms/OperatorShiftTaskHaulForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: filter,
        url: "api/OperatorShiftTaskHauls/",
    },
    [userInterfaceEnum.DevelopmentDrill]: {
        component: () => import("../forms/OperatorShiftTaskDevelopmentDrillForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: { ...filter, developmentDrillBatchId: null },
        url: "/api/OperatorShiftTaskDevelopmentDrills/",
    },
    [userInterfaceEnum.AirLeg]: {
        component: () => import("../forms/OperatorShiftTaskDevelopmentDrillForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: { ...filter, developmentDrillBatchId: null },
        url: "/api/OperatorShiftTaskDevelopmentDrills/",
    },
    [userInterfaceEnum.ProductionDrill]: {
        component: () => import("../forms/OperatorShiftTaskProductionDrillForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: { ...filter, productionDrillHoleId: null, productionDrillPlanId: null },
        url: "/api/OperatorShiftTaskProductionDrills/",
    },
    [userInterfaceEnum.ServiceCrew]: {
        component: () => import("../forms/OperatorShiftTaskServiceCrewForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: { ...filter, serviceId: null },
        url: "/api/OperatorShiftTaskServiceCrews/",
    },
    [userInterfaceEnum.Charge]: {
        component: () => import("../forms/OperatorShiftTaskChargeUpForm.vue"),
        dialog: false,
        data: null,
        readOnly: false,
        filter: { ...filter, chargeUpId: null },
        url: "/api/OperatorShiftTaskChargeUps/",
    },
};

export default schema;
