import { isDate, parseDate } from "@/services/dateUtility";
import { and, or, equal, contains, greaterThanOrEqual, lessThan } from "@/services/filtering";
import enumService from "@/services/enumService";
import { getEntity, getEnums } from "./schemaProvider";
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { removeLabelFormatting, getLabelFields } from "./labeller";
import { hasProperty } from "@/services/objectUtility";

/**
 *
 * @param {*} fields
 * @param {*} searchText
 * @param {*} depth How deep in searching to go? 1 depth means it will also search every property on a foreign key,
 * anything higher wouldn't be useful for our cases because you would be searching for stuff not visible to the user anyway.
 * @param {*} filterFields
 * @returns
 */
export function buildSearchFilter(fields, searchText, depth = 1, filterFields = false) {
    if (isNullOrWhiteSpace(searchText)) {
        return null;
    }
    let wholePhraseMatches = buildTokenFilter(fields, trim(searchText, '"'), depth, filterFields);

    let tokens = extractTokens(searchText);

    if (tokens.length === 1) {
        return wholePhraseMatches;
    }

    let filters = tokens.map((token) => buildTokenFilter(fields, token, depth, filterFields));

    // Use "and" because each token must match.
    let eachTokenMatches = and(filters);

    return or([wholePhraseMatches, eachTokenMatches]);
}

// This splits the search text into tokens. By default each word is a token, unless we
// have double quotes which explicitly define the token boundary.
function extractTokens(searchText) {
    searchText = searchText.trim();
    // This regex finds any text enclosed within double quotes.
    let phraseRegex = /"[^"]*("|$)/g;

    let phrases = searchText.match(phraseRegex)?.map((p) => trim(p, '"')) ?? [];

    // Remove the phrases from the search string.
    searchText = searchText.replace(phraseRegex, "");

    // Treat the remaining words as separate tokens.
    let words = searchText.split(" ");

    let tokens = phrases.concat(words).map((token) => token.trim());

    let uniqueTokens = [...new Set(tokens)];

    return uniqueTokens.filter((token) => !isNullOrWhiteSpace(token));
}

function buildTokenFilter(fields, token, depth, filterFields) {
    return or(getTokenFilters(fields, token, depth, filterFields));
}

function getTokenFilters(fields, token, depth, filterFields, prefix) {
    let filters = [];
    let fieldEntries = Object.entries(fields);
    prefix = prefix == null ? "" : prefix;
    for (let i = 0; i < fieldEntries.length; i++) {
        let field = fieldEntries[i][1];
        let property = prefix + field.key;
        if (filterFields && !isFieldValid(property, field, depth)) {
            continue;
        }
        filters = getFieldFilters(token, property, filters, field, depth, filterFields, prefix);
    }
    return filters;
}

function getType(field) {
    return field.type.replace("?", "");
}

function isFieldValid(property, field, depth) {
    let type = getType(field);
    let labelTypes = ["datetimeoffset", "datetime", "string"];
    let enums = Object.keys(getEnums());
    let tableTypes = ["decimal", "int", ...enums, ...labelTypes];
    if (depth > 1) {
        return tableTypes.includes(type);
    }
    if (depth === 1) {
        return labelTypes.includes(type) || (property.endsWith("Id") && type === "guid");
    }
    return labelTypes.includes(type);
}

function getFieldFilters(token, property, filters, field, depth, filterFields, prefix) {
    let type = getType(field);

    if (depth > 0 && isForeignKeyField(field)) {
        return addForeignKeyFilters(token, filters, field, depth, filterFields, prefix);
    }

    filters = addDateTimeOffsetFilters(token, property, field, filters, type);
    filters = addDateTimeFilters(token, property, field, filters, type);
    filters = addStringFilters(token, property, field, filters, type);
    if (depth === 2 || !filterFields) {
        filters = addIntegerFilters(token, property, field, filters, type);
        filters = addDecimalFilters(token, property, field, filters, type);
        filters = addEnumFilters(token, property, field, filters, type);
    }
    return filters;
}

function addForeignKeyFilters(token, filters, field, depth, filterFields, prefix) {
    if (!isForeignKeyField(field)) {
        return filters;
    }

    let foreignKeyFields = [];
    let nextPrefix = "";
    // If we are building a search filter for foreign keys,
    // we should only be wanting to search for what is visible to the end-user so those are label fields.
    if (hasProperty(field, "dependsOn")) {
        foreignKeyFields = getLabelFields(field.dependsOn.type);
        nextPrefix = prefix + field.dependsOn.type + ".";
    } else {
        foreignKeyFields = getLabelFields(field.type);
        nextPrefix = prefix + field.type + ".";
    }

    let foreignKeyFilters = getTokenFilters(foreignKeyFields, token, depth, filterFields, nextPrefix);
    filters = filters.concat(foreignKeyFilters);
    return filters;
}

// Some fields may not have a dependsOn property, but are still a foreign key lookup.
function isForeignKeyField(field) {
    return hasProperty(field, "dependsOn") || getEntity(field.type) !== null;
}

function addDateTimeOffsetFilters(token, property, field, filters, type) {
    if (type !== "datetimeoffset" || !isDate(removeLabelFormatting(field, token))) {
        return filters;
    }
    let from = parseDate(removeLabelFormatting(field, token));
    let to = from.plus({ days: 1 });
    let isoFrom = from.toISO();
    let isoTo = to.toISO();
    let dateFilter = and([
        greaterThanOrEqual(property, isoFrom, "datetimeoffset"),
        lessThan(property, isoTo, "datetimeoffset"),
    ]);
    filters.push(dateFilter);
    return filters;
}

function addDateTimeFilters(token, property, field, filters, type) {
    if (type !== "datetime" || !isDate(removeLabelFormatting(field, token))) {
        return filters;
    }
    let from = parseDate(removeLabelFormatting(field, token)).toUTC();
    let to = from.plus({ days: 1 });
    let isoFrom = from.toISO();
    let isoTo = to.toISO();
    let dateFilter = and([greaterThanOrEqual(property, isoFrom, "datetime"), lessThan(property, isoTo, "datetime")]);
    filters.push(dateFilter);
    return filters;
}

function addIntegerFilters(token, property, field, filters, type) {
    if (type != "int" || !Number.isInteger(+removeLabelFormatting(field, token))) {
        return filters;
    }
    filters.push(equal(property, +removeLabelFormatting(field, token), type));
    return filters;
}

function addDecimalFilters(token, property, field, filters, type) {
    if (type !== "decimal" || isNaN(+removeLabelFormatting(field, token))) {
        return filters;
    }
    filters.push(equal(property, +removeLabelFormatting(field, token), "decimal"));
    return filters;
}

function addEnumFilters(token, property, field, filters, type) {
    let enums = getEnums();
    if (!Object.keys(enums).includes(type)) {
        return filters;
    }
    let fieldEnum = enums[type];
    let enumWithSpaces = enumService.enumLowerWithSpaces(fieldEnum);
    let lowerToken = removeLabelFormatting(field, token.toLowerCase());

    let matchingEnums = enumWithSpaces.filter((e) => e.includes(lowerToken));
    if (matchingEnums.length <= 0) {
        return filters;
    }

    for (let j = 0; j < matchingEnums.length; j++) {
        let index = enumWithSpaces.indexOf(matchingEnums[j]);
        filters.push(equal(property, index, "byte"));
    }
    return filters;
}

function addStringFilters(token, property, field, filters, type) {
    if (type !== "string") {
        return filters;
    }
    filters.push(buildContains(property, field, token));
    return filters;
}

function buildContains(property, field, token) {
    let filter = contains(property, token, "string");

    let cleanToken = removeLabelFormatting(field, token);
    if (cleanToken === token) {
        return filter;
    }
    let cleanFilter = contains(field.key, cleanToken, "string");
    return or([filter, cleanFilter]);
}

export default {
    buildSearchFilter,
};
