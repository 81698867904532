<template>
    <div>
        <schema-table
            ref="schemaTable"
            :entity-key="entityKey"
            v-bind="$attrs"
            v-on="$listeners"
            :parent-entity="parentEntity"
            :default-values="defaultValues"
            style="height: 100%"
        >
            <template #add-button="{ canAdd }">
                <app-button v-if="canAdd" class="ml-2" @click="add">Add {{ entity.singleTitle }}</app-button>
            </template>
        </schema-table>

        <magazine-item-wizard
            :key="wizardKey"
            :value="wizard"
            @close="wizard = false"
            @save="onSave"
        ></magazine-item-wizard>
    </div>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import magazineItemWizard from "@/wizards/magazineItemWizard/MagazineItemWizard.vue";

    export default {
        mixins: [schemaMixin],
        components: { magazineItemWizard },
        data() {
            return {
                wizard: false,
                wizardKey: 0,
            };
        },

        props: {
            parentEntity: {
                type: Object,
                default: null,
            },
        },

        methods: {
            add() {
                this.wizardKey++;
                this.wizard = true;
            },

            async onSave() {
                await this.$refs.schemaTable.onItemSaved();
                this.wizard = false;
            },
        },

        computed: {
            defaultValues() {
                if (this.parentEntity == null) {
                    return null;
                }

                // Hide Icon column if isShowIcon on the parent false. Don't filter out items that have an Icon though.
                if (this.parentEntity.key == "magazineItemCategory" && this.parentEntity.item.isShowIcon === false) {
                    return {
                        iconId: null,
                    };
                }

                return null;
            },
        },
    };
</script>
