import axios from "axios";

export const branding = {
    namespaced: true,
    state: {
        companyName: "",
        companyLogo: "",
    },
    actions: {
        async initialize({ commit }) {
            var result = await axios.get("/api/Branding");
            commit("brandingSuccess", result.data);
        },
        updateBranding({ commit }) {
            return axios.get("/api/Branding").then((response) => {
                commit("brandingSuccess", response.data);
            });
        },
    },
    mutations: {
        brandingSuccess(state, result) {
            state.companyName = result.companyName;
            state.companyLogo = result.companyLogo;
        },
    },
};
