<template>
    <div>
        <app-select
            v-model="field"
            :items="fields"
            return-object
            item-text="title"
            label="Field"
            class="mb-2"
            hide-details
        />

        <app-select v-model="comparison" label="Comparison" :items="comparisons" class="mb-2" hide-details />

        <component
            :is="getComponent(field)"
            v-if="field"
            v-model="comparisonValue"
            :field="field"
            label="Value"
            class="mb-2"
            hide-details
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import schemaFiltering from "@/features/schemas/services/schemaFiltering";
    import localValueMixin from "@/mixins/localValueMixin";
    import { getComponent } from "@/features/schemas/formFields";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";
    import { hasProperty } from "@/services/objectUtility";

    export default {
        mixins: [localValueMixin, schemaMixin],
        props: {
            value: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                field: null,
                comparison: null,
                comparisonValue: null,
            };
        },
        computed: {
            ...mapGetters({
                getEnumAsList: "schema/getEnumAsList",
            }),
            comparisons() {
                return this.getEnumAsList("comparison");
            },
            fields() {
                return Object.values(this.entity.queryFields).filter(
                    (e) =>
                        e.key !== "id" &&
                        // Hide fields that have the "hidden" property
                        !hasProperty(e, "hidden") &&
                        // Exclude any things which are fixed values.
                        (!this.fixedValues || !Object.keys(this.fixedValues).includes(e.key))
                );
            },
            queryFields() {
                const queryFields = this.entity.queryFields;
                const keys = Object.keys(queryFields);
                return keys.map((key) => queryFields[key]);
            },
        },
        watch: {
            field() {
                this.comparison = null;
                this.comparisonValue = null;
            },
            comparison() {
                this.updateValue();
            },
            comparisonValue() {
                this.updateValue();
            },
        },
        methods: {
            getComponent,
            updateValue() {
                const filterIsIncomplete =
                    this.field == null || this.comparison == null || isNullOrWhiteSpace(this.comparisonValue);

                if (filterIsIncomplete) {
                    this.localValue = null;
                    return;
                }
                const compare = schemaFiltering[this.comparison];
                this.localValue = compare(this.entityKey, this.field.key, this.comparisonValue);
            },
        },
    };
</script>
