<template>
    <v-autocomplete
        v-bind="$attrs"
        v-model="item"
        outlined
        dense
        item-value="id"
        item-text="label"
        :items="filteredLocationTypes"
        :cache-items="false"
        :menu-props="{ bottom: true, offsetY: true }"
        :disabled="disable"
    >
        <template v-slot:selection="{ item }">
            <div class="selected-item">
                <v-avatar size="24px" class="origin-label" :style="{ background: getOriginColor(item.type, 1) }">
                    {{ getOriginLabel(item.type, true) }}
                </v-avatar>
                {{ item.label }}
            </div>
        </template>
        <template v-slot:prepend-item>
            <div v-if="showFilter" class="sticky flex-wrap">
                <v-btn-toggle v-model="filter" multiple>
                    <v-btn
                        v-for="type of movementLocationType"
                        :key="type.type"
                        :style="{
                            background: filter.includes(type) ? getOriginColor(type, 1) : '',
                            color: filter.includes(type) ? 'white' : '',
                            fontSize: '0.7em',
                        }"
                    >
                        {{ getOriginLabel(type) }}
                    </v-btn>
                </v-btn-toggle>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="primary" icon style="align-self: center">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <span>Click to filter by type</span>
                </v-tooltip>
            </div>
        </template>
        <template v-slot:item="{ item }">
            {{ item.label }}
            <v-spacer />
            <origin-chip :type="item.type" />
        </template>
    </v-autocomplete>
</template>

<script>
    import { idLabels } from "@/features/schemas/services/schemaApi";
    import movementLocationType from "@/enums/movementLocationType";
    import { getOriginColor, getOriginLabel } from "../reportUtility";
    import OriginChip from "../OriginChip.vue";

    export default {
        components: {
            OriginChip,
        },
        props: {
            value: {
                type: String,
                default: () => null,
            },
            filterOverride: {
                type: Array,
                default: () => null,
            },
            showFilter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                movementLocationType,
                filter: [0, 1, 2, 3],
                locationTypes: [],
                localValue: null,
            };
        },
        async mounted() {
            const equipments = await this.getLocationType("equipment", movementLocationType.Equipment);
            const locations = await this.getLocationType("location", movementLocationType.Location);
            const magazineAreas = await this.getLocationType("magazineArea", movementLocationType.MagazineArea);
            const manufacturers = await this.getLocationType(
                "magazineItemManufacturer",
                movementLocationType.Manufacturer
            );

            this.locationTypes = [...equipments, ...locations, ...magazineAreas, ...manufacturers];
        },
        methods: {
            getOriginColor,
            getOriginLabel,
            async getLocationType(entityKey, movementLocationType) {
                const locationTypes = await idLabels(entityKey);

                return locationTypes.map((e) => ({
                    ...e,
                    type: movementLocationType,
                }));
            },
        },
        computed: {
            item: {
                get() {
                    if (!this.locationTypes.length) return null;
                    return this.locationTypes.find((e) => e.id === this.localValue);
                },
                set(value) {
                    this.localValue = value;
                    this.$emit("input", value);
                },
            },
            filteredLocationTypes() {
                return this.locationTypes.filter((e) => this.filter.includes(e.type));
            },
            disable() {
                const item = this.locationTypes.find((e) => e.id === this.value);
                return !this.showFilter && item?.type === movementLocationType.MagazineArea;
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (value) {
                        this.localValue = value;
                    } else {
                        this.localValue = null;
                    }
                },
            },
            item: {
                immediate: true,
                handler(value) {
                    //Override the filter if provided
                    //eg: an Inactive record causes the filter to be set to the incorrect source type
                    if (this.filterOverride) {
                        this.filter = JSON.parse(JSON.stringify(this.filterOverride));
                        return;
                    }

                    if (!this.showFilter && value) {
                        const index = this.locationTypes.findIndex((e) => e.id === this.value);

                        if (index != -1) {
                            const type = this.locationTypes[index].type;
                            this.filter = [type];
                        }
                    }
                },
            },
        },
    };
</script>

<style scoped>
    .sticky {
        position: sticky;
        top: -1px;
        background: white;
        z-index: 1;
        display: flex;
        margin: 0 auto;
        padding: 4px;
    }

    ::v-deep .selected-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ::v-deep .v-select__selections {
        display: flex;
        flex-wrap: nowrap;
    }

    .origin-label {
        color: white;
        margin-right: 8px;
        font-size: 0.8em;
    }
</style>
