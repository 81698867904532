<template>
    <v-dialog
        v-bind="$attrs"
        :value="value"
        :max-width="maxWidth"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :persistent="!notPersistent"
        v-on="$listeners"
    >
        <slot />
    </v-dialog>
</template>

<script>
    export default {
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            notPersistent: {
                type: Boolean,
                default: false,
            },
            maxWidth: {
                type: String,
                default: "500px",
            },
        },
    };
</script>
