import { pascalToCamelCase } from "@/services/stringUtility";
import schemaTable from "../components/SchemaTable";

const tableViewsByEntityKey = {};

// Grab all the *DataTable.vue files in this folder (exluding subfolders).
let requireComponent = require.context(".", false, /^.\/([\w-]+)TableView\.vue$/);

requireComponent.keys().forEach((filePath) => {
    const tableView = requireComponent(filePath);
    const entityKey = pascalToCamelCase(filePath.slice(2, -13));
    tableViewsByEntityKey[entityKey] = tableView.default;
});

export function getTableView(entityKey) {
    return tableViewsByEntityKey[entityKey] ?? schemaTable;
}

export default {
    getTableView,
};
