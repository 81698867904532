<template>
    <div>
        <v-autocomplete
            v-model="value.manufacturer"
            :items="manufacturers"
            outlined
            dense
            label="Manufacturer"
            @input="manufacturerOrTypeSelected()"
            :rules="[(v) => !!v || 'Required']"
        />

        <schema-search-select
            v-model="value.magazineItemTypeId"
            entity-key="magazineItemType"
            label="Item Type"
            search-when-blank
            :disabled="value.manufacturer == null"
            :rules="[(v) => !!v || 'Required']"
            @input="manufacturerOrTypeSelected()"
        />

        <v-autocomplete
            v-model="value.item"
            :items="items"
            outlined
            dense
            label="Item"
            item-text="name"
            return-object
            :disabled="disableItemSelection"
            @input="itemSelected()"
            :rules="[(v) => !!v || 'Required']"
        />

        <div class="text-caption pb-3 pt-1" :class="{ disabled: disableUnitSelection }">
            As LiveMine units are customisable, the Manufacturer unit must be mapped to an existing LiveMine unit.
            <br />
            <br />
            Magazine Items must have a Unit binded to them. This Unit is for displaying and stock management. Eg 10 bags
            of Anfo = 10 Ea.
        </div>
        <v-row>
            <v-col>
                <app-text-field v-model="unitName" disabled label="Manufacturer Unit" />
            </v-col>

            <v-col cols="1" align="center" align-self="center" style="margin-bottom: 24px">
                <v-icon>mdi-arrow-right-bold</v-icon>
            </v-col>

            <v-col>
                <schema-search-select
                    v-model="value.mappedUnitId"
                    entity-key="unit"
                    label="LiveMine Unit"
                    search-when-blank
                    :disabled="disableUnitInput"
                    :rules="[(v) => !!v || 'Required']"
                />
            </v-col>
        </v-row>
        <div class="text-caption pb-3 pt-1" :class="{ disabled: disableUnitSelection }">
            This is an optional Capacity Unit or Item Weight Unit for tracking item stock levels in terms of weight and
            for tracking magazine capacities. Eg the Capacity Unit is Kg. 10 bags of anfo = 250kg.
        </div>
        <v-row>
            <v-col>
                <app-text-field v-model="capacityUnitName" disabled label="Manufacturer Capacity Unit" />
            </v-col>

            <v-col cols="1" align="center" align-self="center" style="margin-bottom: 24px">
                <v-icon>mdi-arrow-right-bold</v-icon>
            </v-col>

            <v-col>
                <schema-search-select
                    v-model="value.mappedCapacityUnitId"
                    entity-key="unit"
                    label="LiveMine Unit"
                    search-when-blank
                    :disabled="disableCapUnitInput"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from "axios";
    import { get } from "@/features/schemas/services/schemaApi";

    export default {
        components: {},
        props: {
            value: Object,
            isCategory: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                manufacturers: [],
                items: [],
            };
        },

        async mounted() {
            const { data: manufacturers } = await axios.get(
                "/api/MagazineCatalog/Manufacturers?isCategory=" + this.isCategory
            );
            this.manufacturers = manufacturers;
        },

        methods: {
            async manufacturerOrTypeSelected() {
                if (this.value.magazineItemTypeId == null || this.value.manufacturer == null) {
                    return;
                }

                const magazineItemType = await get("magazineItemType", this.value.magazineItemTypeId);

                if (this.isCategory) {
                    this.items = await this.getCategories(this.value.manufacturer, magazineItemType.magazineType);
                    return;
                }

                this.items = await this.getItems(this.value.manufacturer, magazineItemType.magazineType);
            },

            async getCategories(manufacturer, type) {
                const params = {
                    manufacturer: manufacturer,
                    type: type,
                };

                const { data } = await axios.get(`/api/MagazineCatalog/Categories/`, { params });
                return data;
            },

            async getItems(manufacturer, type) {
                const params = {
                    manufacturer: manufacturer,
                    type: type,
                };

                const { data } = await axios.get(`/api/MagazineCatalog/Items/`, { params });
                return data;
            },

            async itemSelected() {
                const { data: unitData } = await axios.get(
                    `/api/MagazineCatalog/UnitExist/${this.value.item.unitName}`
                );
                if (unitData != null) {
                    this.value.mappedUnitId = unitData;
                }

                if (this.capacityUnitName) {
                    const { data: capacityUnitData } = await axios.get(
                        `/api/MagazineCatalog/UnitExist/${this.capacityUnitName}`
                    );
                    if (capacityUnitData != null) {
                        this.value.mappedCapacityUnitId = capacityUnitData;
                    }
                }
            },
        },

        computed: {
            disableItemSelection() {
                return this.value.magazineItemTypeId == null || this.value.manufacturer == null;
            },
            unitName() {
                if (this.value.item) return this.value.item.unitName;
                return null;
            },
            capacityUnitName() {
                if (this.value.item) return this.value.item.capacityUnitName;
                return null;
            },
            disableUnitSelection() {
                return this.value.item == null;
            },
            disableUnitInput() {
                if (this.value.item) return this.value.item.unitName == null;
                return true;
            },
            disableCapUnitInput() {
                if (this.value.item) return this.value.item.capacityUnitName == null;
                return true;
            },
        },
    };
</script>

<style scoped>
    .disabled {
        opacity: 38%;
    }
</style>
