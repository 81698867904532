import store from "@/store/store";
import { canList } from "./schemaApi";

export function getEntity(entityKey) {
    return store.getters["schema/getEntity"](entityKey);
}

export function getEnums() {
    return store.getters["schema/enums"];
}

export function getEnum(enumKey) {
    return store.getters["schema/getEnum"](enumKey);
}

export function getEndpoint(entityKey, endpointKey) {
    const entity = getEntity(entityKey);
    return entity.endpoints[endpointKey];
}

export function getQueryField(entityKey, fieldKey) {
    return store.getters["schema/getQueryField"](entityKey, fieldKey);
}

export function getDependants(entityKey) {
    const entities = store.getters["schema/entities"];

    const isMasterUser = store.getters["authentication/isMasterUser"];

    // get an array of { entity, field } for fields which depend on the given entityKey.
    // Should only include entities that the user has the claim to view.
    return Object.values(entities)
        .filter((entity) => canList(entity.key))
        .map((entity) =>
            Object.values(entity.queryFields)
                .filter((field) => field.dependsOn?.type === entityKey && !hideRelationship(isMasterUser, field))
                .map((field) => ({ entity, field }))
        )
        .flat();
}

/**
 * Don't include this field if it has the hideRelationship property. Only let master users view it.
 */
function hideRelationship(isMasterUser, field) {
    if (field.dependsOn?.hideRelationship == false || field.dependsOn?.hideRelationship == undefined) {
        return false;
    }

    if (isMasterUser) {
        return false;
    }

    return true;
}

export function getType(entityKey, fieldKey) {
    const entity = getEntity(entityKey);
    const field = entity.queryFields[fieldKey];
    return field.type;
}
