<template>
    <div>
        <schema-data-table
            ref="table"
            v-bind="$attrs"
            v-on="$listeners"
            sortBy="sortOrder"
            direction="descending"
        ></schema-data-table>
    </div>
</template>

<script>
    export default {
        methods: {
            async refresh() {
                await this.$refs.table.refresh();
            },
        },
    };
</script>
