/* Enums */
import userInterfaceEnum from "@/enums/userInterfaceEnum";
import operatorShiftTaskStatusEnum from "@/enums/operatorShiftTaskStatusEnum";
import { statusColors } from "@/shiftAllocation/statusColors.js";
import { getField } from "vuex-map-fields";
export default {
    getField,
    hasPublishedTasks(state) {
        let slots = state.slots;

        if (slots) {
            let tasks = Object.values(slots)
                .flat()
                .filter((slot) => slot.tasks)
                .map((slot) => slot.tasks);

            // Check if any task is published
            return tasks.some((task) => task.some((t) => t.status !== operatorShiftTaskStatusEnum.Draft));
        }

        return false;
    },
    moduleIds: (state) => {
        return Object.keys(state.modules);
    },
    form: (state) => (userInterface) => {
        return state.schema[userInterface];
    },
    getAssignedOperators: (state) => (moduleId) => {
        if (!state.slots || !moduleId || !state.slots[moduleId]) return [];

        return state.slots[moduleId].filter((o) => o.operator).map((o) => o.operator.id);
    },
    getStatusColor: () => (status) => {
        return statusColors[status];
    },
    selectedUserInterface: (state) => {
        let selectedInterface = null;

        if (state.selectedModule) {
            selectedInterface = state.modules[state.selectedModule].userInterface;
        }

        return selectedInterface;
    },
    selectedSlots(state) {
        return state.slots[state.selectedModule];
    },
    isServiceCrew: (state, getters) => {
        return getters.selectedUserInterface == userInterfaceEnum.ServiceCrew;
    },
    isChargeUp: (state, getters) => {
        return getters.selectedUserInterface == userInterfaceEnum.Charge;
    },
    isDevelopmentDrill: (state, getters) => {
        return getters.selectedUserInterface == userInterfaceEnum.DevelopmentDrill;
    },
    getLocationName: (state) => (id) => {
        if (!state.selectedModule) {
            return null;
        }
        let location = state.locations.find((location) => location.id == id);

        if (!location) {
            return null;
        } else {
            return location.name;
        }
    },
    getActivityName: (state) => (id) => {
        if (!state.selectedModule || state.activities[state.selectedModule].length == 0) {
            return null;
        }
        let activity = state.activities[state.selectedModule].find((x) => x.id == id);

        if (!activity) {
            return null;
        } else {
            return activity.name;
        }
    },
    getEquipmentName: (state) => (id) => {
        if (!state.selectedModule) {
            return null;
        }

        let equipment = state.equipments[state.selectedModule].find((x) => x.id == id);

        if (!equipment) {
            return null;
        } else {
            return equipment.assetNumber;
        }
    },
    getProductionDrillPlanName: (state) => (id) => {
        if (!state.selectedModule) {
            return;
        }

        let productionDrillPlan = state.productionDrillPlans.find((x) => x.id == id);

        if (!productionDrillPlan) {
            return null;
        } else {
            return productionDrillPlan.name;
        }
    },
    // * Filter activity by module id, with a optional search parameter
    filteredActivities: (state) => (search) => {
        if (search && search.length > 0) {
            return state.activities[state.selectedModule].filter((x) =>
                x.name.toLowerCase().includes(search.toLowerCase())
            );
        }

        return state.activities[state.selectedModule];
    },
    // * Filter plan with a optional search parameter
    filteredPlans: (state) => (search) => {
        if (search && search.length > 0) {
            return state.productionDrillPlans.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
        }
        return state.productionDrillPlans;
    },

    // * Filter location with a optional search parameter
    filteredLocations: (state) => (search) => {
        if (search && search.length > 0) {
            return state.locations.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
        }
        return state.locations;
    },
    allOperators: (state) => {
        return state.operators.filter((o) => o.moduleIds.includes(state.selectedModule));
    },
    filteredOperators: (state, getters) => {
        //get any already assigned operators
        let assignedOperators = getters.getAssignedOperators(state.selectedModule);

        //remove assigned operators
        let filteredOperators = state.operators.filter(
            (o) => !assignedOperators.includes(o.id) && o.moduleIds.includes(state.selectedModule)
        );

        return filteredOperators;
    },
    crews: (state) => {
        return state.crews.sort((a, b) => a.name.localeCompare(b.name));
    },
};
