<template>
    <app-text-area
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        :counter="getMaxLength"
        v-on="$listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";

    export default {
        types: [],
        mixins: [formFieldMixin],
    };
</script>
