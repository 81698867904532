<template>
    <div ref="wrap" class="app-data-table-wrap">
        <v-data-table
            class="app-data-table"
            v-model="localValue"
            :options.sync="dataTableOptions"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { debounce } from "@/services/debounce";
    import { buildListRequest } from "@/services/listRequestBuilder";

    export default {
        props: {
            value: Array,
        },
        data() {
            return {
                dataTableOptions: null,
                boundedResize: null,
            };
        },
        computed: {
            wrapElement() {
                return this.$refs["wrap"];
            },
            localValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
        },
        watch: {
            dataTableOptions: {
                immediate: true,
                handler(value) {
                    this.$emit("update:listOptions", buildListRequest(value));
                },
            },
        },
        mounted() {
            // Set the items per page to 0 until we've had a chance to calculate them.
            this.dataTableOptions.itemsPerPage = 0;

            // debounce the onResize handler so that quick layout updates don't fire multiple requests.
            const onResize = debounce(this.onResize, 50);

            this.$nextTick(() => {
                this.resizeObserver = new ResizeObserver(onResize);
                this.resizeObserver.observe(this.wrapElement);
                onResize();
            });
        },
        destroyed() {
            this.resizeObserver.disconnect();
        },
        methods: {
            valuesSelected(values) {
                this.$emit("selected-values", values);
            },
            onResize() {
                // The default mobile breakpoint for v-data-table is 600px;
                // We don't want the page sizing to apply when we are less than this.
                if (window.innerWidth < 600) {
                    this.dataTableOptions.itemsPerPage = 10;
                    return;
                }
                const height = this.wrapElement.clientHeight;
                // Rows are fixed height so we can calculate the items per page and avoid scroll bars.
                // Header is 48 high, rows are 48 high, footer is 37 high.
                // Dense prop on v-data-table changes row height to 32.
                const itemsPerPage = Math.floor((height - 48 - 37) / 48);
                this.dataTableOptions.itemsPerPage = itemsPerPage;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 600px) {
        .app-data-table-wrap {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            padding: 0;
        }

        .app-data-table {
            height: 100%;
            width: 100%;
            position: relative;
        }

        .app-data-table::v-deep .v-data-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
        }

        .app-data-table::v-deep .v-data-footer__select {
            display: none;
        }

        .app-data-table::v-deep .v-data-footer__pagination {
            margin-left: auto;
        }

        .app-data-table::v-deep td {
            // stop overriding style of v-checkbox.
            > *:not(.no-overflow-ellipsis):not(.v-data-table__checkbox) {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .app-data-table::v-deep th {
            white-space: nowrap;
        }

        // Fixes icons causing overflown ellipsis to show unnecessarily.
        .app-data-table::v-deep .v-icon.v-icon::after {
            content: none;
        }
    }
</style>
