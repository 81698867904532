<template>
    <span>
        <v-icon @click="edit">mdi-pencil</v-icon>
        <schema-form-dialog :entity-key="entityKey" :value="item" @input="onSave" />
    </span>
</template>

<script>
    import { get } from "@/features/schemas/services/schemaApi";
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";

    export default {
        mixins: [schemaMixin],
        props: ["value"],
        data() {
            return {
                item: null,
            };
        },
        methods: {
            async edit() {
                this.item = await get(this.entityKey, this.value);
            },
            onSave(item) {
                this.item = null;
                this.$emit("input", item);
            },
        },
    };
</script>
