<!-- Temporary Custom Search Select for Users so we don't have the Add ability. 
   TODO Update UserForm and backend logic for adding Users and roles and password functionality.  -->
<template>
    <schema-search-select
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
        entity-key="user"
        label="User"
        search-when-blank
        :rules="rules"
        :clearable="!required"
    ></schema-search-select>
</template>

<script>
    import formFieldMixin from "./formFieldMixin";

    export default {
        types: ["user"],
        mixins: [formFieldMixin],
        props: {
            value: {
                type: [String, Array],
                default: null,
            },
        },
    };
</script>
