export const modules = {
    namespaced: true,
    state: {
        data: [],
        name: null,
    },
    getters: {
        getModules: (state) => state.data,
        getModuleName: (state) => state.name,
        getModulesSelectList(state) {
            return state.data.map((m) => ({
                text: m.name,
                value: m.id,
            }));
        },
    },
    mutations: {
        setModules(state, payload) {
            state.data = payload;
        },
        setModule(state, payload) {
            let index = state.data.findIndex((x) => x.id == payload.id);
            if (index > -1) {
                state.data[index] = payload;
            }
        },
        setModuleName(state, message) {
            state.name = message;
        },
    },
};
