<template>
    <div v-if="field && field.dependsOn && field.dependsOn.addOnly" class="d-flex justify-center">
        <schema-entity-add
            style="width: 100%"
            v-bind="$attrs"
            :value="value"
            :label="field.title"
            :rules="rules"
            :entity-key="field.dependsOn.type"
            :clearable="!required"
            search-when-blank
            v-on="$listeners"
        />
    </div>
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import SchemaEntityAdd from "@/features/schemas/components/SchemaEntityAdd.vue";

    export default {
        types: ["addonlyentity"],
        mixins: [formFieldMixin],
        components: { SchemaEntityAdd },
        props: {
            value: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                editedItem: null,
            };
        },
    };
</script>
