<template>
    <v-chip color="primary" class="text-uppercase">{{ value }}</v-chip>
</template>

<script>
    export default {
        types: ["chip"],
        props: {
            value: {
                type: [String],
                default: null,
            },
        },
    };
</script>
