import axios from "axios";
//import errorUtility from '../plugins/errorUtility';

export default {
    loadLicense,
};

// Poll for the license information
async function loadLicense() {
    return await axios.get("/api/License/Info");
}
