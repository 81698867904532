<template>
    <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn :color="selectedOptions.length > 0 ? 'red' : 'gray'" small icon v-bind="attrs" v-on="on">
                <v-icon size="20px">mdi-filter-variant</v-icon>
            </v-btn>
        </template>
        <div class="schema-column-filter">
            <div class="d-flex flex-nowrap">
                <v-text-field
                    class="flex-grow-1"
                    clearable
                    v-model="searchText"
                    dense
                    hide-details
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                />
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            class="clear flex-shrink-1"
                            outlined
                            color="red"
                            :disabled="selectedOptions.length === 0"
                            @click="clear"
                        >
                            <v-icon size="24px">mdi-filter-variant-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>Clear Filters</span>
                </v-tooltip>
            </div>
            <v-list class="filter-list">
                <v-list-item v-for="option in filteredOptions" :key="option.id" @click="filter(option.id)">
                    <v-list-item-action>
                        <v-checkbox color="primary" v-model="option.isSelected" />
                    </v-list-item-action>
                    <v-list-item-title>{{ option.label }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="filteredOptions.length == 0" dense>
                    <v-list-item-title>No results found</v-list-item-title>
                </v-list-item>
            </v-list>
        </div>
    </v-menu>
</template>

<script>
    export default {
        emits: ["input"],
        props: {
            options: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                searchText: "",
                selectedOptions: [],
            };
        },
        methods: {
            filter(value) {
                const index = this.selectedOptions.indexOf(value);
                if (index > -1) {
                    this.selectedOptions.splice(index, 1);
                } else {
                    this.selectedOptions.push(value);
                }

                this.$emit("input", this.selectedOptions);
            },
            clear() {
                this.selectedOptions = [];
                this.$emit("input", this.selectedOptions);
            },
        },
        computed: {
            filteredOptions() {
                if (!this.searchText || this.searchText.trim().length === 0) {
                    return this.options;
                }
                const lowerCaseSearchText = this.searchText.trim().toLowerCase();
                return this.options.filter((option) => option.label.toLowerCase().includes(lowerCaseSearchText));
            },
        },
    };
</script>

<style>
    .schema-column-filter {
        width: 300px;
        background: white;
        z-index: 10;
        padding: 8px;
        overflow: hidden;
    }

    .filter-list {
        max-height: 300px;
        background: white;
        overflow: auto;
    }

    .clear {
        height: 40px !important;
        width: 24px !important;
        margin-left: 4px;
    }
</style>
