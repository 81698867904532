const baseUrl = "https://liveminehelp.zendesk.com/hc/en-us";

export const help = {
    namespaced: true,
    state: {
        helpLinks: {
            generic: baseUrl,
            webMainSupportPage: baseUrl + "/sections/360009305911-Webapp-Help-Files-and-Training",
            createZendeskAccount: baseUrl + "/restricted?return_to=https%3A%2F%2Fliveminehelp.zendesk.com%2Fhc%2Fen-us",
            shiftReview: baseUrl + "/articles/360054875271-Shift-Review",
            operators: baseUrl + "/articles/360044648912-User-Management-Operators-Tablets",
            users:
                baseUrl + "/articles/360045143191-User-Management-Administrators-Desktop#h_01FFH87J3BS2BMFJ05KFW182XR",
            roles:
                baseUrl + "/articles/360045143191-User-Management-Administrators-Desktop#h_01FFH8CQ2V7BW31JE73E0CZ8JC",
            activities: baseUrl + "/articles/360044698232-Mine-Management-Activities",
            shifts: baseUrl + "/articles/360044700112-Mine-Management-Shifts",
            products: baseUrl + "/articles/360045145531-Mine-Management-Products",
            units: baseUrl + "/articles/360044701512-Mine-Management-Unit-of-Measure",
            timeUsageCategories: baseUrl + "/articles/4405507134221-Time-Management-Time-Usage-Categories",
            timeUsageActivities: baseUrl + "/articles/4405591363853-Time-Management-Time-Usage-Activities",
            locationTypes: baseUrl + "/articles/360044746512-Location-Management-Location-Categories",
            locations: baseUrl + "/articles/360044747052-Location-Management-Locations",
            makes: baseUrl + "/articles/360045191211-Equipment-Management-Equipment-Makes",
            models: baseUrl + "/articles/360044749512-Equipment-Management-Equipment-Models",
            equipments: baseUrl + "/articles/360045195011-Equipment-Management-Equipment",
            equipmentStatuses: baseUrl + "/articles/360045268851-Equipment-Management-Statuses",
            prestart: baseUrl + "/articles/360044932612-Equipment-Management-Prestart-Checklists",
            meters: baseUrl + "/articles/360044993452-Equipment-Management-Meters",
            inventory: baseUrl + "/articles/360045150312-Inventory-Management-Inventory",
            inventoryCategories: baseUrl + "/articles/360045149012-Inventory-Management-Inventory-Categories",
            // Development Drilling
            developmentDrilling: baseUrl + "/articles/360045638671-Module-Management-Development-Drilling",
            developmentDrillActivities:
                baseUrl + "/articles/360045638671-Module-Management-Development-Drilling#h_01FFH52J4P4SEWVP76DZDYP2HC",
            developmentDrillCutLengths:
                baseUrl + "/articles/360045638671-Module-Management-Development-Drilling#h_01FFH541AA50EQ9V9G5XPW38XY",
            developmentDrillTypes:
                baseUrl + "/articles/360045638671-Module-Management-Development-Drilling#h_01FFH54NQEGYN8CMFQ08HG3KFH",
            developmentDrillStandards:
                baseUrl + "/articles/360045638671-Module-Management-Development-Drilling#h_01FFH54ZCG9YG7YJJ9KW2D3PA2",
            // Production Drilling
            productionDrilling: baseUrl + "/sections/360009305911-Webapp-Help-Files-and-Training",
            productionDrillActivities:
                baseUrl + "/articles/360052577492-Module-Management-Production-Drilling#h_01FFH7DPYC6CCJG9HQR0JARQEH",
            productionDrillPlans:
                baseUrl + "/articles/360052577492-Module-Management-Production-Drilling#h_01FFH7DYX4KZ73R5CZG3QRZ7YP",
        },
    },
    mutations: {
        helpLinks(state, links) {
            state.helpLinks = links;
        },
    },
};
