<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
    import { format } from "@/formatting/dateTimeOffsetFormatter";

    export default {
        types: ["datetimeoffset"],
        props: {
            value: {
                type: [String],
                default: null,
            },
        },
        computed: {
            display() {
                return format(this.value);
            },
        },
    };
</script>
