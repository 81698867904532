<template>
    <div>
        {{ dateText }}
    </div>
</template>

<script>
    import { format } from "@/formatting/dateTimeFormatter";

    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
        },
        computed: {
            dateText() {
                return format(this.value);
            },
        },
    };
</script>
