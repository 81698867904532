<template>
    <v-textarea v-bind="$attrs" :value="value" outlined dense v-on="$listeners" />
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
        },
    };
</script>
