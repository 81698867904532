<template>
    <div>
        <app-text-field
            v-bind="$attrs"
            :value="display"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            :required="false"
            :autofocus="false"
            :validate-on-blur="false"
            :append-icon="showClearIcon ? 'mdi-close' : ''"
            readonly
            :rules="rules"
            @click="add()"
            @click:append="clearSelection()"
        />

        <schema-form-dialog
            v-bind="$attrs"
            :entity-key="entityKey"
            :value="editedItem"
            @input="onItemSaved"
            disableDelete
        />
    </div>
</template>

<script>
    import { get } from "@/features/schemas/services/schemaApi";
    import { getLabel } from "@/features/schemas/services/labeller";

    export default {
        props: {
            entityKey: {
                type: String,
                default: null,
                required: true,
            },
            value: {
                type: String,
                default: null,
            },
            label: {
                type: String,
                default: () => "Search",
            },
            filter: {
                type: Object,
                default: null,
            },
            searchOptions: {
                type: Object,
                default: null,
            },
            searchWhenBlank: {
                type: Boolean,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedItem: null,
                editedItem: null,
            };
        },
        computed: {
            /**
             * Don't trigger rules when dialog opens.
             */
            rules() {
                if (this.editedItem != null) {
                    return [];
                }
                return this.$attrs.rules;
            },
            display() {
                if (this.selectedItem == null) {
                    return "";
                }

                return this.selectedItem.label;
            },
            showClearIcon() {
                return this.clearable && this.selectedItem != null;
            },
        },
        watch: {
            selectedItem(selectedItem) {
                if (this.value !== selectedItem?.id) {
                    this.$emit("input", selectedItem?.id);
                }
            },
            value: {
                immediate: true,
                async handler(value) {
                    if (!value) {
                        this.selectedItem = null;
                        return;
                    }

                    if (this.selectedItem?.id !== value) {
                        await this.updateSelectedItem(value);
                    }
                },
            },
        },
        methods: {
            clearSelection() {
                this.selectedItem = null;
            },

            add() {
                if (this.selectedItem != null) {
                    this.editedItem = this.selectedItem;
                } else {
                    this.editedItem = {};
                }
            },

            async updateSelectedItem(value) {
                let item = await get(this.entityKey, value);
                item.label = getLabel(this.entityKey, item);
                this.selectedItem = item;
            },

            async onItemSaved(item) {
                if (item != null) {
                    this.$emit("input", item.id);

                    // Updated existing value. Need to refresh label.
                    if (this.selectedItem?.id === item.id) {
                        await this.updateSelectedItem(item.id);
                    }
                }

                this.editedItem = null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep .v-input__slot {
        cursor: pointer !important;
    }

    ::v-deep .v-text-field__slot > input {
        cursor: pointer !important;
    }
</style>
