import { getEntity } from "./schemaProvider";
import { getTableData } from "./tableService";
import { getLabelFields, getLabel } from "./labeller";
import { buildSearchFilter } from "./searchFilterBuilder";
import { and } from "./schemaFiltering";
import { hasProperty } from "@/services/objectUtility";

export async function search(entityKey, searchText, options) {
    let fields = [];
    // All Fields for when you want to search on all fields (i.e a table search)
    // False for when you want to just search by the label fields (Schema Search Component)
    if (options?.allFields) {
        const queryFields = getEntity(entityKey).queryFields;
        // Filter out fields that are duplicate foreign key fields. E.g operatorShift and operatorShiftId.
        fields = Object.values(queryFields).filter((field) => !hasProperty(queryFields, `${field.key}Id`));
    } else {
        fields = getLabelFields(entityKey);
    }

    if (!fields.length) {
        throw `Unable to search ${entityKey} as there are no suitable fields available.`;
    }
    let filter = buildSearchFilter(fields, searchText);
    if (options?.filter != null) {
        filter = and([filter, options.filter]);
    }
    let model = {
        filter,
        sortBy: fields[0].key,
        take: options?.take ?? 30,
        skip: options?.skip,
        recordState: options?.recordState,
    };

    if (options?.sortBy != null) {
        model.sortBy = options.sortBy;
        model.direction = options.direction;
    }

    let result = await getTableData(entityKey, model, options?.includeLookups);

    if (options?.includeLabels) {
        result.items.forEach((item) => (item.label = getLabel(entityKey, item)));

        result.headers.push({
            value: "label",
            text: getEntity(entityKey).singleTitle,
        });
    }

    return result;
}

export default {
    search,
};
