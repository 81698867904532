<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
        <template #field-fromRecordId="{ field, item }">
            <div v-if="recordCacheFrom" class="px-2 pb-2 text-title primary--text">
                {{ recordCacheFrom.warningMessage }}
            </div>
            <form-location-type-select-field
                v-if="!item.fromMagazineArea?.isInactive"
                v-model="item.fromRecordId"
                :placeholder="recordCacheFrom?.name"
                :filter-override="recordCacheFrom?.filter"
                persistent-placeholder
                label="From"
                :rules="buildRules(field)"
            />
            <app-text-field v-else label="From" :value="item.fromMagazineArea.name" disabled />
        </template>
        <template #field-toRecordId="{ field, item }">
            <div v-if="recordCacheTo" class="px-2 pb-2 text-title primary--text">
                {{ recordCacheTo.warningMessage }}
            </div>
            <form-location-type-select-field
                v-if="!item.toMagazineArea?.isInactive"
                v-model="item.toRecordId"
                :placeholder="recordCacheTo?.name"
                label="To"
                :filter-override="recordCacheTo?.filter"
                :rules="buildRules(field)"
            />
            <app-text-field v-else label="To" :value="item.toMagazineArea.name" disabled />
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import { buildRules } from "@/services/ruleBuilder";
    import FormLocationTypeSelectField from "@/features/explosives/formFields/FormLocationTypeSelectField.vue";
    import movementLocationTypeEnum from "@/enums/movementLocationType";

    export default {
        components: { FormLocationTypeSelectField },
        data() {
            return {
                recordCacheFrom: {},
                recordCacheTo: {},
            };
        },
        methods: {
            buildRules,

            getRecordInformation(item, source) {
                if (!item) return null;

                const fields = Object.keys(movementLocationTypeEnum);

                for (const field of fields) {
                    const itemField = item[`${source}${field}`];

                    if (!itemField?.isInactive) continue;

                    return {
                        name: itemField.name,
                        filter: [movementLocationTypeEnum[field]],
                        warningMessage: `The provided ${source} record '${itemField.name}' has been deactivated`,
                    };
                }
            },
        },
        watch: {
            "$attrs.value": {
                immediate: true,
                deep: true,
                handler(item) {
                    this.recordCacheFrom = this.getRecordInformation(item, "from");
                    this.recordCacheTo = this.getRecordInformation(item, "to");
                },
            },
        },
    };
</script>
