<template>
    <schema-form v-bind="$attrs" v-on="$listeners" v-bind:value="value" :fixed-values="fixedValues">
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import dateUtility from "@/services/dateUtility";

    export default {
        props: {
            parentEntity: {
                type: Object,
                default: null,
            },
            value: {
                type: Object,
                default: null,
            },
        },
        computed: {
            fixedValues() {
                return {
                    // Hide the recordedAt field, but set it to now since it is required.
                    recordedAt: this.value?.recordedAt ?? dateUtility.now(),
                    feedbackItemId: this.parentEntity.item.id,
                    acknowledged: this.parentEntity.item.acknowledged,
                };
            },
        },
    };
</script>
