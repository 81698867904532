<template>
    <div>
        <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                <slot :name="slot" v-bind="scope" />
            </template>

            <template #deleteButton="{ deleteItem }">
                <confirmation-dialog
                    irreversible
                    :message="`This inspection category and Inspection Items under this Inspection Category will be deleted as well. Do you want to proceed?`"
                    @confirm="deleteItem(false)"
                >
                    <template v-slot:activator="{ open }">
                        <app-button color="secondary" text @click.left.exact.stop="open">Deactivate</app-button>
                    </template>
                </confirmation-dialog>
            </template>
        </schema-form>
    </div>
</template>

<script>
    import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

    export default {
        components: {
            ConfirmationDialog,
        },
        methods: {
            async save() {
                return await this.$refs.form.save();
            },
        },
    };
</script>
