<template>
    <app-select
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        :items="enumValues"
        :multiple="field.isMultiple"
        v-on="$listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import { mapGetters } from "vuex";
    import { camelToTitleCase } from "@/services/stringUtility";

    // TODO: move this to the enum service once the generated code has been removed.
    function enumAsSelectList(enumDictionary) {
        return Object.keys(enumDictionary).map((k) => {
            return {
                value: k,
                text: camelToTitleCase(k),
            };
        });
    }

    export default {
        types: ["enum"],
        mixins: [formFieldMixin],
        computed: {
            ...mapGetters({
                getEnum: "schema/getEnum",
            }),
            enumValues() {
                const value = this.getEnum(this.field.type);
                return enumAsSelectList(value);
            },
        },
    };
</script>
