import { FieldDependencyProvider } from "@/features/schemas/services/fieldDependencyProvider";

// Grab all the vue files in this folder (exluding subfolders).
const context = require.context(".", false, /.*\.vue$/);
const provider = new FieldDependencyProvider(context);

export function getComponent(schemaField) {
    return provider.getDependency(schemaField);
}

export default {
    getComponent,
};
