<template>
    <div>
        <schema-data-table ref="table" v-bind="$attrs" v-on="$listeners" sortBy="sortOrder" direction="descending">
            <template #deleteManyButton="{ deleteMany }">
                <confirmation-dialog
                    irreversible
                    :message="`These inspection categories and all Inspection Items under them will be deleted as well. Do you want to proceed?`"
                    @confirm="deleteMany(false)"
                >
                    <template v-slot:activator="{ open }">
                        <v-icon class="float-right" @click.left.exact.stop="open">mdi-delete</v-icon>
                    </template>
                </confirmation-dialog>
            </template>
        </schema-data-table>
    </div>
</template>

<script>
    import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

    export default {
        components: { ConfirmationDialog },
        methods: {
            async refresh() {
                await this.$refs.table.refresh();
            },
        },
    };
</script>
