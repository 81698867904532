<template>
    <schema-form v-bind="$attrs" v-on="$listeners">
        <template #append="{ item, fixedValues }">
            <div v-if="iconImage(item, fixedValues)" class="d-flex justify-center">
                <exm-icon
                    :icon="formatIconName(iconImage(item, fixedValues))"
                    :primary-color="item.primaryColor"
                    :secondary-color="item.secondaryColor"
                    :background-color="item.backgroundColor"
                    width="200px"
                    height="200px"
                />
            </div>
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import { camelToPascalCase } from "@/services/stringUtility";
    import ExmIcon from "@/components/ExmIcon";

    export default {
        components: {
            ExmIcon,
        },
        methods: {
            iconImage(item, fixedValues) {
                // Fixed Values takes precedence.
                // E.g when editing an already saved Icon, need fixed values to override.
                if (fixedValues?.iconImage != null) {
                    return fixedValues.iconImage;
                }

                if (item?.iconImage != null) {
                    return item.iconImage;
                }

                return null;
            },
            formatIconName(iconName) {
                return camelToPascalCase(iconName);
            },
        },
    };
</script>
