import Vue from "vue";
import securityService from "../services/securityService";

export default Vue.mixin({
    methods: {
        hasClaim(name, requireswrite) {
            return securityService.hasClaim(name, requireswrite);
        },
        canRead(claim) {
            return securityService.hasClaim(claim);
        },
        canEdit(claim) {
            return securityService.hasClaim(claim, true);
        },
    },
});
