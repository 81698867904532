import { DateTime } from "luxon";

export function toIso(time) {
    return parseTime(time)?.toISOTime({ includeOffset: false });
}

const formats = ["HH:mm", "H:mm", "HHmm", "Hmm", "hh:mm a", "h:mm a", "hh:mma", "h:mma", "hhmma", "hmma", "ha", "H"];

export function parseTime(input) {
    if (input === null) {
        return null;
    }
    let dateTime;
    let isValid;
    let i = 0;
    do {
        dateTime = DateTime.fromFormat(input, formats[i]);
        isValid = dateTime.isValid;
    } while (!isValid && ++i < formats.length);

    if (!isValid) {
        return null;
    }

    return dateTime;
}

export function canParseTime(input) {
    if (input === null) {
        return true;
    }
    let dateTime = parseTime(input);
    return dateTime !== null;
}
