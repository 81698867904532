<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
                <v-form ref="itemSelectForm" @submit.prevent>
                    <v-card-title>Select an item to add</v-card-title>
                    <v-card-text>
                        <v-select
                            v-model="selectedItem"
                            :items="selectableItems"
                            label="Magazine Items"
                            :rules="rules"
                            required
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="primary" text @click="close()">Close</v-btn>
                        <v-btn class="primary" :disabled="!selectedItem" text @click="addItemOrder(selectedItem)">
                            Add Item
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            dialog: {
                type: Boolean,
                default: false,
            },
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                selectedItem: null,
                rules: [(v) => !!v || "Required"],
            };
        },
        methods: {
            addItemOrder(item) {
                this.$emit("addItemOrder", item);
                this.selectedItem = "";
                this.$refs.itemSelectForm.reset();
            },
            close() {
                this.selectedItem = "";
                this.$refs.itemSelectForm.reset();

                this.$emit("close");
            },
            getItemText(item) {
                const categoryName = item.magazineItemCategory?.name;
                return `${item.name} ${categoryName ? ` - ${categoryName}` : ""}`;
            },
            sortItems(items) {
                return items
                    .sort((a, b) => {
                        const magazineItemTypeA = a.magazineItemType;
                        const magazineItemTypeB = b.magazineItemType;

                        const magazineItemCategoryA = a.magazineItemCategory;
                        const magazineItemCategoryB = b.magazineItemCategory;

                        // Compare by magazineItemType
                        if (magazineItemTypeA.priority !== magazineItemTypeB.priority) {
                            return magazineItemTypeA.priority - magazineItemTypeB.priority;
                        }

                        if (magazineItemCategoryA?.priority !== magazineItemCategoryB?.priority) {
                            return (magazineItemCategoryA?.priority ?? 0) - (magazineItemCategoryB?.priority ?? 0);
                        }

                        // If magazineItemCategories are also equal, finally compare by magazineItem
                        return (a.priority ?? 0) - (b.priority ?? 0);
                    })
                    .reverse();
            },
        },
        computed: {
            selectableItems() {
                if (!this.items) {
                    return [];
                }

                const sortedItems = this.sortItems(this.items);
                return sortedItems.map((item) => ({
                    text: this.getItemText(item),
                    value: item,
                }));
            },
        },
    };
</script>
