import movementLocationType from "@/enums/movementLocationType";
import { format } from "@/formatting/decimalFormatter";

export function commandHeader(value, divider = true) {
    return {
        text: "",
        value,
        divider,
        cellClass: "command-cell",
        sortable: false,
    };
}

export function formatQuantity(value, stringFormat) {
    if (value == null) {
        return null;
    }

    var quantity = format(value);

    if (!stringFormat) {
        return quantity;
    }
    return stringFormat.replace("0", quantity);
}

export function formatDeviation(value) {
    if (value === null || value == 0) {
        return null;
    }

    return value > 0 ? `+${value}` : value;
}

export function sortByHeader(header, items) {
    if (!header.sortable) return;

    const sortOrder = header.sortOrder === 1 ? -1 : 1;
    header.sortOrder = sortOrder;

    // Sort items at the top level
    items.sort((a, b) => {
        return compare(a[header.value], b[header.value], sortOrder);
    });

    // Then sort category sub-items
    for (const { isCategory, items: subItems } of items) {
        if (!isCategory) continue;

        subItems.sort((a, b) => {
            return compare(a[header.value], b[header.value], sortOrder);
        });
    }
}

function compare(a, b, sortOrder) {
    if (typeof b === "string" && typeof a === "string") {
        return b.localeCompare(a) * sortOrder;
    } else if (typeof b === "number" && typeof a === "number") {
        return (b - a) * sortOrder;
    }
    return 0;
}

export function expand(item) {
    if (!item.isCategory) return;
    item.expand = !item.expand;
}

export function getOriginLabel(origin, letter = false) {
    switch (origin) {
        case movementLocationType.Location:
            return letter ? "L" : "Location";
        case movementLocationType.MagazineArea:
            return letter ? "M" : "Magazine";
        case movementLocationType.Equipment:
            return letter ? "E" : "Equipment";
        case movementLocationType.Manufacturer:
            return letter ? "MF" : "Manufacturer";
    }
}
export function getOriginColor(origin, alpha) {
    switch (origin) {
        case movementLocationType.Location:
            return `rgba(158, 59, 193, ${alpha})`;
        case movementLocationType.MagazineArea:
            return `rgba(59, 193, 80, ${alpha})`;
        case movementLocationType.Equipment:
            return `rgba(59, 129, 193, ${alpha})`;
        case movementLocationType.Manufacturer:
            return `rgba(193, 59, 59, ${alpha})`;
    }
}
