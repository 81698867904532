<template>
    <v-chip
        v-bind="$attrs"
        class="origin-chip"
        text-color="white"
        label
        :style="{ background: getOriginColor(type, 0.8) }"
    >
        {{ getOriginLabel(type) }}
    </v-chip>
</template>

<script>
    import { getOriginColor, getOriginLabel } from "./reportUtility";
    export default {
        props: {
            type: {
                type: Number,
                default: null,
            },
        },
        methods: {
            getOriginColor,
            getOriginLabel,
        },
    };
</script>

<style scoped>
    .origin-chip {
        max-width: 100px;
        min-width: 100px;
        padding: 0 4px;
    }

    :deep(.v-chip__content) {
        margin: 0 auto;
    }
</style>
