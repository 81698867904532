import { FieldDependencyProvider } from "@/features/schemas/services/fieldDependencyProvider";

// Grab all the *Formatter.js files in this folder (exluding subfolders).
const context = require.context(".", false, /.*Formatter\.js$/);
const provider = new FieldDependencyProvider(context, false);

export function getFormatter(schemaField) {
    const service = provider.getDependency(schemaField);
    return service?.format;
}

export default {
    getFormatter,
};
