import Vue from "vue";
import { VDataTable } from "vuetify/lib";

export default Vue.component("v-data-table-custom", {
    extends: VDataTable,
    props: {
        footerProps: {
            type: Object,
            default: function () {
                return { itemsPerPageOptions: [50, 100, 150, -1], showFirstLastPage: true };
            },
        },
    },
});
