<template>
    <schema-column-filter :options="entityOptions" @input="filter" />
</template>

<script>
    import { idLabels } from "@/features/schemas/services/schemaApi";
    import SchemaColumnFilter from "@/features/schemas/components/SchemaColumnFilter.vue";

    export default {
        types: ["entity"],
        components: {
            SchemaColumnFilter,
        },
        props: {
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                entityOptions: [],
            };
        },
        async mounted() {
            if (!this.options?.entityKey) {
                return;
            }

            const entities = await idLabels(this.options.entityKey);
            this.entityOptions = entities.map((entity) => ({
                ...entity,
                isSelected: false,
            }));
        },
        methods: {
            filter(values) {
                // Set isSelected to true for each entity that is selected
                this.entityOptions.map((entity) => {
                    entity.isSelected = values.includes(entity.id);
                });

                this.$emit("input", {
                    type: this.type,
                    values: values,
                });
            },
        },
    };
</script>
