<template>
    <div>
        <app-text-field
            v-if="!isEditing"
            key="displayField"
            v-bind="$attrs"
            :value="display"
            @focus="isEditing = true"
        />
        <app-text-field
            v-if="isEditing"
            v-model="militaryTime"
            v-bind="$attrs"
            autofocus
            :rules="combinedRules"
            @change="onChange"
            @blur="isEditing = false"
            @focus="$event.target.select()"
        />
    </div>
</template>

<script>
    import { toIso, canParseTime } from "@/services/timeUtility";
    import { isNullOrWhiteSpace } from "@/services/stringUtility";

    import { formatTime, parseIso } from "@/services/dateUtility";

    function mapRule(rule) {
        return (militaryTime) => rule(toIso(militaryTime));
    }

    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
            required: {
                type: Boolean,
                default: false,
            },
            rules: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                // This is in the short time format for the configured locale.
                display: null,
                militaryTime: null,
                isEditing: false,
                defaultRules: [
                    (v) => !this.required || !isNullOrWhiteSpace(v) || "Time is required",
                    (v) => {
                        if (isNullOrWhiteSpace(v) || canParseTime(v)) {
                            return true;
                        }
                        return "Enter time as HHmm";
                    },
                ],
            };
        },

        computed: {
            combinedRules() {
                return [...this.defaultRules, ...this.rules.map(mapRule)];
            },
            valid() {
                return this.combinedRules.every((rule) => rule(this.militaryTime) === true);
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(value) {
                    let dateTime = parseIso(value);
                    if (dateTime === null) {
                        this.display = null;
                        this.militaryTime = null;
                        return;
                    }
                    this.display = formatTime(dateTime);
                    this.militaryTime = dateTime.toFormat("HHmm");
                },
            },
        },

        methods: {
            onChange(militaryTime) {
                if (isNullOrWhiteSpace(militaryTime)) {
                    this.$emit("input", null);
                    this.isEditing = false;
                    return;
                }

                if (!this.valid) {
                    return;
                }

                let isoTime = toIso(militaryTime);
                let valueWithoutOffset = this.value?.split("+")[0];
                if (valueWithoutOffset !== isoTime) {
                    this.$emit("input", isoTime);
                } else {
                    let dateTime = parseIso(this.value);
                    this.militaryTime = dateTime.toFormat("HHmm");
                }
                this.isEditing = false;
            },
        },
    };
</script>
