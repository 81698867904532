import successEnum from "../../enums/successEnum";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

class ProgressItem {
    constructor(message, type) {
        this.message = message;
        this.type = type;
    }

    class() {
        return this.type == successEnum.Success ? "progress-item-color-ok" : "progress-item-color-error";
    }
}

export const plodtrackSync = {
    namespaced: true,
    state: {
        status: [],
        syncComplete: false,
        syncFailed: false,
    },
    actions: {
        reset({ commit }) {
            commit("reset");
        },
        async connect({ commit, state, dispatch }) {
            if (state.connection) {
                return;
            }
            const connection = new HubConnectionBuilder()
                .withUrl("/plodtrack-hub")
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

            await connection.start();
            connection.on("ReceiveAsync", (message) => dispatch("appendSuccessMessage", message));
            connection.on("ErrorAsync", (message) => dispatch("onError", message));
            connection.on("CompleteAsync", () => dispatch("syncComplete"));
            commit("connection", connection);
        },
        disconnect({ commit, state }) {
            if (!state.connection) {
                return;
            }
            state.connection.stop();
            commit("connection", null);
        },
        appendSuccessMessage({ commit }, message) {
            commit("appendMessage", new ProgressItem(message, successEnum.Success));
        },
        onError({ commit }, message) {
            commit("appendMessage", new ProgressItem(message, successEnum.Error));
            commit("syncFailed");
        },
        syncComplete({ commit }) {
            commit("syncComplete");
        },
        syncFailed({ commit }) {
            commit("syncFailed");
        },
    },
    mutations: {
        reset(state) {
            state.status = [];
            state.syncComplete = false;
            state.syncFailed = false;
        },
        appendMessage(state, message) {
            state.status.push(message);
        },
        syncComplete(state) {
            state.syncComplete = true;
        },
        syncFailed(state) {
            state.syncComplete = true;
            state.syncFailed = true;
        },
        connection(state, connection) {
            state.connection = connection;
        },
    },
};
