import { camelToTitleCase } from "./stringUtility";

export function enumAsSelectList(enumDictionary, addSpace = true) {
    // Convert an enum Ie a dict of numbers vs text description to an array suitable for use in a select control ie an array of dicts with two key/value pairs value and text
    return Object.keys(enumDictionary).map((k) => {
        return {
            value: parseInt(enumDictionary[k]),
            text: addSpace ? addSpaceToPascalCase(k) : k,
        };
    });
}

export function reverseEnum(enumDictionary, addSpace = true) {
    // Enums are implemented as a key value pair where the key is a human readable name and the value is an integer. But sometimes we need to get the human readable value from the integer
    // to do this we reverse the enum and produce a dictionary where ints are the keys and strings are the values
    return Object.keys(enumDictionary).reduce((result, k) => {
        let v = enumDictionary[k];
        result[v] = addSpace ? addSpaceToPascalCase(k) : k;
        return result;
    }, {});
}

function enumLowerWithSpaces(enumDictionary) {
    // add spaces in enum values
    return Object.keys(enumDictionary).map((k) => {
        return camelToTitleCase(k).toLowerCase();
    });
}

export function addSpaceToPascalCase(value) {
    return value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([a-z])/g, " $1$2")
        .replace(/ +/g, " ");
}

export default {
    enumAsSelectList,
    reverseEnum,
    enumLowerWithSpaces,
};
