import axios from "axios";
import store from "../store/store";

export default function () {
    axios.interceptors.response.use(
        (response) => response,

        function (error) {
            if (error.response && error.response.status === 401) {
                const redirectUrl = getRedirectUrl();
                store.dispatch("authentication/logout", redirectUrl);
            }
            throw error;
        }
    );

    function getRedirectUrl() {
        const currentPath = window.location.pathname;
        const currentSearch = window.location.search;

        const isLoginPage = currentPath === "/login";
        const hasRedirectParam = window.location.search.includes("redirect");

        if (isLoginPage && hasRedirectParam) {
            // Strip the redirect? and get the redirect URL
            return window.location.search.replace("?redirect=", "");
        } else if (!isLoginPage && !hasRedirectParam) {
            // If no redirect parameter, construct the redirect URL
            return currentPath + currentSearch;
        } else {
            // Otherwise don't append a redirect URL
            return null;
        }
    }
}
