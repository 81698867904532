import axios from "axios";
import { DateTime } from "luxon";
import store from "@/store/store";
import { getEntity } from "./schemaProvider";
import { getLabel } from "./labeller";
import { hasProperty } from "@/services/objectService";

function saveBlob(response) {
    function getFilename(response) {
        let disposition = response.headers["content-disposition"];

        if (disposition && disposition.indexOf("attachment") !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, "");
            }

            return "export.xslx";
        }
    }

    let blob = new Blob([response.data], { type: response.headers["content-type"] });

    let downloadUrl = window.URL.createObjectURL(blob);
    let filename = getFilename(response);
    let link = document.createElement("a");

    if (typeof link.download === "undefined") {
        window.location.href = downloadUrl;
    } else {
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
    }
}

function isObject(value) {
    return typeof value === "object" && value !== null;
}

function hasProperties(value) {
    for (let key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            return true;
        }
    }
    return false;
}

function isIdLabel(value) {
    return "id" in value && "label" in value;
}

function getFieldValue(item, field, lookups) {
    const value = item[field.key];

    if (!field.dependsOn) {
        return value;
    }

    let entityKey = field.dependsOn.type;

    if (!hasProperty(lookups, entityKey)) {
        return null;
    }

    const fieldItem = lookups[entityKey][value];
    return getLabel(entityKey, fieldItem);
}

async function exportExcelResult(url, parameters = undefined) {
    try {
        let result;
        if (parameters) {
            result = await axios.post(url, parameters, { responseType: "blob" });
        } else {
            result = await axios.get(url, { responseType: "blob" });
        }
        saveBlob(result);
    } catch (error) {
        store.dispatch("alert/error", "There was an error downloading the file. " + error);
    }
}

export async function downloadAsExcel(data) {
    if (data == null || data.items == null || !data.items.length) {
        store.dispatch("alert/error", "There is no data to export.");
        return;
    }

    let items = [];

    data.items.forEach((e) => {
        let item = Object.assign({}, e);

        for (let property in item) {
            let value = item[property];

            if (!isObject(value)) {
                continue;
            }

            if (isIdLabel(value)) {
                item[property] = value.label;
            } else if (!hasProperties(value)) {
                item[property] = "";
            }
        }

        items.push(item);
    });

    let parameters = {
        title: data.title,
        items,
        clientNow: DateTime.local().toISO(),
    };

    await exportExcelResult("/api/Export/ToExcel", parameters);
}

export async function exportToExcel(tableData) {
    const entity = getEntity(tableData.entityKey);

    const fields = Object.keys(entity.queryFields).map((key) => entity.queryFields[key]);

    const items = tableData.items.map((item) => {
        const row = {};
        fields.forEach((field) => (row[field.title] = getFieldValue(item, field, tableData.lookups)));
        return row;
    });

    await downloadAsExcel({
        title: entity.pluralTitle,
        items,
    });
}

export async function exportSummaryReportToExcel(filter) {
    await exportExcelResult("/api/ExmReportExport/GetSummaryExcelFile", filter);
}

export async function exportMovementReportToExcel(filter) {
    await exportExcelResult("/api/ExmReportExport/GetMovementExcelFile", filter);
}

export async function exportStocktakeReportToExcel(filter) {
    await exportExcelResult("/api/ExmReportExport/GetStocktakeExcelFile", filter);
}

export async function exportAuditReportToExcel(filter) {
    await exportExcelResult("/api/ExmReportExport/GetAuditReportExcelFile", filter);
}

export async function exportStockOnHandToExcel() {
    await exportExcelResult("/api/ExmReportExport/GetStockOnHandExcelFile");
}

export async function exportStockUsageReportToExcel(filter) {
    await exportExcelResult("/api/ExmReportExport/GetStockUsageExcelFile", filter);
}

export async function exportItemOrderReportToExcel(batchId) {
    await exportExcelResult(`/api/MagazineOrder/GetItemOrderExcelFile/${batchId}`);
}

export default {
    downloadAsExcel,
};
