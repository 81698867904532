<template>
    <div class="d-flex flex-column">
        <div class="d-flex mb-4">
            <v-tabs background-color="transparent">
                <v-tab v-for="(item, i) in items" :key="i" @click="selectedItem = item">
                    {{ getTitle(item) }}
                </v-tab>
            </v-tabs>
        </div>

        <component
            v-if="selectedItem"
            :is="tableViewComponent"
            :key="selectedItem.entity.key"
            class="flex-grow-1"
            :entity-key="selectedItem.entity.key"
            :fixed-values="getFixedValues(selectedItem)"
            :parent-entity="parentEntity"
        />
    </div>
</template>

<script>
    import { getDependants } from "@/features/schemas/services/schemaProvider";
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import { hasProperty } from "@/services/objectUtility";
    import { getTableView } from "../tableViews";

    export default {
        mixins: [schemaMixin],

        props: {
            itemId: {
                type: [Number, String],
                default: null,
            },
            fixedValuesByEntity: {
                type: [Object],
                default: () => {},
            },
            parentItem: {
                type: [Object],
                default: null,
            },
        },

        data() {
            return {
                selectedItem: null,
            };
        },

        computed: {
            items() {
                return getDependants(this.entityKey);
            },

            tableViewComponent() {
                return getTableView(this.selectedItem.entity.key);
            },

            parentEntity() {
                if (this.parentItem == null) {
                    return null;
                }

                return {
                    key: this.entityKey,
                    item: this.parentItem,
                };
            },
        },

        methods: {
            getTitle(item) {
                // If the foreign key title matches this entity's title, then the foreign key entity
                // title will suffice.
                if (item.field.title === this.entity.singleTitle) {
                    return item.entity.pluralTitle;
                }

                // Otherwise, include the field title to distinguish the source of the records.
                return `${item.entity.pluralTitle} (${item.field.title})`;
            },
            getFixedValues(item) {
                let result = {};
                result[item.field.key] = this.itemId;
                if (hasProperty(this.fixedValuesByEntity, item.entity.key)) {
                    result = { ...result, ...this.fixedValuesByEntity[item.entity.key] };
                }
                return result;
            },
        },

        mounted() {
            if (this.items == null || this.items.length < 1) return;

            this.selectedItem = this.items[0];
        },
    };
</script>
