export const equipmentReview = {
    namespaced: true,

    state: {
        summaryVisible: true,
    },

    mutations: {
        summaryVisible(state, value) {
            state.summaryVisible = value === "true" || value === true;
            localStorage.setItem("equipmentReviewSummary", state.summaryVisible);
        },
    },

    getters: {
        summaryVisible(state) {
            let summary = localStorage.getItem("equipmentReviewSummary");
            if (typeof summary !== "undefined" && summary != null) {
                return summary === "true" || summary === true;
            }
            return state.summaryVisible;
        },
    },
};
