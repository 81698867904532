<template>
    <v-btn class="px-0 app-btn" :color="color" v-bind="$attrs" v-on="$listeners">
        <v-icon v-if="icon" class="app-btn-icon">
            {{ icon }}
        </v-icon>
        <span class="px-5 flex-grow-1"><slot /></span>
    </v-btn>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: "primary",
            },
            icon: {
                type: String,
                default: null,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-btn {
        background-color: white;
    }
    .app-btn-icon {
        height: 36px;
        width: 36px;
        font-size: 22px;
        margin-right: -5px;
    }

    .app-btn.v-size--default {
        color: #ffffff;
    }
</style>
