import { pascalToCamelCase } from "@/services/stringUtility";
import schemaForm from "../components/SchemaForm";

const formsByEntityKey = {};

// Grab all the *Form.vue files in this folder (exluding subfolders).
let requireComponent = require.context(".", false, /^.\/([\w-]+)Form\.vue$/);

requireComponent.keys().forEach((filePath) => {
    const form = requireComponent(filePath);
    const entityKey = pascalToCamelCase(filePath.slice(2, -8));
    formsByEntityKey[entityKey] = form.default;
});

export function getForm(entityKey) {
    return formsByEntityKey[entityKey] ?? schemaForm;
}

export default {
    getForm,
};
