import state from "./shiftAllocation/state";
import mutations from "./shiftAllocation/mutations";
import getters from "./shiftAllocation/getters";
import actions from "./shiftAllocation/actions";

export const shiftAllocation = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
