<template>
    <v-simple-table class="app-simple-table" :items="items" :headers="headers" dense>
        <thead>
            <tr>
                <th v-for="header in headers" :key="header.value">
                    {{ header.text }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items" :key="item.id">
                <td v-for="fieldKey in fieldKeys" :key="fieldKey">
                    <component
                        :is="getCellComponent(fieldKey)"
                        :key="fieldKey"
                        :value="item[fieldKey]"
                        :options="getCellOptions(fieldKey)"
                    />
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
    import { buildCellOptions, getCellComponent } from "@/features/schemas/services/tableService";

    export default {
        props: {
            tableData: {
                type: Object,
                default: () => ({
                    entityKey: "",
                    headers: [],
                    items: [],
                    total: 0,
                    lookups: {},
                }),
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            fieldKeys() {
                return this.tableData?.headers?.map((h) => h.value) ?? [];
            },
            items() {
                return this.tableData?.items ?? [];
            },
            headers() {
                return this.tableData?.headers ?? [];
            },
        },
        methods: {
            getCellComponent(fieldKey) {
                return getCellComponent(this.tableData?.entityKey, fieldKey);
            },
            getCellOptions(fieldKey) {
                return buildCellOptions(this.tableData, fieldKey);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-simple-table::v-deep td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .app-simple-table::v-deep td > * {
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
