<template>
    <div class="app-search">
        <app-text-field
            v-if="!isEditing"
            key="displayField"
            class="app-search-item-display"
            v-bind="$attrs"
            :value="display"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            :autofocus="false"
            :append-icon="showClearIcon ? 'mdi-close' : ''"
            @focus="edit"
            @click:append="clearSelection()"
        />

        <app-text-field
            v-if="isEditing"
            ref="editField"
            key="editField"
            v-model="searchText"
            v-bind="$attrs"
            :label="label"
            :hide-details="hideDetails"
            autocomplete="off"
            autofocus
            @blur="clear"
            @keydown="onKeyDown"
        />

        <v-menu
            :value="items && items.length"
            :close-on-click="false"
            :close-on-content-click="false"
            :disable-keys="true"
            :open-on-click="false"
            :max-height="304"
            :top="menuPosition"
            :nudge-top="nudgeAmount"
            @input="updateMenuPosition()"
        >
            <template v-slot:activator="{ on }">
                <div ref="activator" :style="activatorStyle" @null="on" />
            </template>
            <v-list class="app-search-results">
                <v-list-item
                    v-for="(item, index) in items"
                    :key="item.id"
                    link
                    :class="itemClass(index)"
                    @mousedown.prevent="onItemClick(item)"
                >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                    <slot name="list-item-icon" :item="item"></slot>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
    import { search } from "@/features/schemas/services/searcher";
    import { get } from "@/features/schemas/services/schemaApi";
    import { getLabel } from "@/features/schemas/services/labeller";
    import { debounce } from "@/services/debounce";

    export default {
        props: {
            entityKey: {
                type: String,
                default: null,
                required: true,
            },
            value: {
                type: String,
                default: null,
            },
            label: {
                type: String,
                default: () => "Search",
            },
            filter: {
                type: Object,
                default: null,
            },
            searchOptions: {
                type: Object,
                default: null,
            },
            searchWhenBlank: {
                type: Boolean,
                default: false,
            },
            hideDetails: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isEditing: false,
                searchText: null,
                results: null,
                selectedIndex: -1,
                selectedItem: null,
                menuPosition: false,
                nudgeAmount: 0,
            };
        },
        computed: {
            items() {
                return this.results?.items ?? [];
            },
            display() {
                if (this.selectedItem == null) {
                    return "";
                }

                return this.selectedItem.label;
            },
            activatorStyle() {
                if (this.hideDetails) {
                    return null;
                }
                return {
                    marginTop: "-26px",
                    height: "26px",
                };
            },
            showClearIcon() {
                return this.clearable && this.selectedItem != null;
            },
            menuListHeight() {
                // Each item is 50px, -10px to account for padding
                const itemListHeight = this.items.length * 50 - 10;
                return itemListHeight > 304 ? 304 : itemListHeight;
            },
        },
        watch: {
            searchText: debounce(function () {
                return this.search();
            }, 250),
            selectedItem(selectedItem) {
                if (this.value !== selectedItem?.id) {
                    this.$emit("input", selectedItem?.id);
                }
            },
            value: {
                immediate: true,
                async handler(value) {
                    if (!value) {
                        this.selectedItem = null;
                        return;
                    }

                    if (this.selectedItem?.id !== value) {
                        let item = await get(this.entityKey, value);
                        item.label = getLabel(this.entityKey, item);
                        this.selectedItem = item;
                    }
                },
            },
        },
        methods: {
            // Put the menu above the input box if not enough space in the viewport to fit it.
            updateMenuPosition() {
                var inputBox = this.$refs["activator"].getBoundingClientRect();
                // Calculate the distance from the bottom of the viewport

                const distanceFromBottom = window.innerHeight - inputBox.bottom;

                if (distanceFromBottom < this.menuListHeight) {
                    this.menuPosition = true;
                    this.nudgeAmount = 80;
                } else {
                    this.menuPosition = false;
                    this.nudgeAmount = 0;
                }
            },
            edit() {
                this.isEditing = true;
                this.search();
            },
            async search() {
                if (!this.searchWhenBlank && isNullOrWhiteSpace(trim(this.searchText, '"'))) {
                    this.results = null;
                    return;
                }

                let searchText = this.searchText;

                let options = {
                    includeLabels: true,
                    filter: this.filter,
                    sortBy: this.searchOptions?.sortBy,
                    direction: this.searchOptions?.direction,
                };

                let results = await search(this.entityKey, searchText, options);

                // Avoid showing previous search if results arrive out of order.
                if (searchText === this.searchText) {
                    this.results = results;
                }
            },
            onKeyDown(e) {
                let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Tab"];

                if (handledKeys.includes(e.key)) {
                    let handler = this[`on${e.key}`];
                    handler(e);
                }
            },
            onArrowDown(e) {
                let index = this.selectedIndex + 1;
                if (index < this.items.length) {
                    this.selectedIndex = index;
                }
                e.preventDefault();
            },
            onArrowUp(e) {
                let index = this.selectedIndex - 1;
                if (index > -1) {
                    this.selectedIndex = index;
                }
                e.preventDefault();
            },
            onEnter(e) {
                if (this.selectedIndex > -1) {
                    this.selectedItem = this.items[this.selectedIndex];
                    this.clear();
                }
                e.preventDefault();
            },
            onTab() {
                if (this.selectedIndex > -1) {
                    this.selectedItem = this.items[this.selectedIndex];
                    this.clear();
                }
            },
            onItemClick(item) {
                this.selectedItem = item;
                this.clear();
            },
            clear() {
                this.results = null;
                this.selectedIndex = -1;
                this.isEditing = false;
            },
            itemClass(index) {
                if (index == this.selectedIndex) {
                    return "app-search-selected";
                }
            },
            clearSelection() {
                this.selectedItem = null;
                this.clear();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .app-search-selected {
        color: #f7901e;
        background-color: rgba(#f7901e, 0.16);
    }

    .app-search-results > * {
        cursor: pointer;
    }

    .app-search::v-deep .v-input__append-inner:hover {
        cursor: pointer;
    }
</style>
