<template>
    <div>
        <schema-table :entity-key="entityKey" v-bind="$attrs" v-on="$listeners" style="height: 100%">
            <template #add-button="{ canAdd }">
                <app-button v-if="canAdd" class="ml-2" @click="add">Add {{ entity.singleTitle }}</app-button>
            </template>
        </schema-table>

        <magazine-item-wizard
            :key="wizardKey"
            :value="wizard"
            @close="wizard = false"
            @save="onSave"
            is-category-wizard
        ></magazine-item-wizard>
    </div>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import magazineItemWizard from "@/wizards/magazineItemWizard/MagazineItemWizard.vue";
    import { getItemRoute } from "@/features/schemas/services/schemaRouteProvider";

    export default {
        mixins: [schemaMixin],
        components: { magazineItemWizard },
        data() {
            return {
                wizard: false,
                wizardKey: 0,
            };
        },

        methods: {
            add() {
                this.wizardKey++;
                this.wizard = true;
            },

            onSave(categoryId) {
                var itemRoute = getItemRoute(this.entityKey);
                if (itemRoute == null) {
                    return;
                }
                this.$router.push({
                    name: itemRoute.name,
                    params: { itemId: categoryId },
                });
            },
        },
    };
</script>

<style scoped>
    .disabled {
        color: #999999;
    }
</style>
