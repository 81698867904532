<template>
    <span>
        <v-icon v-if="this.value" @click="show">mdi-comment-text</v-icon>
        <app-dialog v-model="isShowing" not-persistent :max-width="maxWidth">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                    <div>{{ value }}</div>
                </v-card-text>
            </v-card>
        </app-dialog>
    </span>
</template>

<script>
    export default {
        // Must be consumed as a base component, not registered for a type.
        types: [],
        props: ["value", "title", "maxWidth"],
        data() {
            return {
                isShowing: false,
            };
        },
        computed: {},
        methods: {
            show() {
                this.isShowing = true;
            },
        },
    };
</script>
