<template>
    <v-text-field
        type="date"
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        v-on="$listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";

    export default {
        types: [],
        mixins: [formFieldMixin],
    };
</script>
