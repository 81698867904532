import { getEntity } from "@/features/schemas/services/schemaProvider";
import { canUndelete } from "../services/schemaApi";
import { mapState } from "vuex";

export default {
    props: {
        entityKey: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState({
            schemaIsLoaded: (s) => s.schema.isLoaded,
        }),
        entity() {
            return getEntity(this.entityKey);
        },
        canUndelete() {
            return canUndelete(this.entityKey);
        },
    },
};
