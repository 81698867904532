<template>
    <v-card>
        <v-card-text>
            <div class="schema-filter-wrap pa-4">
                <schema-filter-field :entity-key="entityKey" @input="$emit('input', $event)" />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    const schemaFilterField = () => import("./SchemaFilterField.vue");

    export default {
        components: {
            schemaFilterField,
        },
        mixins: [schemaMixin],
        data() {
            return {};
        },
    };
</script>

<style scoped lang="scss">
    .schema-filter-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
</style>
