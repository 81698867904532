<template>
    <div :style="{ backgroundColor: value, width: '25px', height: '25px', borderRadius: '4px' }" />
</template>

<script>
    export default {
        types: ["color"],
        props: {
            value: {
                type: [String],
                default: null,
            },
        },
    };
</script>
