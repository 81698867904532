import router from "@/router";

export function goTo(path) {
    if (router.currentRoute.path !== path) {
        router.push(path);
    }
}

export default {
    goTo,
};
