<template>
    <div ref="timePicker" class="app-time-picker px-3">
        <div v-for="time in times" :key="time.display" class="px-2 py-2" @click="onClick(time)">
            {{ time.display }}
        </div>
    </div>
</template>

<script>
    import { DateTime } from "luxon";
    import { formatTime, now, parseIso } from "@/services/dateUtility";

    const dateTimes = [];

    for (let i = 0; i < 24 * 15 * 4; i += 15) {
        let minute = i % 60;
        let hour = (i - minute) / 60;
        dateTimes.push(DateTime.fromObject({ hour, minute }));
    }

    function parseTimeOrNow(isoTime) {
        let dateTime = parseIso(isoTime);

        if (dateTime === null || !dateTime.isValid) {
            return now();
        }

        return dateTime;
    }

    export default {
        props: {
            value: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                observer: null,
            };
        },
        computed: {
            times() {
                return dateTimes.map((d) => ({
                    dateTime: d,
                    display: formatTime(d),
                }));
            },
        },
        mounted() {
            // Use an observer to detect when this component is made visible.
            this.observer = new IntersectionObserver(this.onVisibilityChange);

            this.$nextTick(() => {
                this.observer.observe(this.$refs.timePicker);
            });
        },
        destroyed() {
            this.observer?.disconnect();
        },
        methods: {
            onClick(time) {
                this.$emit("input", time.dateTime.toISOTime({ includeOffset: false }));
            },
            onVisibilityChange(entries) {
                if (!entries[0].isIntersecting) {
                    return;
                }

                // When this component is made visible, let's scroll to the current value.
                let dateTime = parseTimeOrNow(this.value);
                let minutes = dateTime.hour * 60 + dateTime.minute;
                // If the current value isn't divisible by 15, then we round down.
                let i = Math.floor(minutes / 15);

                let element = this.$refs.timePicker;
                element.children[i].scrollIntoView();
            },
        },
    };
</script>

<style lang="scss">
    .app-time-picker {
        background-color: white;
        max-height: 200px;
        overflow: auto;
        white-space: nowrap;

        > * {
            cursor: pointer;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 28px;
            font-weight: 500;
            transition: background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1);

            &:hover {
                background-color: #ededed;
            }
        }
    }
</style>
