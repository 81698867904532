import { getField, updateField } from "vuex-map-fields";
export const navigation = {
    namespaced: true,
    state: {
        drawer: true,
    },
    mutations: {
        updateField,
    },
    getters: {
        getField,
    },
};
