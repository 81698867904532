<template>
    <schema-data-table ref="table" v-bind="$attrs" v-on="$listeners">
        <template v-slot:[`item.feedbackSource`]="{ item }">
            <v-chip class="text-uppercase" :color="feedbackSourceColor(item)">
                {{ camelToTitleCase(item.feedbackSource) }}
            </v-chip>
        </template>
    </schema-data-table>
</template>

<script>
    import { camelToTitleCase } from "@/services/stringUtility";
    export default {
        methods: {
            camelToTitleCase,
            feedbackSourceColor(item) {
                // feedback enum is returned as camelCase string
                if (item.feedbackSource === "exm") return "primary";
                if (item.feedbackSource === "liveMine") return "secondary";
                return undefined;
            },
        },
    };
</script>
