<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
        <template #form-title>
            <v-card-title>
                <span class="headline">{{ isEdit ? "Edit" : "Add" }} Order</span>
            </v-card-title>
        </template>

        <template #field-orderId="{ field, item, autofocus }">
            <div class="d-flex justify-center">
                <form-text-field
                    v-model="item.orderId"
                    :key="field.value"
                    :field="field"
                    required
                    :autofocus="autofocus"
                    validate-on-blur
                />
                <v-btn icon outlined small fab class="ml-2" @click="generateOrderId(item)">
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
            </div>
        </template>

        <template #field-deliveryLeadTimeDays="{ field, item }">
            <form-number v-model="item.deliveryLeadTimeDays" :key="field.value" :field="field" disabled required />
        </template>

        <template #field-usageLookBackPeriodStart="{ field, item }">
            <form-date-time-field v-model="item.usageLookBackPeriodStart" :field="field" disabled />
        </template>

        <template #field-usageLookBackPeriodEnd="{ field, item }">
            <form-date-time-field v-model="item.usageLookBackPeriodEnd" :field="field" disabled />
        </template>

        <template #field-dateStarted="{ field, item }">
            <form-date-time-field v-model="item.dateStarted" :field="field" disabled />
        </template>

        <template #field-dateOrdered="{ field, item }">
            <v-checkbox
                :input-value="!!item.dateOrdered"
                @change="(checked) => handleDateOrderedChange(checked, item)"
                label="Ordered?"
                size="sm"
                style="display: inline-block"
            />
            <form-date-time-field v-model="item.dateOrdered" :field="field" />
        </template>

        <template #field-dateReceived="{ field, item }">
            <v-checkbox
                :input-value="!!item.dateReceived"
                @change="(checked) => handleDateReceivedChange(checked, item)"
                label="Received?"
                size="sm"
                style="display: inline-block"
            />
            <form-date-time-field v-model="item.dateReceived" :field="field" />
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import FormTextField from "../formFields/FormTextField.vue";
    import FormDateTimeField from "../formFields/FormDateTimeField.vue";
    import FormNumber from "../formFields/FormNumber.vue";

    import { nowIso } from "@/services/dateUtility";

    export default {
        props: {
            parentEntity: {
                type: Object,
                default: null,
            },
        },
        components: { FormTextField, FormDateTimeField, FormNumber },
        methods: {
            async generateOrderId(item) {
                this.$set(item, "orderId", Math.floor(Math.random() * 1000000).toString());
            },
            handleDateOrderedChange(checked, item) {
                this.$set(item, "dateOrdered", checked ? nowIso() : null);
            },
            handleDateReceivedChange(checked, item) {
                this.$set(item, "dateReceived", checked ? nowIso() : null);
            },
        },
    };
</script>
