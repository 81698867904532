<template>
    <v-menu v-model="menu" top :close-on-content-click="false" max-width="300">
        <template v-slot:activator="{ on }">
            <v-text-field
                v-if="!hideInput"
                v-model="inputValue"
                v-mask="mask"
                :readonly="readonly || !allowInput"
                :hide-details="hideDetails"
                :label="label"
                :required="required"
                :rules="rules"
                :solo="solo"
                class="ma-0 pa-0"
                :clearable="clearable"
                :outlined="outlined"
                :dense="dense"
                @change="textFieldChange"
                @click:clear="clearColor"
                @click="textFieldClick"
            >
                <template v-slot:append>
                    <v-icon :style="swatchStyle" v-on="on">{{ iconValue }}</v-icon>
                </template>
            </v-text-field>
            <v-icon v-else :style="swatchStyle" v-on="on">{{ iconValue }}</v-icon>
        </template>
        <v-card max-width="300">
            <v-card-text class="pa-0 ma-0">
                <v-color-picker
                    show-swatches
                    swatches-max-height="300px"
                    mode="hexa"
                    v-model="color"
                    flat
                    @input="pickerInput"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="clearable" text @click="clearColor">Clear</v-btn>
                <v-spacer />
                <v-btn text @click="menu = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
    // This component is based from:
    // https://codepen.io/JamieCurnow/pen/KKPjraK

    import Vue from "vue";
    import { mask } from "vue-the-mask";

    export default Vue.component("color-field-picker", {
        directives: { mask },
        props: {
            label: String,
            value: [String, Object],
            required: Boolean,
            rules: Array,
            readonly: Boolean,
            hideDetails: Boolean,
            /**
             * configure visibility of the text field.
             */
            hideInput: Boolean,
            clearable: Boolean,
            /**
             * True to allow text input. If false, text input will be readonly and click opens color picker swatches.
             */
            allowInput: Boolean,
            outlined: Boolean,
            dense: Boolean,
            solo: Boolean,
        },
        data() {
            return {
                // value of the v-text-field
                inputValue: null,
                // value of the v-color-picker
                color: null,
                mask: "!#XXXXXX",
                menu: false,
            };
        },
        methods: {
            textFieldClick() {
                // if allowInput is false open swatches on click
                if (!this.allowInput) {
                    this.menu = true;
                }
            },
            textFieldChange(value) {
                if (value) {
                    this.color = value || "#FFFFFF";
                }
                this.$emit("input", value);
            },
            pickerInput(value) {
                this.inputValue = value;
                this.$emit("input", value);
            },
            clearColor() {
                this.menu = false;
                this.$emit("input", null);
            },
        },

        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (typeof value === "undefined" || value === null || value === "") {
                        // reset color to transparent
                        // setting this to null makes the v-color-picker to reset it back to the default red color object (not string).
                        this.color = "#FFFFFF";
                        this.inputValue = null;
                    } else {
                        this.color = value;
                        this.inputValue = value;
                    }
                },
            },
        },
        computed: {
            iconValue() {
                return this.value ? null : "mdi-checkbox-blank-off-outline";
            },
            swatchStyle() {
                const { color, menu, value } = this;
                return {
                    backgroundColor: color,
                    cursor: "pointer",
                    height: "25px",
                    width: "25px",
                    borderRadius: menu ? "50%" : "4px",
                    border: value ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                    transition: "border-radius 200ms ease-in-out",
                };
            },
        },
    });
</script>
