<template>
    <dialog-cell title="Data" :value="value" :max-width="'70%'" />
</template>

<script>
    import DialogCell from "./DialogCell.vue";
    export default {
        types: ["largeString"],
        components: { DialogCell },
        props: ["value"],
    };
</script>
