import Vue from "vue";
import dateUtility from "../services/dateUtility";

export default Vue.mixin({
    methods: {
        formatTime(value) {
            return dateUtility.formatTime(value);
        },

        formatTimeWithOffset(value) {
            return dateUtility.formatTimeWithOffset(value);
        },

        formatTimespan(value, format = false) {
            return dateUtility.formatTimespan(value, format);
        },

        formatDate(value) {
            return dateUtility.formatDate(value);
        },

        formatUtcToOffset(value) {
            return dateUtility.formatUtcToOffset(value);
        },

        formatDateOnly(value) {
            return dateUtility.formatDateOnly(value);
        },

        formatBoolYesNo(value) {
            return value ? "Yes" : "No";
        },

        formatNull(value) {
            return value === null || typeof value === "undefined" ? "-" : value;
        },

        formatDecimal(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        },
    },
});
