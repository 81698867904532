//TODO: Remove duplication where this pattern is used elsewhere (e.g. TimeFilter)

export default {
    props: {
        value: {
            type: [Object],
            default: null,
        },
    },

    data() {
        return {
            localValue: null,
            jsonState: null,
        };
    },

    methods: {
        getDefault() {
            return {};
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                const defaultValue = this.getDefault();
                if (value !== null) {
                    Object.keys(defaultValue).forEach((key) => (this[key] = value[key] ?? defaultValue[key]));
                } else {
                    Object.assign(this, defaultValue);
                }
                this.jsonState = JSON.stringify(this.localValue);
            },
        },
        localValue: {
            immediate: true,
            handler(value) {
                // Only emit a change if the object looks different.
                const json = JSON.stringify(value);
                if (json === this.jsonState) {
                    return;
                }

                this.jsonState = json;
                this.$emit("input", value);
            },
        },
    },
};
