<template>
    <app-data-table
        v-model="selectedItems"
        :class="{ 'app-data-table-editable': canEditEntity }"
        :items="tableData.items"
        :headers="headers"
        :server-items-length="tableData.total"
        :list-options.sync="options"
        :page.sync="page"
        :show-select="canDeleteEntity || canUndeleteEntity"
    >
        <template v-for="field in fieldKeys" v-slot:[`header.${field}`]="{ header }">
            <slot :name="`header.${field}`" v-bind:header="header">
                {{ header.text }}
                <component
                    v-if="localFilter.hasOwnProperty(field)"
                    :is="getFilterComponent(field)"
                    v-model="localFilter[field]"
                    :options="getHeaderOptions(field)"
                />
            </slot>
        </template>

        <template v-for="field in fieldKeys" v-slot:[`item.${field}`]="{ item }">
            <slot :name="`item.${field}`" v-bind:item="item">
                <component
                    :is="getCellComponent(field)"
                    :key="field"
                    :options="getCellOptions(field)"
                    :value="item[field]"
                />
            </slot>
        </template>

        <template v-if="selectedItems.length >= 1" #header.actions>
            <slot v-if="canDeleteEntity" name="deleteManyButton" :deleteMany="deleteMany">
                <v-icon class="float-right" @click.stop="deleteMany()">mdi-delete</v-icon>
            </slot>
            <v-icon v-if="canUndeleteEntity" class="float-right" @click.stop="undeleteMany()">
                mdi-delete-restore
            </v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <div class="no-overflow-ellipsis d-flex justify-end">
                <v-icon v-if="recordState == 'active' && canEditEntity" class="mr-2" @click.stop="$emit('edit', item)">
                    mdi-pencil
                </v-icon>
                <slot v-if="recordState == 'active' && canViewEntity" name="view-item-action" :item="item">
                    <v-icon @click.stop="viewItem(item)">mdi-arrow-right-thick</v-icon>
                </slot>

                <v-icon v-if="recordState == 'deleted' && canUndeleteEntity" @click.stop="undelete(item)">
                    mdi-delete-restore
                </v-icon>
                <slot name="extra-actions" :item="item" />
            </div>
        </template>
    </app-data-table>
</template>

<script>
    import schemaFilterMixin from "@/features/schemas/mixins/schemaFilterMixin";
    import dataTableMixin from "@/features/schemas/mixins/dataTableMixin";

    export default {
        mixins: [dataTableMixin, schemaFilterMixin],
        props: {
            sortByOverride: {
                type: String,
                default: null,
            },
            sortDescOverride: { type: Boolean, default: null },
        },
    };
</script>

<style lang="scss" scoped>
    .app-data-table-editable::v-deep th:last-child {
        width: 1%;
    }

    .app-data-table-editable::v-deep td:last-child {
        white-space: nowrap;
    }

    /* Stick actions to the right of the table, so they are visible without needing to scroll */
    ::v-deep .action-cell {
        position: sticky;
        right: 0px;
        background: white;
    }
</style>
