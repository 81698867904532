export default {
    /* Shift Allocation Panel */
    shift: null, //The selected shift
    crews: [], //The selected crews
    selectedCrews: [],
    date: null,

    /* General */
    saved: true, //By default this is true, because the user has not made any changes yet

    tab: null,
    /* Data */
    activities: {},
    equipments: [],
    hiddenEquipments: [],
    loading: false,
    locations: [],
    modules: {},
    operators: [],
    productionDrillPlans: [],
    schema: require("@/shiftAllocation/schema").default,
    slots: null,
    tasks: [],

    /* Shift Allocation Content */
    selectedModule: null,
    selectedSlot: null,
    selectedTask: null,
    showHidden: false,

    /* Drag / Drop */
    movingActivity: false,
    payload: null,

    /* Shift Sheet */
    formGroups: [],
    shiftSheetActive: false,
};
