<template>
    <v-btn
        fab
        dark
        small
        class="app-icon-button"
        :color="color"
        :disabled="disabled"
        elevation="2"
        outlined
        v-on="$listeners"
    >
        <v-icon>{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: "primary",
            },
            icon: {
                type: String,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style scoped>
    .app-icon-button {
        background-color: white;
    }
</style>
