import axios from "axios";
import { camelToTitleCase } from "@/services/stringUtility";

function getDefaultState() {
    return {
        schema: null,
        isLoaded: false,
    };
}

function trimTrailingQuestionMark(type) {
    return type.replace(/\?$/, "");
}

function camel(key) {
    return key[0].toLowerCase() + key.slice(1);
}

const state = getDefaultState;

const getters = {
    schema: (state) => state.schema,
    enums: (state) => state.schema.enums,
    entities: (state) => state.schema.entities,
    getEnum: (state) => (enumKey) => {
        enumKey = trimTrailingQuestionMark(camel(enumKey));
        return state.schema.enums[enumKey] ?? null;
    },
    getEnumAsList: (_, getters) => (enumKey) => {
        const e = getters.getEnum(enumKey);
        return Object.keys(e).map((key) => {
            return {
                value: key,
                text: camelToTitleCase(key),
            };
        });
    },
    getEntity: (state) => (entityKey) => {
        entityKey = camel(entityKey);
        return state.schema.entities[entityKey] ?? null;
    },
    getQueryField: (state) => (entityKey, fieldKey) => {
        entityKey = camel(entityKey);
        fieldKey = camel(fieldKey);
        const entity = state.schema.entities[entityKey];
        return entity.queryFields[fieldKey];
    },
};

const actions = {
    async load({ commit }) {
        let schema = (await axios.get("/api2/Schema")).data;
        commit("schema", schema);
        commit("loaded");
    },
    clear({ commit }) {
        commit("clear");
    },
};

const mutations = {
    schema(state, schema) {
        state.schema = schema;
    },
    loaded(state) {
        state.isLoaded = true;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    },
};

export const schema = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
