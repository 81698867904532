<template>
    <div>
        <v-card v-if="item">
            <v-card-title>
                {{ entity.singleTitle }}
                <v-spacer />
                <app-icon-button color="secondary" icon="mdi-pencil" @click="edit" />
            </v-card-title>
            <v-card-text>
                <div v-for="field in fields" :key="field.key" class="mb-2">
                    <label>
                        {{ field.title }}
                    </label>
                    <slot :name="field.key" :field="field" :item="item">
                        <component
                            :is="getCellComponent(field.key)"
                            :key="field.key"
                            :options="getCellOptions(field.key)"
                            :value="item[field.key]"
                        />
                    </slot>
                </div>
            </v-card-text>
        </v-card>

        <schema-form-dialog :entity-key="entityKey" :value="editedItem" @input="onItemSaved" />
    </div>
</template>

<script>
    import {
        getCellComponent,
        buildCellOptions,
        getTableData,
        updateLookups,
        hasLookupAlready,
    } from "@/features/schemas/services/tableService";

    import { equal } from "@/services/filtering";
    import { hasProperty } from "@/services/objectUtility";
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import { getTableRoute } from "@/features/schemas/services/schemaRouteProvider";
    // TODO: automatic updates with SignalR
    // import { subscribe } from "@/services/actionHub";
    import { goTo } from "@/services/routeService";

    export default {
        mixins: [schemaMixin],

        props: {
            itemId: {
                type: String,
                default: null,
            },
        },

        data() {
            return {
                tableData: null,
                isDeleted: false,
                editedItem: null,
            };
        },

        computed: {
            fields() {
                return Object.values(this.entity.queryFields).filter(
                    (f) => !hasProperty(f, "hidden") && (!f.dependsOn || !hasLookupAlready(f, this.entity.queryFields))
                );
            },
            item() {
                const items = this.tableData?.items;
                if (!items?.length) {
                    return null;
                }
                return items[0];
            },
        },

        watch: {
            itemId: {
                immediate: true,
                async handler() {
                    await this.refresh();
                },
            },
        },
        mounted() {
            // TODO: automatic updates with SignalR
            // let entityKeys = [this.entityKey, ...getLookupTypes(this.entityKey)];
            // this.subscription = subscribe(this.receiveMessages, entityKeys);
        },
        destroyed() {
            // TODO: automatic updates with SignalR
            // this.subscription.unsubscribe();
        },
        methods: {
            getCellComponent(fieldKey) {
                return getCellComponent(this.entityKey, fieldKey);
            },
            getCellOptions(fieldKey) {
                return buildCellOptions(this.tableData, fieldKey);
            },
            async refresh() {
                if (!this.itemId) {
                    this.tableData = null;
                    return;
                }

                const filter = equal("id", this.itemId, "guid");
                this.tableData = await getTableData(this.entityKey, { filter }, true);
                if (this.item == null) {
                    // Go to table route. This can happen if the item is deleted while viewing it.
                    let route = getTableRoute(this.entityKey)?.path ?? "/";
                    goTo(route);
                } else {
                    this.$emit("refresh", this.item);
                }
            },
            async receiveMessages(messages) {
                const thisItemHasUpdates = messages.some((m) => m.entityKey == this.entityKey && m.id == this.itemId);

                if (thisItemHasUpdates) {
                    await this.refresh();
                    return;
                }

                await updateLookups(this.tableData.lookups, messages);
            },
            edit() {
                this.editedItem = this.item;
            },
            async onItemSaved() {
                this.editedItem = null;
                await this.refresh();
            },
        },
    };
</script>

<style scoped>
    label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.75rem;
        font-weight: 700;
    }
</style>
