import licenseService from "../services/licenseService";

export const license = {
    namespaced: true,
    state: {
        license: {},
    },
    actions: {
        loadLicense({ commit }) {
            licenseService
                .loadLicense()
                .then((result) => {
                    commit("license", result.data);
                })
                .catch(() => {
                    commit("license", null);
                });
        },
    },
    mutations: {
        license(state, license) {
            state.license = license;
        },
    },
};
