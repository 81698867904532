<template>
    <schema-column-filter :options="enumOptions" @input="filter" />
</template>

<script>
    import SchemaColumnFilter from "@/features/schemas/components/SchemaColumnFilter.vue";
    import { getEnum } from "@/features/schemas/services/schemaProvider";
    import { camelToTitleCase } from "@/services/stringUtility";

    export default {
        types: ["enum"],
        components: {
            SchemaColumnFilter,
        },
        props: {
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                enumOptions: [],
            };
        },
        mounted() {
            const enumValue = getEnum(this.options.field.type);
            if (enumValue) {
                this.enumOptions = Object.keys(enumValue).map((key) => ({
                    id: enumValue[key],
                    label: camelToTitleCase(key),
                    isSelected: false,
                }));
            }
        },
        methods: {
            filter(values) {
                // Set isSelected to true for each enum that is selected
                this.enumOptions.map((enumOption) => {
                    enumOption.isSelected = values.includes(enumOption.id);
                });

                this.$emit("input", {
                    type: "byte",
                    values,
                });
            },
        },
    };
</script>
