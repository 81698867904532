<template>
    <div :title="value" class="text">
        {{ value }}
    </div>
</template>

<script>
    export default {
        types: ["string", "int", "decimal", "default"],
        props: {
            value: {
                type: [String, Number],
                default: null,
            },
        },
    };
</script>
