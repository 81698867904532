import { and, anyValue } from "@/services/filtering";
import SchemaColumnFilter from "@/features/schemas/components/SchemaColumnFilter.vue";
import { getEnum } from "@/features/schemas/services/schemaProvider";

export default {
    components: {
        SchemaColumnFilter,
    },
    data() {
        return {
            localFilter: {},
        };
    },
    mounted() {
        const fieldKeys = Object.values(this.fieldKeys); // via dataTableMixin.js

        for (const field of fieldKeys) {
            const options = this.getHeaderOptions(field);

            // If the filter does not depend on an entity OR is not an enum, skip it.
            if (!getEnum(options.field?.type) && !options.field?.dependsOn) continue;

            this.$set(this.localFilter, field, {
                type: options.field.type,
                values: [],
            });
        }
    },
    computed: {
        schemaFilter() {
            const filters = [];
            const keys = Object.keys(this.localFilter);

            for (const key of keys) {
                if (!this.localFilter[key]) continue;

                const { values, type } = this.localFilter[key];

                // Only add filters that have values.
                if (values && values.length > 0) {
                    filters.push(anyValue(key, values, type));
                }
            }

            return and(filters);
        },
    },
};
