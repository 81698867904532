<template>
    <div v-on="$listeners">
        {{ display }}
    </div>
</template>

<script>
    import { display } from "../services/entityCellProvider";

    export default {
        types: ["entity"],
        props: {
            value: {
                type: [String, Object],
                default: () => null,
            },
            options: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            display() {
                return display(this.options.entityKey, this.value, this.options?.lookup);
            },
        },
    };
</script>
