<template>
    <app-text-field
        type="number"
        v-bind="$attrs"
        :value="value"
        @input="$emit('input', toNumber($event))"
        :label="field.title"
        :rules="combinedRules"
        :required="required"
        v-on="listeners"
    />
</template>

<script>
    import formFieldMixin from "./formFieldMixin";

    export default {
        types: ["int", "decimal", "double", "short", "single"],
        mixins: [formFieldMixin],
        props: {
            value: {
                type: Number,
                default: null,
            },
            customRules: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            listeners() {
                // eslint-disable-next-line no-unused-vars
                const { input, ...others } = this.$listeners;
                return others;
            },
            combinedRules() {
                if (this.customRules?.length > 0) {
                    return [...this.rules, ...this.customRules];
                }
                return this.rules;
            },
        },
        methods: {
            toNumber(value) {
                const n = parseFloat(value);
                return isNaN(n) ? null : n;
            },
        },
    };
</script>
