export const shiftReview = {
    namespaced: true,
    state: {
        filters: {},
        searches: {},
        reset: false,
        summary: {
            expandByDefault: true,
        },
        isExcelExporting: false,
    },
    mutations: {
        reset(state, message) {
            state.reset = message;
        },
        clearFilters(state) {
            state.filters = {};
        },
        clearSearches(state) {
            state.searches = {};
        },
        setSummary(state, message) {
            state.summary = message;
        },
        setExcelExporting(state, message) {
            state.isExcelExporting = message;
        },
    },
};
