<template>
    <app-select
        v-bind="$attrs"
        v-on="$listeners"
        :items="items"
        item-text="label"
        item-value="id"
        :value="value"
        @input="$emit('input', $event)"
        clearable
    />
</template>

<script>
    import schemaMixin from "../mixins/schemaMixin";
    import { getLabel } from "../services/labeller";
    import { list } from "../services/schemaApi";
    export default {
        mixins: [schemaMixin],
        props: {
            value: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                items: [],
            };
        },
        watch: {
            entityKey: {
                immediate: true,
                async handler(entityKey) {
                    const result = await list(entityKey, {});
                    this.items = result.items.map((i) => ({ id: i.id, label: getLabel(this.entityKey, i) }));
                },
            },
        },
    };
</script>
