<template>
    <div class="d-flex flex-column schema-table">
        <div class="d-flex align-center mb-5">
            <slot name="before-buttons">
                <h2>{{ entity.pluralTitle }}</h2>
                <v-spacer />
            </slot>

            <slot name="add-button" :can-add="canAdd">
                <app-button v-if="canAdd" class="ml-2" @click="add">Add {{ entity.singleTitle }}</app-button>
            </slot>

            <slot name="before-excel-button" />

            <schema-excel-export-button v-bind="$attrs" class="ml-2" :entity-key="entityKey" />

            <slot name="after-buttons" />
        </div>

        <div class="d-flex align-center">
            <app-text-field v-model="searchText" label="Search" hide-details />
            <v-spacer />
            <v-btn-toggle v-if="canUndelete" mandatory class="ml-2 float-right" tile color="tertiary" group>
                <v-btn @click="toggleRecordState('active')">Active</v-btn>
                <v-btn @click="toggleRecordState('deleted')">Inactive</v-btn>
            </v-btn-toggle>
        </div>

        <div class="d-flex flex-grow-1">
            <v-card class="flex-grow-1">
                <component
                    :is="tableComponent"
                    ref="schemaDataTable"
                    class="flex-grow-1"
                    v-bind="$attrs"
                    :entity-key="entityKey"
                    :record-state="recordState"
                    :search-text="searchText"
                    @edit="edit"
                />
            </v-card>

            <slot name="after-data-table" />
        </div>

        <schema-form-dialog :entity-key="entityKey" :value="editedItem" v-bind="$attrs" @input="onItemSaved" />
    </div>
</template>

<script>
    import schemaMixin from "@/features/schemas/mixins/schemaMixin";
    import { canAdd } from "@/features/schemas/services/schemaApi";
    import { getTable } from "../tables";

    export default {
        mixins: [schemaMixin],

        data() {
            return {
                editedItem: null,
                recordState: "active",
                searchText: "",
            };
        },

        computed: {
            canAdd() {
                return canAdd(this.entityKey);
            },
            tableComponent() {
                return getTable(this.entityKey);
            },
        },

        methods: {
            add() {
                this.editedItem = {};
            },
            edit(item) {
                this.editedItem = item;
            },
            async onItemSaved() {
                this.editedItem = null;
                await this.$refs.schemaDataTable.refresh();
            },
            toggleRecordState(recordState) {
                this.recordState = recordState;
            },
        },
    };
</script>
