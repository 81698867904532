<template>
    <schema-data-table ref="table" v-bind="$attrs" v-on="$listeners">
        <template v-slot:[`item.iconImage`]="{ item }">
            <exm-icon
                :icon="formatIconName(item.iconImage)"
                :primary-color="item.primaryColor"
                :secondary-color="item.secondaryColor"
                :background-color="item.backgroundColor"
            />
        </template>
    </schema-data-table>
</template>

<script>
    import { camelToPascalCase } from "@/services/stringUtility";
    import ExmIcon from "@/components/ExmIcon";

    export default {
        components: {
            ExmIcon,
        },
        methods: {
            formatIconName(iconName) {
                return camelToPascalCase(iconName);
            },
            async refresh() {
                await this.$refs.table.refresh();
            },
        },
    };
</script>
