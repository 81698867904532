import { getLabel } from "./labeller.js";

export function display(entityKey, value, lookup) {
    if (value == null) {
        return "";
    }

    if (!lookup) {
        return getLabel(entityKey, value);
    }

    let item = lookup[value.toString()];
    return getLabel(entityKey, item);
}

export default { display };
