<template>
    <schema-form ref="form" v-bind="$attrs" v-on="$listeners">
        <template #field-isHazardRequired="{ field, item }">
            <!-- TODO: Show this when we implement Hazards -->
            <form-checkbox v-show="false" v-model="item.isHazardRequired" :field="field" />
        </template>

        <template #field-isNotApplicable="{ field, item }">
            <form-checkbox v-model="item.isNotApplicable" :field="field" :disabled="isNotApplicableDisabled(item)" />
        </template>

        <template #field-inspectionType="{ field, item }">
            <form-enum
                v-model="item.inspectionType"
                :field="field"
                @change="(newVal) => onInspectionTypeChange(newVal, item)"
            />
        </template>

        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
        </template>
    </schema-form>
</template>

<script>
    import FormCheckbox from "../formFields/FormCheckbox.vue";
    import FormEnum from "../formFields/FormEnum.vue";

    export default {
        components: { FormCheckbox, FormEnum },

        methods: {
            async save() {
                return await this.$refs.form.save();
            },
            isNotApplicableDisabled(item) {
                if (item.inspectionType == "question") {
                    return true;
                }
                return false;
            },
            onInspectionTypeChange(inspectionType, item) {
                if (inspectionType == "question") {
                    item.isNotApplicable = 0;
                }
            },
        },
    };
</script>
