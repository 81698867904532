import { isNullOrWhiteSpace } from "@/services/stringUtility";

export function buildRules(field) {
    let rules = [];

    if (field.required) {
        rules.push((v) => !isNullOrWhiteSpace(v) || `${field.title} is required`);
    }

    if (field.maxLength) {
        const maxLength = field.maxLength.length;

        rules.push(
            (v) => !(v && v.length > maxLength) || `${field.title} must not be more than ${maxLength} characters`
        );
    }

    return rules;
}

export default { buildRules };
