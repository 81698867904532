<template>
    <div v-if="field && field.dependsOn" class="d-flex flex-nowrap align-center justify-center">
        <component
            :is="searchSelectComponent"
            style="width: 100%"
            v-bind="$attrs"
            :value="value"
            :label="field.title"
            :rules="rules"
            :entity-key="field.dependsOn.type"
            :clearable="!required"
            search-when-blank
            v-on="$listeners"
        />

        <v-btn
            v-if="canAdd"
            :outlined="$attrs.outlined != null ? $attrs.outlined : true"
            icon
            small
            fab
            class="ml-2"
            style="align-self: flex-start"
            @click="add"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn>

        <schema-form-dialog :entity-key="field.dependsOn.type" :value="editedItem" @input="onItemSaved" />
    </div>
</template>

<script>
    import formFieldMixin from "./formFieldMixin";
    import { canAdd } from "@/features/schemas/services/schemaApi";
    import { getSearchSelect } from "@/features/schemas/searchSelects/index";

    export default {
        types: ["entity", "entity[]"],
        mixins: [formFieldMixin],
        props: {
            value: {
                type: [String, Array],
                default: null,
            },
        },
        data() {
            return {
                editedItem: null,
            };
        },

        computed: {
            canAdd() {
                return canAdd(this.field.dependsOn.type);
            },
            searchSelectComponent() {
                return getSearchSelect(this.field.dependsOn.type, this.field.type);
            },
            isMultiple() {
                return this.field.type.includes("[]");
            },
        },

        methods: {
            add() {
                this.editedItem = {};
            },
            onItemSaved(item) {
                if (item == null) {
                    this.editedItem = null;
                    return;
                }

                // Handle case where input is an array of id's instead of just one.
                if (this.isMultiple) {
                    if (this.value == null) {
                        this.$emit("input", [item.id]);
                    } else {
                        this.$emit("input", [...this.value, item.id]);
                    }
                } else {
                    this.$emit("input", item.id);
                }

                this.editedItem = null;
            },
        },
    };
</script>
