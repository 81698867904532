import { getEnum } from "./schemaProvider";
import { getEntity } from "./schemaProvider";
import { hasProperty } from "@/services/objectUtility";
import { isNullOrWhiteSpace, pascalToCamelCase } from "@/services/stringUtility";

export class FieldDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {};

        context.keys().forEach((filename) => {
            const file = context(filename);

            const dependency = useDefault ? file.default : file;

            if (!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            for (const type of dependency.types) {
                if (type.includes("[]")) {
                    dependenciesByType["entity[]"] = dependency;
                } else {
                    dependenciesByType[type] = dependency;
                }
            }
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(schemaField) {
        // Entity fields are integers. We want to resolve these as the "entity" type.
        const type = this.getDependencyType(schemaField);
        return this.#dependenciesByType[type] ?? this.#dependenciesByType["default"];
    }

    getDependencyType(schemaField) {
        // If we're looking at a foreign key, then look for a dependency which handles that
        // specific type, otherwise default to generic type.
        if ("dependsOn" in schemaField) {
            //If the field is an array, then we want to default it as an array type.
            let entityType = schemaField.type;

            if (entityType.includes("[]")) {
                return "entity[]";
            }

            let foreignEntityType = schemaField.dependsOn.type;
            // Foreign Key is only able to have new records.
            if (schemaField.dependsOn.addOnly) {
                return this.hasDependency(foreignEntityType) ? foreignEntityType : "addonlyentity";
            }

            return this.hasDependency(foreignEntityType) ? foreignEntityType : "entity";
        }

        if ("subtype" in schemaField) {
            let subtype = pascalToCamelCase(schemaField.subtype.name);
            if (!isNullOrWhiteSpace(subtype) && this.hasDependency(subtype)) {
                return subtype;
            }
        }

        // Remove trailing "?" from nullable types.
        let type = schemaField.type.replace(/\?$/, "");

        // This is a foreign key, but we have the lookup in our schema already.
        // TODO: Issue if type of Entity is same name as an Enum.
        if (getEntity(type) !== null) {
            return this.hasDependency(type) ? type : "entity";
        }

        if (getEnum(type) !== null) {
            return "enum";
        }

        return type;
    }

    hasDependency(type) {
        return hasProperty(this.#dependenciesByType, type);
    }
}
