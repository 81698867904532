<template>
    <v-btn v-bind="$attrs" :href="getLink" target="_blank" color="white" style="text-transform: none" outlined>
        <v-icon left>mdi-help-circle-outline</v-icon>
        Get Help
    </v-btn>
</template>

<script>
    import Vue from "vue";

    export default Vue.component("help-link", {
        props: ["buttonHeight"],

        computed: {
            getLink() {
                const linkKey = this.$route?.meta?.helpLink ?? "generic";
                return this.$store.getters.getHelpLink(linkKey);
            },
        },
    });
</script>
