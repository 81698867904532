<template>
    <div>
        <v-row>
            <v-col v-if="!wizardMode" col-12 class="d-flex justify-start">
                <div class="ma-3">
                    <app-button color="primary" @click="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                        Back
                    </app-button>
                </div>
            </v-col>
            <v-col col-12 class="d-flex justify-end">
                <!-- Excel export button -->
                <div class="ma-3">
                    <!-- Show tooltip if Items not saved -->
                    <v-tooltip bottom v-if="!editMode">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <app-button
                                    :disabled="!editMode"
                                    :loading="isExporting"
                                    color="secondary"
                                    @click="exportExcel()"
                                >
                                    Export Item Orders
                                </app-button>
                            </div>
                        </template>
                        <span>Must save before you can export</span>
                    </v-tooltip>

                    <!-- Items have been saved -->
                    <app-button
                        v-else
                        :disabled="!editMode"
                        :loading="isExporting"
                        color="secondary"
                        @click="exportExcel()"
                    >
                        Export Item Orders
                    </app-button>
                </div>
            </v-col>
        </v-row>
        <v-data-table
            :key="tableKey"
            v-if="isDataLoaded"
            :headers="headers"
            :items="localItems"
            sort-by="priority"
            :sort-desc="true"
            @update:page="(page) => (currentPage = page)"
            :page.sync="currentPage"
        >
            <template #item.icon="{ item }">
                <exm-icon
                    v-if="item.icon"
                    class="exm-icon"
                    :icon="camelToPascalCase(item.icon?.name)"
                    :primary-color="item.icon?.primaryColor"
                    :secondary-color="item.icon?.secondaryColor"
                    :background-color="item.icon?.backgroundColor"
                />
            </template>
            <template #item.magazineItemId="{ item }">
                {{ getItemText(item) }}
            </template>

            <template #item.comments="{ item }">
                <comments-dialog
                    :val="item.comments"
                    @updateComment="(newComment) => onUpdateComment(newComment, item)"
                >
                    <template v-slot:activator="{ open }">
                        <v-badge
                            v-if="item.comments != null && item.comments.length > 0"
                            color="error"
                            content="1"
                            @click.left.exact.stop="open"
                            overlap
                        >
                            <v-icon color="primary" @click.left.exact.stop="open">mdi-chat</v-icon>
                        </v-badge>
                        <v-icon v-else color="primary" @click.left.exact.stop="open">mdi-chat</v-icon>
                    </template>
                </comments-dialog>
            </template>

            <template #item.minMax="{ item }">{{ item.magazineItem?.min }} / {{ item.magazineItem?.max }}</template>

            <template #item.orderQuantity="{ item }">
                <form-number
                    class="centered-form-number"
                    v-model="item.orderQuantity"
                    :field="{ title: `Order Quantity` }"
                    dense
                    required
                    :rules="[
                        (v) => (!!v && Number(v) > 0) || 'Value must be positive',
                        (v) => (!!v && Number.isInteger(Number(v))) || 'Value must be an integer',
                    ]"
                    @input="calculateTotal(item)"
                />
            </template>

            <template #item.unitOfMeasure="{ item }">
                <v-select
                    v-model="item.selectedOption"
                    :items="item.unitOfMeasureOptions"
                    style="min-width: 130px"
                    item-value="value"
                    item-text="text"
                    label="Unit Of Measure"
                    dense
                    @input="onChangeUnitOfMeasure(item)"
                />
            </template>

            <template #item.totalQuantity="{ item }">
                <div>{{ item.totalQuantity }} {{ item.unit?.name }}</div>
            </template>

            <template #item.actions="{ item }">
                <confirmation-dialog
                    irreversible
                    :message="`Are you sure you want to delete this item?`"
                    @confirm="deleteItem(item.id)"
                >
                    <template v-slot:activator="{ open }">
                        <v-icon
                            @click.left.exact.stop="open"
                            @click.shift.left.exact.stop="deleteItem(item.id)"
                            :disabled="isLoading"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                </confirmation-dialog>
            </template>
        </v-data-table>

        <v-row>
            <v-col col-12 class="d-flex justify-end">
                <div class="mr-2">
                    <app-button color="primary" text @click="openAddItemDialog()" :disabled="isLoading">
                        Add Item
                        <v-icon>mdi-plus</v-icon>
                    </app-button>
                </div>
            </v-col>
        </v-row>

        <add-item-dialog
            :items="addableItems"
            :dialog="addItemDialog"
            @close="addItemDialog = false"
            @addItemOrder="(item) => addItemOrder(item)"
        />
    </div>
</template>

<script>
    import axios from "axios";
    import { list, delete_ } from "@/features/schemas/services/schemaApi";
    import { camelToPascalCase } from "@/services/stringUtility";

    import FormNumber from "../schemas/formFields/FormNumber.vue";
    import { exportItemOrderReportToExcel } from "@/features/schemas/services/exporter";
    import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
    import ExmIcon from "@/components/ExmIcon";
    import CommentsDialog from "./CommentsDialog.vue";
    import AddItemDialog from "./AddItemDialog.vue";
    import unitOfMeasureEnum from "@/enums/unitOfMeasureEnum";

    export default {
        components: {
            FormNumber,
            ExmIcon,
            CommentsDialog,
            ConfirmationDialog,
            AddItemDialog,
        },
        props: {
            items: {
                type: Array,
                default: null,
            },
            editMode: {
                type: Boolean,
                default: false,
            },
            wizardMode: {
                type: Boolean,
                default: false,
            },
            itemOrderBatch: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                headers: [
                    { text: "Icon", value: "icon" },
                    { text: "Magazine Item", value: "magazineItemId", sortable: true },
                    { text: "Item Min / Max", value: "minMax" },
                    { text: "Comments", value: "comments" },
                    { text: "Stock on hand", value: "currentStock", sortable: true }, // stock at time of order
                    { text: "Usage Per Day", value: "usagePerDay", sortable: true },
                    {
                        text: "Projected Usage Until Delivery",
                        value: "projectedStockUsageUntilDelivery",
                    },
                    { text: "Estimated Quantity Required", value: "totalQuantityRequired", divider: true },
                    { text: "Order Quantity", value: "orderQuantity" },
                    { text: "UnitOfMeasure", value: "unitOfMeasure" },
                    { text: "Total Quantity", value: "totalQuantity" },
                    { text: "Actions", value: "actions" },
                    { text: "Global Priority", value: "priority", sortable: true, hidden: true },
                ],
                icons: [],
                magazineItems: [],
                isDataLoaded: false,
                tableKey: 1,
                addItemDialog: false,
                addableItems: [],
                currentPage: 1,
                localItems: [],
                isLoading: true,
                isExporting: false,
            };
        },
        watch: {
            items: {
                async handler() {
                    await this.loadData();
                    this.initializeLocalItems();
                },
            },
        },
        methods: {
            async loadData() {
                try {
                    // We need this for lookup on what items to add
                    const magazineItemQuery = await list("magazineItem", {
                        filter: null,
                        sortBy: null,
                        direction: "ascending",
                        recordState: "active",
                    });
                    this.magazineItems = magazineItemQuery.items;
                } finally {
                    this.isDataLoaded = true;
                    this.isLoading = false;
                }
            },
            initializeLocalItems() {
                this.localItems = this.items.map((item) => {
                    return this.initializeItem(item);
                });
                this.sortLocalItems();
            },
            initializeItem(item) {
                let unitOfMeasureOptions = this.getUnitOfMeasureOptions(item.magazineItem);
                return {
                    ...item,
                    unitOfMeasureOptions: unitOfMeasureOptions,
                    selectedOption: this.getCurrentUnitOfMeasure(item, unitOfMeasureOptions),
                };
            },
            sortLocalItems() {
                const sortedItems = this.sortItems(this.localItems);
                this.localItems = this.addPriority(sortedItems);
            },
            sortItems(items) {
                return items.sort((a, b) => {
                    const magazineItemA = a.magazineItem;
                    const magazineItemB = b.magazineItem;

                    const magazineItemTypeA = a.magazineItemType;
                    const magazineItemTypeB = b.magazineItemType;

                    const magazineItemCategoryA = a.magazineItemCategory;
                    const magazineItemCategoryB = b.magazineItemCategory;

                    if (magazineItemTypeA.priority !== magazineItemTypeB.priority) {
                        return magazineItemTypeA.priority - magazineItemTypeB.priority;
                    }

                    if (magazineItemCategoryA?.priority !== magazineItemCategoryB?.priority) {
                        return (magazineItemCategoryA?.priority ?? 0) - (magazineItemCategoryB?.priority ?? 0);
                    }

                    // If magazineItemCategories are also equal, finally compare by magazineItem
                    return (magazineItemA.priority ?? 0) - (magazineItemB.priority ?? 0);
                });
            },
            addPriority(items) {
                return items.map((item, index) => {
                    return {
                        ...item,
                        priority: index + 1,
                    };
                });
            },
            camelToPascalCase,
            getItemText(item) {
                const categoryName = item.magazineItemCategory?.name;
                return `${item.magazineItem?.name} ${categoryName ? ` - ${categoryName}` : ""}`;
            },
            getUnitOfMeasureOptions(magazineItem) {
                let unitOptions = [{ value: unitOfMeasureEnum.Loose, text: "Loose" }];

                if (!magazineItem || (magazineItem.quantityPerPallet === 0 && magazineItem.quantityPerBox === 0)) {
                    return unitOptions;
                }

                if (magazineItem.quantityPerPallet) {
                    unitOptions.push({
                        value: unitOfMeasureEnum.Pallets,
                        text: `Pallet (${magazineItem.quantityPerPallet})`,
                    });
                }
                if (magazineItem.quantityPerBox) {
                    unitOptions.push({ value: unitOfMeasureEnum.Boxes, text: `Box (${magazineItem.quantityPerBox})` });
                }

                return unitOptions;
            },
            getCurrentUnitOfMeasure(item, unitOfMeasureOptions) {
                switch (item.unitOfMeasure) {
                    case unitOfMeasureEnum.Pallets:
                        return unitOfMeasureOptions.find((x) => x.value === unitOfMeasureEnum.Pallets).value;
                    case unitOfMeasureEnum.Boxes:
                        return unitOfMeasureOptions.find((x) => x.value === unitOfMeasureEnum.Boxes).value;
                    default:
                        return unitOfMeasureOptions.find((x) => x.value === unitOfMeasureEnum.Loose).value;
                }
            },
            async onUpdateComment(newComment, itemOrder) {
                itemOrder.comments = newComment;
                this.updateTable();
            },
            onChangeUnitOfMeasure(itemOrder) {
                itemOrder.unitOfMeasure = itemOrder.selectedOption;
                this.calculateTotal(itemOrder);
            },
            calculateTotal(itemOrder) {
                if (!itemOrder.magazineItem) {
                    return;
                }

                switch (itemOrder.selectedOption) {
                    case unitOfMeasureEnum.Pallets:
                        itemOrder.totalQuantity = itemOrder.orderQuantity * itemOrder.magazineItem.quantityPerPallet;
                        break;
                    case unitOfMeasureEnum.Boxes:
                        itemOrder.totalQuantity = itemOrder.orderQuantity * itemOrder.magazineItem.quantityPerBox;
                        break;
                    case unitOfMeasureEnum.Loose:
                        itemOrder.totalQuantity = itemOrder.orderQuantity;
                        break;
                }
            },
            async deleteItem(itemId) {
                this.isLoading = true;
                try {
                    const index = this.localItems.findIndex((x) => x.id === itemId);
                    if (index > -1) {
                        this.localItems.splice(index, 1);
                    }
                    if (this.editMode) {
                        await delete_("itemOrder", itemId);
                    }
                    this.updateTable();
                } finally {
                    this.isLoading = false;
                }
            },
            async exportExcel() {
                this.isLoading = true;
                this.isExporting = true;
                try {
                    await exportItemOrderReportToExcel(this.itemOrderBatch.id);
                } finally {
                    this.isLoading = false;
                    this.isExporting = false;
                }
            },
            openAddItemDialog() {
                // Filter items that are not already in the list
                this.addableItems = this.magazineItems.filter(
                    (x) => !this.localItems.some((y) => y.magazineItemId === x.id)
                );
                this.addItemDialog = true;
            },
            async addItemOrder(item) {
                this.addItemDialog = false;
                this.isLoading = true;
                try {
                    const { data } = await axios.post(
                        `/api/MagazineOrder/AddItemOrder/${item.id}`,
                        this.itemOrderBatch
                    );
                    if (data) {
                        const newItem = data;
                        const initializedItem = this.initializeItem(newItem);
                        this.localItems.push(initializedItem);
                        this.sortLocalItems();
                        this.updateTable();
                    } else {
                        throw new Error("Failed to add item order");
                    }
                } finally {
                    this.isLoading = false;
                }
            },
            updateTable() {
                // We need this to update the table rows when we add a comment or an item
                const currentPage = this.currentPage;
                this.tableKey++;
                this.$nextTick(() => {
                    this.currentPage = currentPage;
                });
            },
        },
    };
</script>

<style scoped>
    .exm-icon {
        max-height: 25px;
    }
    .centered-form-number {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }
</style>
