import { pascalToCamelCase } from "@/services/stringUtility";
import schemaSearchSelect from "../components/SchemaSearchSelect";
import schemaSearchSelectMultiple from "../components/SchemaSearchSelectMultiple";
const searchSelectsByEntityKey = {};

// Grab all the *.vue files in this folder (exluding subfolders).
let requireComponent = require.context(".", false, /^.\/([\w-]+)SearchSelect\.vue$/);

requireComponent.keys().forEach((filePath) => {
    const searchSelect = requireComponent(filePath);
    const entityKey = pascalToCamelCase(filePath.slice(2, -16));
    searchSelectsByEntityKey[entityKey] = searchSelect.default;
});

export function getSearchSelect(entityKey, entityType) {
    return entityType?.includes("[]")
        ? schemaSearchSelectMultiple
        : searchSelectsByEntityKey[entityKey] ?? schemaSearchSelect;
}

export default {
    getSearchSelect,
};
